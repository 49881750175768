import { NavigationDrawer } from '@csinstruments/cs-react-theme'
import React, { useCallback, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router'
import { accessibleItems } from '../../../helpers/drawerItems'
import { ROUTES } from '../../../helpers/routes'

interface NavigationProps {
  onDrawerAction: (open: boolean) => void
}

export const ProfileNavigationDrawer: React.FC<NavigationProps> = ({ onDrawerAction }: NavigationProps) => {
  const history = useHistory()
  const location = useLocation()

  const activeTab: string = useMemo(() => {
    const selected = accessibleItems.filter((drawerItem) => location.pathname.includes(drawerItem.link))
    return selected.length ? selected[selected.length - 1].link : ''
  }, [location])

  /* useEffect(() => {
    if (orgs.length) {
      setOrganiztions([...orgs])
    }
  }, [])

   const accessibleItems = useMemo(() => {
    if (organizations.length) {
      const apps = organizations.flatMap((o) => o.appRoles.map((ap) => `${ap?.appName.toUpperCase()}`))
      const drawerApps = [...apps, 'HOME']
      return drawerItems.filter((d) => drawerApps.includes(d.text))
    } else {
      return drawerItems.filter((d) => d.text === 'HOME')
    }
  }, [organizations]) */

  const appItemClick = useCallback(
    (path: string): void => {
      if (path.startsWith('http')) {
        window.open(path, '_blank')
      } else if (path.startsWith('cs')) {
        history.push(ROUTES.home)
      } else {
        history.push('/' + path)
      }
    },
    [history],
  )
  return (
    <div>
      <NavigationDrawer
        onDrawerAction={onDrawerAction}
        onLinkClicked={appItemClick}
        entries={accessibleItems}
        activeLink={activeTab}
      />
    </div>
  )
}
