import { Typography, Grid, Checkbox, FormControlLabel, TextField, MenuItem } from '@material-ui/core'
import { useKeycloak } from '@react-keycloak/web'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useChangeOrganizationNameMutation } from '../../../api/organization.models'
import { environment } from '../../helpers/environment'
import { ROUTES } from '../../helpers/routes'
import { useGetUsers, useUpdateAdmin } from '../../hooks/iamHooks'
import { UserProfileType } from '../../Interfaces/KeycloakTypes'
import { TextInputField } from './Inputs/TextInputField'
import { ModalForm } from './ModalForm'
import ProgressBar from './ProgressBar'
import { ResultAlert } from './ResultAlert'
type ChangeOrgNameProps = {
  active: boolean
  closeChangeNameTab: (close: boolean) => void
  realmName: string
  orgDisplayName: string
}
const UpdateOrganization: React.FC<ChangeOrgNameProps> = ({
  active,
  realmName,
  orgDisplayName,
  closeChangeNameTab,
}: ChangeOrgNameProps) => {
  const { keycloak } = useKeycloak()
  const userProfile: UserProfileType | undefined = keycloak.userInfo
  const [displayName, setDisplayName] = useState('')
  const [changeRootAdmin, setChangeRootAdmin] = useState(false)
  const [selectedUser, setSelectedUser] = useState<string>('')
  const { t } = useTranslation('common')
  const [changeDisplayName, { loading, error }] = useChangeOrganizationNameMutation()
  const { changeSuperAdmin, changingAdmin } = useUpdateAdmin(realmName)
  const { superAdmins } = useGetUsers(realmName)
  const handleClose = () => {
    setDisplayName('')
    setSelectedUser('')
    setChangeRootAdmin(false)
    closeChangeNameTab(false)
  }
  const submitForm = async () => {
    try {
      if (!realmName) {
        return
      }
      if (changeRootAdmin) {
        const res = await changeSuperAdmin(selectedUser)
        if (res && res) {
          handleClose()
          keycloak.login({ redirectUri: environment.WEB_URL + ROUTES.home })
        }
      } else {
        if (!displayName) {
          return
        }
        const resp = await changeDisplayName({
          variables: {
            realmName: realmName,
            displayName: displayName,
          },
        })
        if (resp && resp) {
          keycloak.login({ redirectUri: environment.WEB_URL + ROUTES.home })
        }
      }
    } catch (er) {
      console.error('error activating Organization', er)
    }
  }
  return (
    <>
      <ModalForm
        formOpen={active}
        formClose={handleClose}
        submitForm={submitForm}
        headingText={t('labels.changeOrganizationName')}
        disableClose={changingAdmin || loading}
        disableSubmit={
          (!changeRootAdmin && !displayName) || (changeRootAdmin && !selectedUser) || loading || changingAdmin
        }
      >
        {loading && <ProgressBar />}
        {error && <ResultAlert severity="error" alertText={t('error.changeOrgName')} />}
        <Grid item container spacing={2} alignContent="space-between">
          <Grid item xs={6} lg={8}>
            <FormControlLabel
              label={
                <Typography color="textPrimary" variant="body1">
                  {t('actions.modifyAdmin')}
                </Typography>
              }
              control={
                <Checkbox
                  checked={changeRootAdmin}
                  onChange={() => {
                    setSelectedUser('')
                    setChangeRootAdmin(!changeRootAdmin)
                  }}
                />
              }
            />
          </Grid>
          <Grid item xs={6} lg={8}>
            <Typography variant="subtitle2" color="textPrimary" gutterBottom>
              {t('licenses.oldDisplayName')} : {orgDisplayName}
            </Typography>
          </Grid>
          <TextInputField
            label={t('licenses.newDisplayName')}
            variant="standard"
            mdColumns={8}
            required={!changeRootAdmin}
            name="displayname"
            disabled={changeRootAdmin}
            placeholder={'eg. MagnaGmbh'}
            changeHandler={(name, value) => name && setDisplayName(value)}
            value={displayName}
          />

          <Grid item xs={6} lg={8} />
          <Grid item xs={6} lg={8}>
            <Typography variant="subtitle2">
              {t('labels.currentSuperAdmin') + ': '}
              {userProfile?.email}
            </Typography>
          </Grid>

          <Grid item xs={6} lg={8}>
            <TextField
              fullWidth
              select
              disabled={!changeRootAdmin}
              label={<>{t('labels.selectSuperAdmin')}</>}
              variant="standard"
              onChange={({ target }) => setSelectedUser(target.value as string)}
              value={selectedUser}
            >
              {superAdmins?.map((accUser) => (
                <MenuItem key={accUser} value={accUser}>
                  {accUser}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </ModalForm>
    </>
  )
}

export default UpdateOrganization
