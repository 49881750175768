import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};


export enum ActionType {
  Create = 'CREATE',
  Update = 'UPDATE',
  Delete = 'DELETE'
}

export type ActivatedCloudLicense = {
  __typename?: 'ActivatedCloudLicense';
  organizationName: Scalars['String'];
  licensePackets: Array<Maybe<ActivatedLicensePacket>>;
};

export type ActivatedCloudLicenseInput = {
  organizationName: Scalars['String'];
  licensePackets?: Maybe<Array<Maybe<ActivatedLicensePacketInput>>>;
};

export type ActivatedLicense = {
  __typename?: 'ActivatedLicense';
  id: Scalars['String'];
  reserved?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type ActivatedLicenseGroup = {
  __typename?: 'ActivatedLicenseGroup';
  id: Scalars['String'];
  groupname: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  licenseTypes: Array<Maybe<ActivatedLicenseType>>;
};

export type ActivatedLicenseGroupInput = {
  id: Scalars['String'];
  licenseTypes?: Maybe<Array<Maybe<ActivatedLicenseTypeInput>>>;
};

export type ActivatedLicenseInput = {
  id: Scalars['String'];
};

export type ActivatedLicensePacket = {
  __typename?: 'ActivatedLicensePacket';
  id: Scalars['String'];
  packetName: Scalars['String'];
  licenseGroups: Array<Maybe<ActivatedLicenseGroup>>;
};

export type ActivatedLicensePacketInfo = {
  __typename?: 'ActivatedLicensePacketInfo';
  licensePacketActivatedAt: Scalars['String'];
  licensePacketActivator: Scalars['String'];
  expirationDate: Scalars['String'];
};

export type ActivatedLicensePacketInput = {
  id: Scalars['String'];
  licenseGroups?: Maybe<Array<Maybe<ActivatedLicenseGroupInput>>>;
};

export type ActivatedLicenseType = {
  __typename?: 'ActivatedLicenseType';
  id: Scalars['String'];
  licenseType: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  licenses: Array<Maybe<ActivatedLicense>>;
};

export type ActivatedLicenseTypeInput = {
  id: Scalars['String'];
  licenses?: Maybe<Array<Maybe<ActivatedLicenseInput>>>;
};

export type ActivatedOrganizationLicense = {
  __typename?: 'ActivatedOrganizationLicense';
  id: Scalars['String'];
  organizationLicenseId: Scalars['String'];
  organizationName: Scalars['String'];
  cloudLicenseType: Scalars['String'];
  activationDate: Scalars['String'];
  expirationDate: Scalars['String'];
  licensePackets: Array<Maybe<ActivatedLicensePacket>>;
};

export type ActivatedOrganizationLicenseInfo = {
  __typename?: 'ActivatedOrganizationLicenseInfo';
  organizationName: Scalars['String'];
  organizationActivatedAt: Scalars['String'];
  organizationActivator: Scalars['String'];
  expirationDate: Scalars['String'];
};

export type AdminLicenseInput = {
  groupname: Scalars['String'];
  licenseType: Scalars['String'];
};

export type AppResourceRights = {
  __typename?: 'AppResourceRights';
  appResourceId: Scalars['String'];
  scopes: Array<Maybe<Scalars['String']>>;
};

export type AssignableLicenseInput = {
  licenseGroupID?: Maybe<Scalars['String']>;
  licenseTypeID?: Maybe<Scalars['String']>;
  licensePacketID?: Maybe<Scalars['String']>;
  licenseID?: Maybe<Scalars['String']>;
};

export type AssignedCloudLicenses = {
  __typename?: 'AssignedCloudLicenses';
  licensePoolID: Scalars['String'];
  poolName: Scalars['String'];
  poolSize: Scalars['Int'];
  numberOfLicenses: Scalars['Int'];
};

export type AssignedLicensePackets = {
  __typename?: 'AssignedLicensePackets';
  id: Scalars['String'];
  packetName: Scalars['String'];
  packetType: Scalars['String'];
  activationStatus: Scalars['Boolean'];
  packetKey: Scalars['String'];
  creationDate: Scalars['String'];
  activatedBy?: Maybe<Scalars['String']>;
};

export type CloudActionJournal = {
  __typename?: 'CloudActionJournal';
  id: Scalars['ID'];
  action_user: Scalars['String'];
  license_name?: Maybe<Scalars['String']>;
  license_pool_name?: Maybe<Scalars['String']>;
  license_type_name?: Maybe<Scalars['String']>;
  action_type: ActionType;
  date: Scalars['String'];
};

export type CloudCompaniesInfo = {
  __typename?: 'CloudCompaniesInfo';
  cloudLicenseID: Scalars['String'];
  expirationDate: Scalars['String'];
  realmId: Scalars['String'];
  realmName: Scalars['String'];
  licenseKey: Scalars['String'];
};

export type CloudDashboard = {
  __typename?: 'CloudDashboard';
  nLicensePools: Scalars['Int'];
  nLicenses: Scalars['Int'];
  nLicenseTypes: Scalars['Int'];
  nTrialLicenses: Scalars['Int'];
  lastTrialCloudLicenseDate?: Maybe<Scalars['String']>;
  lastCloudLicenseCreationDate?: Maybe<Scalars['String']>;
  lastLicensePoolCreationDate?: Maybe<Scalars['String']>;
  lastLicenseTypeName?: Maybe<Scalars['String']>;
  nUsers: Scalars['Int'];
};

export type CloudLicense = {
  __typename?: 'CloudLicense';
  id: Scalars['String'];
  licenseKey: Scalars['String'];
  editorId: Scalars['String'];
  organizationName: Scalars['String'];
  cloudLicenseType: Scalars['String'];
  dateModified: Scalars['String'];
  dateActivated?: Maybe<Scalars['String']>;
  expirationDate: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order_id: Scalars['String'];
  activatedBy?: Maybe<Scalars['String']>;
  licenseGroups: Array<Maybe<CloudLicenseGroup>>;
  assignedPackets?: Maybe<Array<Maybe<AssignedLicensePackets>>>;
};

export type CloudLicenseConfigInput = {
  expirationDate?: Maybe<Scalars['String']>;
  licIntMinutes?: Maybe<Scalars['Int']>;
};

export type CloudLicenseDetails = {
  __typename?: 'CloudLicenseDetails';
  id: Scalars['String'];
  licenseKey: Scalars['String'];
  editorId: Scalars['String'];
  realmId?: Maybe<Scalars['String']>;
  organizationName: Scalars['String'];
  cloudLicenseType: Scalars['String'];
  dateCreated?: Maybe<Scalars['String']>;
  dateModified: Scalars['String'];
  dateActivated?: Maybe<Scalars['String']>;
  expirationDate: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  order_id: Scalars['String'];
  activatedBy?: Maybe<Scalars['String']>;
  licenseIntMinutes: Scalars['Int'];
  cloudLicensePackets?: Maybe<Array<Maybe<CloudLicensePacketDetails>>>;
};

export type CloudLicenseGroup = {
  __typename?: 'CloudLicenseGroup';
  id: Scalars['String'];
  groupname: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  licenseTypes: Array<Maybe<CloudLicenseType>>;
};

export type CloudLicenseInfo = {
  __typename?: 'CloudLicenseInfo';
  cloudLicenseInfo: CloudLicense;
  journal?: Maybe<Array<Maybe<CloudActionJournal>>>;
};

export type CloudLicensePacket = {
  __typename?: 'CloudLicensePacket';
  packetId: Scalars['String'];
  packetName: Scalars['String'];
  numberOfLicenses: Scalars['Int'];
  assignedLicenses: Scalars['Int'];
  reservedLicenses: Array<Maybe<Scalars['String']>>;
};

export type CloudLicensePacketDetails = {
  __typename?: 'CloudLicensePacketDetails';
  id: Scalars['ID'];
  orderID: Scalars['String'];
  packetName: Scalars['String'];
  packetType: Scalars['String'];
  packetLicenseKey: Scalars['String'];
  activationStatus: Scalars['Boolean'];
  creationDate: Scalars['String'];
  activatedBy?: Maybe<Scalars['String']>;
  activationDate?: Maybe<Scalars['String']>;
  cloudlicensePacketGroups: Array<Maybe<CloudLicensePacketGroup>>;
  packetLog?: Maybe<Array<Maybe<PacketHistory>>>;
};

export type CloudLicensePacketGroup = {
  __typename?: 'CloudLicensePacketGroup';
  groupId: Scalars['ID'];
  groupname: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  licenseGroupTypeDetails: Array<Maybe<LicenseGroupTypeDetails>>;
};

export type CloudLicensePoolInput = {
  licensePoolID: Scalars['ID'];
  numberOfLicenses: Scalars['Int'];
};

export type CloudLicenseServiceVersionInformation = {
  __typename?: 'CloudLicenseServiceVersionInformation';
  /** version of user cloud license service */
  version: Scalars['String'];
  /** version int git + commit (git describe) */
  versionGit: Scalars['String'];
  /** timestamp of the iam build */
  buildTimestamp: Scalars['String'];
};

export type CloudLicenseType = {
  __typename?: 'CloudLicenseType';
  id: Scalars['String'];
  licenseType: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  rights: Array<Maybe<LicenseGroupRight>>;
  numberOfLicenses: Scalars['Int'];
  assignedLicenses: Scalars['Int'];
  numberOfReservedLicenses: Scalars['Int'];
  licensePackets: Array<Maybe<CloudLicensePacket>>;
};

export type CloudLicensesActivationsPage = {
  __typename?: 'CloudLicensesActivationsPage';
  cloudLicensesActivations: Array<Maybe<CloudLicenseDetails>>;
  totalSize: Scalars['Int'];
};

export type CloudLicensesListPage = {
  __typename?: 'CloudLicensesListPage';
  cloudLicensesList: Array<Maybe<CloudLicenseDetails>>;
  totalSize: Scalars['Int'];
};

export type CloudLicensesPage = {
  __typename?: 'CloudLicensesPage';
  cloudLicenses: Array<Maybe<CloudLicense>>;
  totalSize: Scalars['Int'];
};

export type CloudOrganizationLicense = {
  __typename?: 'CloudOrganizationLicense';
  id: Scalars['String'];
  organizationLicenseId: Scalars['String'];
  organizationName: Scalars['String'];
  cloudLicenseType: Scalars['String'];
  activationDate: Scalars['String'];
  expirationDate: Scalars['String'];
  licenseGroups: Array<Maybe<CloudLicenseGroup>>;
};

export type ContactLicenseInfo = {
  __typename?: 'ContactLicenseInfo';
  ID: Scalars['String'];
  orderID: Scalars['String'];
  dateCreated: Scalars['String'];
  dateUpdated: Scalars['String'];
  licensekey: Scalars['String'];
  licensePacketType: Scalars['String'];
  licensePacketName: Scalars['String'];
};

export type EndUserLicenseActivation = {
  __typename?: 'EndUserLicenseActivation';
  id: Scalars['ID'];
  license_key: Scalars['String'];
  date: Scalars['Int'];
  types: Array<Scalars['String']>;
};

export type License = {
  __typename?: 'License';
  id: Scalars['ID'];
  license_key: Scalars['String'];
  date_modified: Scalars['String'];
  license_pool_id: Scalars['ID'];
  cloud_license_id: Scalars['ID'];
  order_id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  activation_maximum?: Maybe<Scalars['Int']>;
  nActivations: Scalars['Int'];
  editor_id: Scalars['String'];
};

export type LicenseFilterUpdater = {
  license_pool_id?: Maybe<Scalars['ID']>;
  cloud_license_id?: Maybe<Scalars['ID']>;
  license_key?: Maybe<Scalars['String']>;
};

export type LicenseGroup = {
  __typename?: 'LicenseGroup';
  id: Scalars['String'];
  groupname: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  groupRights: Array<Maybe<LicenseGroupRight>>;
};

export type LicenseGroupInput = {
  groupname: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  groupRights: Array<Maybe<LicenseGroupRightInput>>;
};

export type LicenseGroupRight = {
  __typename?: 'LicenseGroupRight';
  rightName: Scalars['String'];
  scopes: Array<Maybe<Scalars['String']>>;
};

export type LicenseGroupRightInput = {
  rightName: Scalars['String'];
  scopes: Array<Maybe<Scalars['String']>>;
};

export type LicenseGroupTypeDetails = {
  __typename?: 'LicenseGroupTypeDetails';
  id: Scalars['String'];
  licenseType: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  numberOfLicenses: Scalars['Int'];
  assignedLicenses: Scalars['Int'];
  licenseUsers?: Maybe<Array<Maybe<ActivatedLicense>>>;
  numberOfReservedLicenses: Scalars['Int'];
  typeLogs?: Maybe<Array<Maybe<LicenseTypeHistory>>>;
};

export type LicenseInstance = {
  __typename?: 'LicenseInstance';
  id: Scalars['ID'];
  license_instance_key: Scalars['String'];
  user_id: Scalars['ID'];
  date_modified: Scalars['String'];
  system_identifiers?: Maybe<Scalars['String']>;
};

export type LicenseInstancePage = {
  __typename?: 'LicenseInstancePage';
  instances: Array<LicenseInstance>;
  total: Scalars['Int'];
};

export type LicenseMetadata = {
  hostname: Scalars['String'];
  uuid: Scalars['String'];
};

export type LicensePacketInfo = {
  __typename?: 'LicensePacketInfo';
  orderId: Scalars['String'];
  creationDate: Scalars['String'];
  activatedOrganizationInfo?: Maybe<ActivatedOrganizationLicenseInfo>;
  activatedLicensePacketInfo?: Maybe<ActivatedLicensePacketInfo>;
  licensePacket: ActivatedLicensePacket;
  assignedGroupLicences?: Maybe<Array<Maybe<CloudLicensePacketGroup>>>;
};

export type LicensePage = {
  __typename?: 'LicensePage';
  licenses: Array<License>;
  total: Scalars['Int'];
};

export type LicensePool = {
  __typename?: 'LicensePool';
  id: Scalars['ID'];
  poolName: Scalars['String'];
  editor_id: Scalars['ID'];
  license_type: LicenseType;
  order_id: Scalars['ID'];
  date_modified: Scalars['String'];
  date_Created: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  pool_size?: Maybe<Scalars['Int']>;
  n_in_use: Scalars['Int'];
  group_id: Scalars['ID'];
};

export type LicensePoolFilter = {
  type_key?: Maybe<Scalars['String']>;
};

export type LicensePoolIdInput = {
  poolName: Scalars['String'];
  group_id: Scalars['ID'];
  license_type_id: Scalars['ID'];
  expiration_date: Scalars['String'];
  order_id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pool_size?: Maybe<Scalars['Int']>;
};

export type LicensePoolInput = {
  poolName: Scalars['String'];
  groupname: Scalars['String'];
  type_key: Scalars['String'];
  expiration_date: Scalars['String'];
  order_id?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  pool_size?: Maybe<Scalars['Int']>;
};

export type LicenseType = {
  __typename?: 'LicenseType';
  id: Scalars['ID'];
  groupId: Scalars['String'];
  licenseType: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  activationMaximum: Scalars['Int'];
  date_Created: Scalars['String'];
};

export type LicenseTypeHistory = {
  __typename?: 'LicenseTypeHistory';
  typeId: Scalars['String'];
  typeName: Scalars['String'];
  numberOfLicenses: Scalars['Int'];
  dateCreated: Scalars['String'];
  dateModified: Scalars['String'];
  modifiedBy: Scalars['String'];
};

export type LicenseTypeInput = {
  groupId: Scalars['String'];
  licenseType: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
  activationMaximum: Scalars['Int'];
  groupRights: Array<Maybe<LicenseGroupRightInput>>;
};

export type LicenseTypePage = {
  __typename?: 'LicenseTypePage';
  licenseTypes: Array<LicenseType>;
  total: Scalars['Int'];
};

export type LicenseTypeRights = {
  __typename?: 'LicenseTypeRights';
  licenseTypeId: Scalars['String'];
  appResourceRights?: Maybe<Array<Maybe<AppResourceRights>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createLicenseType: Scalars['ID'];
  updateLicenseType: Scalars['Boolean'];
  deleteLicenseType: Scalars['Boolean'];
  createCloudLicense: Scalars['String'];
  assignLicensesById?: Maybe<Array<Maybe<SingleUserCloudLicense>>>;
  createLicensePacket: Scalars['String'];
  updatePacketName: Scalars['Boolean'];
  activateCloudLicense?: Maybe<ActivatedCloudLicense>;
  updateCloudLicense?: Maybe<ActivatedCloudLicense>;
  updateCloudLicenseExpirationDate: Scalars['Boolean'];
  deactivateCloudLicense: Scalars['Boolean'];
  activateLicensePacket?: Maybe<ActivatedLicensePacket>;
  deactivateLicensePacket: Scalars['Boolean'];
  assignLicenses?: Maybe<Array<Maybe<SingleUserCloudLicense>>>;
  transferLicenses?: Maybe<Array<Maybe<SingleUserCloudLicense>>>;
  freeLicenses: Scalars['Boolean'];
  reserveLicense: Scalars['Boolean'];
  changeLicenseNumber: Scalars['Boolean'];
  deleteCloudLicenseFull: Scalars['Boolean'];
  clearCloudLicenseInstanceLogs: Scalars['Boolean'];
  registerLicenseGroup: Scalars['ID'];
  createLicensePoolID: Scalars['ID'];
  createLicensePool: Scalars['ID'];
  updateLicensePoolID: Scalars['Boolean'];
  updateLicensePool: Scalars['Boolean'];
  deleteLicensePool: Scalars['Boolean'];
  initTrialLicenseConfig: Scalars['Boolean'];
  createTrialLicense?: Maybe<Scalars['String']>;
  clearTrialLicenseLogs: Scalars['Boolean'];
};


export type MutationCreateLicenseTypeArgs = {
  licenseType: LicenseTypeInput;
};


export type MutationUpdateLicenseTypeArgs = {
  id: Scalars['ID'];
  licenseType: LicenseTypeInput;
};


export type MutationDeleteLicenseTypeArgs = {
  id: Scalars['ID'];
};


export type MutationCreateCloudLicenseArgs = {
  licenseKey: Scalars['String'];
  orderId: Scalars['String'];
  organizationName: Scalars['String'];
  licenses: Array<Maybe<CloudLicensePoolInput>>;
  adminLicenses: Array<Maybe<AdminLicenseInput>>;
  config: CloudLicenseConfigInput;
};


export type MutationAssignLicensesByIdArgs = {
  realmName: Scalars['String'];
  userId: Scalars['String'];
  licenses: Array<Maybe<SingleUserCloudLicenseIdInput>>;
  assignableLicenses?: Maybe<ActivatedCloudLicenseInput>;
};


export type MutationCreateLicensePacketArgs = {
  licenseKey: Scalars['String'];
  orderId: Scalars['String'];
  cloudLicenseId: Scalars['String'];
  licenses: Array<Maybe<CloudLicensePoolInput>>;
  licensePacketName: Scalars['String'];
  licensePacketType: Scalars['String'];
};


export type MutationUpdatePacketNameArgs = {
  realmId: Scalars['ID'];
  packetId: Scalars['ID'];
  newPacketName: Scalars['String'];
};


export type MutationActivateCloudLicenseArgs = {
  licenseKey: Scalars['String'];
  realmId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateCloudLicenseArgs = {
  licenseKey: Scalars['String'];
  realmId: Scalars['String'];
  adminId: Scalars['String'];
};


export type MutationUpdateCloudLicenseExpirationDateArgs = {
  cloudLicenseId: Scalars['ID'];
  config: CloudLicenseConfigInput;
};


export type MutationDeactivateCloudLicenseArgs = {
  licenseKey: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationActivateLicensePacketArgs = {
  licenseKey: Scalars['String'];
  realmId: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  reserve?: Maybe<Scalars['Boolean']>;
};


export type MutationDeactivateLicensePacketArgs = {
  licenseKey: Scalars['String'];
};


export type MutationAssignLicensesArgs = {
  realmName: Scalars['String'];
  userId: Scalars['String'];
  licenses: Array<Maybe<SingleUserCloudLicenseInput>>;
  assignableLicenses?: Maybe<ActivatedCloudLicenseInput>;
};


export type MutationTransferLicensesArgs = {
  realmName: Scalars['String'];
  userId1: Scalars['String'];
  userId2: Scalars['String'];
  keepLics: Scalars['Boolean'];
};


export type MutationFreeLicensesArgs = {
  realmName: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationReserveLicenseArgs = {
  licenseId: Scalars['String'];
  userEmail: Scalars['String'];
};


export type MutationChangeLicenseNumberArgs = {
  cloudLicenseId: Scalars['String'];
  licensePacketId: Scalars['String'];
  licensePacketLicenseTypeId: Scalars['String'];
  newNumberOfLicenses: Scalars['Int'];
};


export type MutationDeleteCloudLicenseFullArgs = {
  licenseKey: Scalars['String'];
  realmId: Scalars['String'];
};


export type MutationClearCloudLicenseInstanceLogsArgs = {
  cloudLicenseId: Scalars['String'];
};


export type MutationRegisterLicenseGroupArgs = {
  licenseGroup?: Maybe<LicenseGroupInput>;
};


export type MutationCreateLicensePoolIdArgs = {
  licensePool: LicensePoolIdInput;
};


export type MutationCreateLicensePoolArgs = {
  licensePool: LicensePoolInput;
};


export type MutationUpdateLicensePoolIdArgs = {
  id: Scalars['ID'];
  licensePool: LicensePoolIdInput;
};


export type MutationUpdateLicensePoolArgs = {
  id: Scalars['ID'];
  licensePool: LicensePoolInput;
};


export type MutationDeleteLicensePoolArgs = {
  id: Scalars['ID'];
};


export type MutationInitTrialLicenseConfigArgs = {
  licenses: Array<Maybe<CloudLicensePoolInput>>;
  adminLicenses: Array<Maybe<SingleUserCloudLicenseInput>>;
  trialDays: Scalars['Int'];
};


export type MutationClearTrialLicenseLogsArgs = {
  cloudLicenseId: Scalars['String'];
};

export type OrganizationUserCloudLicense = {
  __typename?: 'OrganizationUserCloudLicense';
  cloudLicenseType: Scalars['String'];
  expirationDate: Scalars['String'];
  licenses: Array<Maybe<SingleUserCloudLicense>>;
};

export type PacketHistory = {
  __typename?: 'PacketHistory';
  logId: Scalars['String'];
  actionType: Scalars['String'];
  licenseKey: Scalars['String'];
  dateModified: Scalars['String'];
  modifiedBy: Scalars['String'];
  groupName?: Maybe<Scalars['String']>;
  packetName: Scalars['String'];
  expirationDate?: Maybe<Scalars['String']>;
  orderID?: Maybe<Scalars['String']>;
};

export type PaginationParams = {
  limit?: Maybe<Scalars['Int']>;
  Offset?: Maybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  licenseTypes: LicenseTypePage;
  licenseType?: Maybe<LicenseType>;
  getAllLicenseTypes: Array<Maybe<LicenseType>>;
  getLicenseGroupLicenseTypeRights: Array<Maybe<LicenseTypeRights>>;
  cloudCompanies: Array<Maybe<CloudCompaniesInfo>>;
  getclServiceVersion: CloudLicenseServiceVersionInformation;
  generateKey: Scalars['String'];
  getActivatedCloudLicensePackets: ActivatedCloudLicense;
  getListCloudLicenses: CloudLicensesPage;
  getListActivatedCloudLicenses: CloudLicensesPage;
  getCloudLicenseById: CloudLicenseInfo;
  getCloudLicenseDetails: CloudLicenseDetails;
  getCloudLicenseDetailsByKey: CloudLicenseDetails;
  getCloudLicenseDetailsByOrderID: Array<Maybe<CloudLicenseDetails>>;
  getCloudLicense: CloudOrganizationLicense;
  getAssignedLicenses: OrganizationUserCloudLicense;
  getLicensePacketInformation?: Maybe<LicensePacketInfo>;
  getCloudLicensesList: CloudLicensesListPage;
  getActivatedCloudLicenses: CloudLicensesActivationsPage;
  getContactLicenseInfo: Array<Maybe<ContactLicenseInfo>>;
  dashboardInfo: CloudDashboard;
  getAllLicenseGroups: Array<Maybe<LicenseGroup>>;
  getLicenseGroupByName?: Maybe<LicenseGroup>;
  getLicensePoolByName: LicensePool;
  licensePools: Array<Maybe<LicensePool>>;
  licensePool: LicensePool;
  hasFreeTrialLicense: Scalars['Boolean'];
};


export type QueryLicenseTypesArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryLicenseTypeArgs = {
  id: Scalars['ID'];
};


export type QueryGetLicenseGroupLicenseTypeRightsArgs = {
  licenseGroupId: Scalars['String'];
};


export type QueryGenerateKeyArgs = {
  cloudLicenseId?: Maybe<Scalars['String']>;
};


export type QueryGetActivatedCloudLicensePacketsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetListCloudLicensesArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryGetListActivatedCloudLicensesArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryGetCloudLicenseByIdArgs = {
  ID: Scalars['ID'];
};


export type QueryGetCloudLicenseDetailsArgs = {
  ID: Scalars['ID'];
};


export type QueryGetCloudLicenseDetailsByKeyArgs = {
  licenseKey: Scalars['String'];
};


export type QueryGetCloudLicenseDetailsByOrderIdArgs = {
  orderIDs: Array<Maybe<Scalars['ID']>>;
};


export type QueryGetCloudLicenseArgs = {
  realmName: Scalars['String'];
};


export type QueryGetAssignedLicensesArgs = {
  realmName: Scalars['String'];
  userId: Scalars['String'];
};


export type QueryGetLicensePacketInformationArgs = {
  licenseKey: Scalars['String'];
};


export type QueryGetCloudLicensesListArgs = {
  paginationInfo?: Maybe<PaginationParams>;
};


export type QueryGetActivatedCloudLicensesArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  fetchTrial: Scalars['Boolean'];
};


export type QueryGetLicenseGroupByNameArgs = {
  groupname: Scalars['String'];
};


export type QueryGetLicensePoolByNameArgs = {
  poolName: Scalars['String'];
};


export type QueryLicensePoolsArgs = {
  paginationInfo?: Maybe<PaginationParams>;
  filter?: Maybe<LicensePoolFilter>;
};


export type QueryLicensePoolArgs = {
  id: Scalars['ID'];
};

export type SimpleLicenseType = {
  __typename?: 'SimpleLicenseType';
  id: Scalars['ID'];
  type_key: Scalars['String'];
  activation_maximum?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type SingleUserCloudLicense = {
  __typename?: 'SingleUserCloudLicense';
  id: Scalars['String'];
  groupId: Scalars['String'];
  licenseTypeId: Scalars['String'];
  packetId: Scalars['String'];
  groupname: Scalars['String'];
  licenseType: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  reserved?: Maybe<Scalars['String']>;
  regularLicense?: Maybe<Scalars['String']>;
  permissions: Array<Maybe<LicenseGroupRight>>;
  expirationDate: Scalars['String'];
};

export type SingleUserCloudLicenseIdInput = {
  licenseTypeId: Scalars['String'];
  packetId: Scalars['String'];
  regularLicense?: Maybe<Scalars['String']>;
};

export type SingleUserCloudLicenseInput = {
  licenseGroupID: Scalars['String'];
  licenseTypeID: Scalars['String'];
  licensePacketID: Scalars['String'];
  regularLicense?: Maybe<Scalars['String']>;
};

export type ActivateCloudTrialLicenseMutationVariables = Exact<{ [key: string]: never; }>;


export type ActivateCloudTrialLicenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createTrialLicense'>
);


export const ActivateCloudTrialLicenseDocument = gql`
    mutation ActivateCloudTrialLicense {
  createTrialLicense
}
    `;
export type ActivateCloudTrialLicenseMutationFn = Apollo.MutationFunction<ActivateCloudTrialLicenseMutation, ActivateCloudTrialLicenseMutationVariables>;

/**
 * __useActivateCloudTrialLicenseMutation__
 *
 * To run a mutation, you first call `useActivateCloudTrialLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCloudTrialLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCloudTrialLicenseMutation, { data, loading, error }] = useActivateCloudTrialLicenseMutation({
 *   variables: {
 *   },
 * });
 */
export function useActivateCloudTrialLicenseMutation(baseOptions?: Apollo.MutationHookOptions<ActivateCloudTrialLicenseMutation, ActivateCloudTrialLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateCloudTrialLicenseMutation, ActivateCloudTrialLicenseMutationVariables>(ActivateCloudTrialLicenseDocument, options);
      }
export type ActivateCloudTrialLicenseMutationHookResult = ReturnType<typeof useActivateCloudTrialLicenseMutation>;
export type ActivateCloudTrialLicenseMutationResult = Apollo.MutationResult<ActivateCloudTrialLicenseMutation>;
export type ActivateCloudTrialLicenseMutationOptions = Apollo.BaseMutationOptions<ActivateCloudTrialLicenseMutation, ActivateCloudTrialLicenseMutationVariables>;