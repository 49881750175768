import { environment } from '../../helpers/environment'
import { keycloak } from '../../helpers/keycloakService'
import axios, { AxiosRequestConfig } from 'axios'

export const initializeDemoDb = async (realmName: string): Promise<any> => {
  const baseUri = environment.LR_DEMO_SERVICE

  if (keycloak && keycloak.isTokenExpired(300)) {
    await keycloak.updateToken(5)
  }

  const config: AxiosRequestConfig = {
    headers: { Authorization: `Bearer ${keycloak.token}` },
  }
  const url = baseUri + '/initialize' + `?realmName=${realmName}`
  //console.log('datainp', dataImp)
  const result = await axios.post(url, undefined, config)
  //console.log('result', result)

  return result
}

const iamOperations: string[] = [
  'GetAccessibleUsers',
  'getLicensePacketInformation',
  'GetLicensePacketPermissions',
  'hasAllRootPermissions',
  'ModifyRootAdmin',
  'GetAccessibleOrgLicensePackets',
]

const orgDataOpertions: string[] = [
  'ActivateOrganization',

  'ModifyPacketName',
  'UpdateTrialLicense',
  'ActivateLicensePacket',
  'hasRootPermissions',
  'ChangeOrganizationName',
]

const userPreferenceOperations: string[] = ['UpdateUserLang', 'ChangeEmail']

const demoDataOperations: string[] = ['AddDemoData']

const licenseOperations: string[] = ['ActivateCloudTrialLicense', 'GetLicenseGroups']

const { API, IAMAPI, ORGAPI, LICENSEAPI, DEMOSERVICEAPI } = environment

export const getApolloURI = (operationName: string): string => {
  if (iamOperations.includes(operationName)) {
    return IAMAPI
  } else if (orgDataOpertions.includes(operationName)) {
    return ORGAPI
  } else if (licenseOperations.includes(operationName)) {
    return LICENSEAPI
  } else if (demoDataOperations.includes(operationName)) {
    return DEMOSERVICEAPI.GQL_HTTP_URI
  } else if (userPreferenceOperations.includes(operationName)) {
    return environment.USER_PREFERENCE_API
  }
  return API.GQL_HTTP_URI
}
