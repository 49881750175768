import React, { ChangeEvent, useCallback, useMemo } from 'react'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import Grid from '@material-ui/core/Grid'

export type TextProps = {
  label: string
  value?: string
  columns?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  mdColumns?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  aloneInRow?: boolean
  displayOnly?: boolean
  changeHandler?: (e: string) => void
  multiline?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  onlyNumbers?: boolean
  required?: boolean
  margin?: 'dense' | 'normal' | 'none'
}

export const Text: React.FC<TextProps> = ({
  value,
  mdColumns,
  changeHandler,
  displayOnly,
  aloneInRow,
  columns,
  label,
  multiline,
  variant,
  onlyNumbers,
  required,
  margin,
}) => {
  const { t } = useTranslation('common')
  const shownLabel = useMemo(() => t(label), [t, label])
  const inputType = useMemo(() => (onlyNumbers ? 'number' : 'text'), [onlyNumbers])

  const onChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (!changeHandler) {
        return
      }
      let val = e.target.value as string
      if (onlyNumbers) {
        val = val.replace(',', '.')
      }
      changeHandler(val)
    },
    [changeHandler, onlyNumbers],
  )

  return (
    <>
      <Grid item xs={columns ? columns : 6} md={mdColumns ? mdColumns : columns ? columns : 6}>
        <TextField
          margin={margin ? margin : 'none'}
          multiline={multiline}
          variant={variant ? variant : multiline ? 'outlined' : 'standard'}
          rows={multiline ? 4 : 1}
          onChange={onChange}
          disabled={displayOnly}
          type={inputType}
          value={value}
          required={required}
          fullWidth={true}
          label={shownLabel}
        />
      </Grid>
      {aloneInRow && <Grid item xs={6} />}
    </>
  )
}
