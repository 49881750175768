import React, { useCallback, useEffect, useState } from 'react'
import Paper from '@material-ui/core/Paper'
import PermMediaIcon from '@material-ui/icons/PermMediaOutlined'
import DeleteIcon from '@material-ui/icons/DeleteOutlined'
import Grid from '@material-ui/core/Grid/Grid'
import ImageUploadSlider, { SliderDirection } from './ImageUploadSlider'
import { CsButton } from '@csinstruments/cs-react-theme'
import { fullHeight } from '../../../../styles/shared'

export type ImageUploadProps = {
  image?: string
  imageChanged?: (image: string) => void
  imageRemoved?: () => void
  style?: React.CSSProperties
  slideAction?: (direction: SliderDirection) => void
  imageClickAction?: () => void
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  image,
  imageClickAction,
  slideAction,
  imageRemoved,
  style,
  imageChanged,
}: ImageUploadProps) => {
  const [img, setImg] = useState<string>('')

  useEffect(() => {
    // if (image) {
    setImg(image ?? '')
    // }
  }, [image])

  const handleImageChange = (image: string): void => {
    setImg(image)
    imageChanged && imageChanged(image)
  }

  const removeImage = (): void => {
    handleImageChange('')
    imageRemoved && imageRemoved()
  }

  const handleChange = (selectorFiles: FileList | null): void => {
    if (selectorFiles && selectorFiles.length > 0) {
      const reader = new FileReader()

      const file = selectorFiles.item(0)
      if (file) {
        reader.readAsDataURL(file)

        reader.onload = () => {
          const result = reader.result
          handleImageChange(result as string)
          //console.log('result', result)
        }
      }
    }
  }

  const imageClicked = useCallback(() => {
    imageClickAction && imageClickAction()
  }, [imageClickAction])

  return (
    <div style={style ? style : {}}>
      <Paper style={{ width: '100%', height: '300px', marginBottom: '10px', overflow: 'hidden', position: 'relative' }}>
        {slideAction && <ImageUploadSlider direction={'left'} clicked={slideAction} />}
        <div
          style={{
            ...fullHeight,
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '100%',
            position: 'absolute',
          }}
        >
          {img && (
            <img
              alt="logo"
              height="240px"
              width="320px"
              style={{ marginLeft: 'auto', marginRight: 'auto', cursor: imageClickAction ? 'pointer' : 'default' }}
              src={img}
              onClick={imageClicked}
            />
          )}
        </div>
        {slideAction && <ImageUploadSlider direction={'right'} clicked={slideAction} />}
      </Paper>
      <input
        accept="image/*"
        style={{ display: 'none' }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={(e) => handleChange(e.target.files)}
      />
      <Grid container>
        <Grid item xs={3} md={5} />
        {img && (
          <Grid item xs={4} md={3}>
            <CsButton fullwidth={true} onClick={removeImage} color="primary" small={true}>
              <DeleteIcon />
            </CsButton>
          </Grid>
        )}
        <Grid item xs={1} />
        {!img && <Grid item xs={4} md={3} />}
        <Grid item xs={4} md={3}>
          <label htmlFor="raised-button-file">
            <CsButton small={true} fileUpload={true} fullwidth={true}>
              <PermMediaIcon />
            </CsButton>
          </label>
        </Grid>
      </Grid>
    </div>
  )
}
