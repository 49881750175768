import React, { useEffect, useState } from 'react'

import { useTranslation } from '../../../hooks/useTranslation'

import { Checkbox, FormControlLabel, Grid, MenuItem, TextField, Typography } from '@material-ui/core'
import {
  GetUserProfileDocument,
  useGetUserProfileQuery,
  UserProfileDataInput,
  UserProfileIdImage,
  useUpdateUserProfileDataMutation,
  useUpdateUserProfileImageMutation,
} from '../../../../api/models'
import { TextInputField } from '../../partials/Inputs/TextInputField'
import { CsButtonPrimary } from '@csinstruments/cs-react-theme'
import { paddingTopBottom, standardContentPadding, userForm } from '../../../../styles/shared'
import { ResultAlert } from '../../partials/ResultAlert'
import { useUpdateEmail } from '../../../hooks/useUpdateEmail'
import ProgressBar from '../../partials/ProgressBar'
import DeleteAccountModal from '../../partials/DeleteAccountModal'
import { ImageUpload } from '../../partials/ImageUpload/ImageUpload'
import { useKeycloak } from '@react-keycloak/web'
import { UserProfileType } from '../../../Interfaces/KeycloakTypes'
import { countries } from '../../../../constants/sageCountries'
import { useUpdateNewsLetterPreference } from '../../../hooks/newsletter'
import { ModalForm } from '../../partials/ModalForm'
import LeakReporterCloudInfo from '../../partials/LeakReporterCloudInfo'
import { environment } from '../../../helpers/environment'

export const UserProfile: React.FC = () => {
  const { keycloak } = useKeycloak()
  const user_Info: UserProfileType | undefined = keycloak.userInfo
  const { t, i18n } = useTranslation()
  const [emailToChange, setEmailToChange] = useState<string>('')
  const [subscribed, setSubscribed] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)

  const [userUpdate, userUpdateStatus] = useUpdateUserProfileDataMutation({
    refetchQueries: [{ query: GetUserProfileDocument }],
  })

  const { data: userInfo, loading, error } = useGetUserProfileQuery()

  const { confirmChange, updated, updating } = useUpdateEmail()

  const initialInput: UserProfileDataInput = {
    Title: '',
    FirstName: '',
    LastName: '',
    CompanyName: '',
    Phone: '',
    Mobile: '',
    Fax: '',
    Country: '',
    Zip: '',
    Street: '',
    City: '',
    Department: '',
    Relationship: '',
  }

  const [userProfileVar, setUserProfileVar] = useState<UserProfileDataInput>(initialInput)
  const [userProfileImage, setUserProfileImage] = useState<UserProfileIdImage>({
    Name: '',
    Type: '',
    Content: '',
  })

  const [updateImage] = useUpdateUserProfileImageMutation()

  const [profileUpdated, setProfileUpdated] = useState<boolean>(false)

  const { updatePreference } = useUpdateNewsLetterPreference()

  const changeUserProfile = (name: string, value: string) => {
    setUserProfileVar({
      ...userProfileVar,
      [name]: value,
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEmailToChange('')
  }

  const updatePassword = () => {
    keycloak?.login({ action: 'UPDATE_PASSWORD', locale: i18n.language })
  }

  const changeLogin = (email: string) => {
    setEmailToChange(email)
  }

  const handleConfirm = async () => {
    try {
      await confirmChange(emailToChange)
      handleClose()
    } catch (e) {
      console.error(e)
    }
  }

  const submitForm = async () => {
    try {
      await userUpdate({ variables: { userProfileDataInput: userProfileVar } })
      await updatePreference(subscribed)
      const result = await updateImage({
        variables: {
          userProfileImage: {
            Content: userProfileImage.Content,
            Type: userProfileImage.Type,
            Name: userProfileImage.Name,
          },
        },
      })
      if (result.data?.updateUserProfileImage) {
        setUserProfileImage({
          ...userProfileImage,
        })
      }
    } catch (er) {
      console.error('err:', er)
    }
  }

  const getUserProfileData = () => {
    const originalUserInput = userInfo?.getUserProfile
    const oKeys = originalUserInput ? Object.keys(originalUserInput) : []
    const oValues = originalUserInput ? Object.values(originalUserInput) : []

    const userProf = oKeys.reduce(
      (acc: UserProfileDataInput, o, i) => (oValues[i] === 'null' ? { ...acc, [o]: '' } : { ...acc, [o]: oValues[i] }),
      initialInput,
    )

    //console.log({ userProf })

    const {
      Title,
      FirstName,
      LastName,
      CompanyName,
      Phone,
      Mobile,
      Fax,
      Country,
      Zip,
      Street,
      City,
      Department,
      Relationship,
    } = userProf
    const cntry =
      countries.find(
        (cn) => cn.country.toLowerCase() === Country.toLowerCase() || cn.value.toLowerCase() === Country.toLowerCase(),
      )?.value ?? Country.toLowerCase()
    setUserProfileVar({
      Title,
      FirstName,
      LastName,
      CompanyName,
      Phone,
      Mobile,
      Fax,
      Country: cntry,
      Zip,
      Street,
      City,
      Department,
      Relationship,
    } as UserProfileDataInput)
    if (originalUserInput) {
      setUserProfileImage({
        Name: originalUserInput.FirstName,
        Type: 'img',
        Content: originalUserInput.Image?.Content as string,
      })
      setSubscribed(!!originalUserInput.Newsletter)
    }
  }
  useEffect(() => {
    if (!(loading || updating) && (userInfo?.getUserProfile || updated)) {
      getUserProfileData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo, loading, updating, updated])
  const { loading: load, data } = userUpdateStatus

  useEffect(() => {
    if (data?.updateUserProfileData) {
      setProfileUpdated(!!data?.updateUserProfileData)
    }
  }, [data])

  if (loading || updating || load) {
    return (
      <>
        <ProgressBar />
      </>
    )
  }
  /* const isLicenseExpired = (days: number) => {
    return days <= 0
  }

  const changeOrgName = (orgName: string): void => {
    setOrgRealmName(orgName)
    setOrgDisplayName(permissions?.find((p) => p.realmName === orgName)?.displayName ?? '')
    setChangeNameTab(true)
  } */

  return (
    <>
      <div style={{ ...paddingTopBottom, ...standardContentPadding }}>
        {profileUpdated && <ResultAlert alertText={'contact.creationSucceeded'} severity="success" />}
        {(error?.message || userUpdateStatus?.error) && (
          <ResultAlert alertText={`${error?.message || userUpdateStatus?.error?.message}`} severity="error" />
        )}

        {/* <UpdateOrganization
          active={changeNameTab}
          realmName={orgRealmName}
          orgDisplayName={orgDisplayName}
          closeChangeNameTab={setChangeNameTab}
        /> */}
        <Grid item container spacing={3} justifyContent="center">
          <ModalForm
            formOpen={open}
            formClose={handleClose}
            headingText={t('modals.confirmationHeading')}
            submitForm={handleConfirm}
            disableSubmit={updating || !emailToChange}
            disableClose={updating}
          >
            <TextField
              label={t('contact.email')}
              variant="standard"
              value={emailToChange}
              onChange={({ target }) => changeLogin(target.value)}
            />
          </ModalForm>

          <Grid item lg={5} container style={userForm} justifyContent="center" direction="column">
            <Grid item>
              <Typography variant="h4" color="secondary" align="center" gutterBottom>
                {t('heading.personalDetails')}
              </Typography>
            </Grid>

            <Grid item container justifyContent="center" alignContent="stretch" spacing={2}>
              <TextInputField
                mdColumns={5}
                label={t('contact.firstName')}
                required
                variant="standard"
                name="FirstName"
                changeHandler={changeUserProfile}
                value={userProfileVar.FirstName}
              />

              <TextInputField
                mdColumns={5}
                label={t('contact.familyName')}
                required
                variant="standard"
                name="LastName"
                changeHandler={changeUserProfile}
                value={userProfileVar.LastName}
              />
              <TextInputField
                mdColumns={10}
                label={'email'}
                disabled
                variant="standard"
                name="email"
                changeHandler={changeUserProfile}
                value={user_Info?.email ?? ''}
              />

              <TextInputField
                mdColumns={5}
                label={t('contact.company')}
                variant="standard"
                name="CompanyName"
                changeHandler={changeUserProfile}
                value={userProfileVar.CompanyName}
              />

              <TextInputField
                mdColumns={5}
                label={t('contact.phone')}
                variant="standard"
                name="Phone"
                changeHandler={changeUserProfile}
                value={userProfileVar.Phone}
              />
              <TextInputField
                mdColumns={5}
                label={t('contact.fax')}
                variant="standard"
                name="Fax"
                changeHandler={changeUserProfile}
                value={userProfileVar.Fax as string}
              />
              <TextInputField
                mdColumns={5}
                label={t('contact.mobile')}
                variant="standard"
                name="Mobile"
                changeHandler={changeUserProfile}
                value={userProfileVar.Mobile as string}
              />
              <TextInputField
                mdColumns={5}
                label={t('contact.street')}
                variant="standard"
                name="Street"
                changeHandler={changeUserProfile}
                value={userProfileVar.Street as string}
              />
              <TextInputField
                mdColumns={5}
                label={t('contact.city')}
                variant="standard"
                name="City"
                changeHandler={changeUserProfile}
                value={userProfileVar.City as string}
              />
              <TextInputField
                mdColumns={5}
                label={t('contact.country')}
                variant="standard"
                required
                select
                name="Country"
                changeHandler={changeUserProfile}
                value={
                  countries.find(
                    (cn) =>
                      cn.country.toLowerCase() === userProfileVar.Country.toLowerCase() ||
                      cn.value.toLowerCase() === userProfileVar.Country.toLowerCase(),
                  )?.value ?? userProfileVar.Country
                }
              >
                {countries.map((c) => (
                  <MenuItem key={c.country + c.value} value={c.value}>
                    {c.value}
                  </MenuItem>
                ))}
              </TextInputField>
              <TextInputField
                mdColumns={5}
                label={t('contact.zip')}
                variant="standard"
                name="Zip"
                changeHandler={changeUserProfile}
                value={userProfileVar.Zip as string}
              />
              <TextInputField
                mdColumns={5}
                label={t('contact.department')}
                variant="standard"
                name="Department"
                changeHandler={changeUserProfile}
                value={userProfileVar.Department as string}
              />
              <TextInputField
                mdColumns={5}
                label={t('contact.Relationship')}
                variant="standard"
                name="Relationship"
                changeHandler={changeUserProfile}
                value={userProfileVar.Relationship as string}
              />
              <Grid item container direction="column">
                <Grid item xs={12}>
                  <ImageUpload
                    image={userProfileImage.Content}
                    imageChanged={(image: string) => {
                      setUserProfileImage((prv) => ({
                        ...prv,
                        Content: image,
                      }))
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <FormControlLabel
                label={t('labels.newsletter')}
                control={<Checkbox checked={subscribed} onChange={() => setSubscribed(!subscribed)} />}
              />
            </Grid>

            <Grid item>
              <CsButtonPrimary label={t('actions.save')} fullwidth onClick={() => submitForm()} />
            </Grid>
          </Grid>
          <Grid item container lg={3} justifyContent="flex-start" alignItems="stretch" spacing={2} direction="column">
            <Grid item>
              <Typography variant="h4" color="secondary" align="center">
                {t('heading.accountManagement')}
              </Typography>
            </Grid>
            <Grid item>
              <CsButtonPrimary fullwidth label={t('actions.updatePass')} onClick={updatePassword} />
            </Grid>
            <Grid item>
              <DeleteAccountModal />
            </Grid>
            <Grid item>
              <CsButtonPrimary fullwidth label={t('actions.changeLogin')} onClick={handleClickOpen} />
            </Grid>
          </Grid>
          {environment.STAGE === 'neutral' ? <></> : <LeakReporterCloudInfo />}
        </Grid>
      </div>
    </>
  )
}
