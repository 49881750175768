import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useMemo, useState } from 'react'
import { useActivateCloudTrialLicenseMutation } from '../../api/license.models'
import { useAddDemoDataMutation } from '../../api/demodata.models'
import { useGetUserProfileQuery } from '../../api/models'
import { environment } from '../helpers/environment'
import { useOrganizations } from './useOrganizations'
import { useActivateOrganizationMutation, useUpdateTrialLicenseMutation } from '../../api/organization.models'

interface TrialLicense {
  activateTrial: (displayName: string, userID: string) => Promise<boolean | undefined>
  activationProgress?: boolean
  trialLicenseError?: boolean
}

export const useActivateTrialLicense = (): TrialLicense => {
  const { keycloak } = useKeycloak()
  const [confirmActivate, trialLicenseResp] = useActivateCloudTrialLicenseMutation()
  const [activateOrganization, activateLiceResp] = useActivateOrganizationMutation()
  const [addDemoData] = useAddDemoDataMutation()

  const submitActivation = async (displayname: string, id: string): Promise<boolean | undefined> => {
    if (!displayname || !id) {
      return
    }
    //console.log(`implementation pending: ${displayname} user: ${id} ,`)
    try {
      const res = await confirmActivate()
      const key = res.data?.createTrialLicense
      if (!key) {
        return false
      }
      const response = await activateOrganization({
        variables: {
          displayName: displayname,
          userID: id,
          licenseString: key,
        },
      })
      if (response.data?.activateOrganization) {
        await keycloak.updateToken(1000)

        const respDemoDat = await addDemoData({
          variables: {
            realmName: response.data.activateOrganization,
          },
        })
        if (respDemoDat.data?.addDemoData && respDemoDat.data?.addDemoData) {
          keycloak.login(environment.redirect)
          window.open(environment.LEAKAGE, '_blank')
        } else {
          console.log('demo data initailization failed')
        }
      }
    } catch (error) {
      console.error('error activating trial license', error)
    }
  }
  return {
    activateTrial: submitActivation,
    activationProgress: activateLiceResp.loading || trialLicenseResp.loading,
  }
}
interface UpdateTrialLicense {
  updateTrialLicense: (licenseString: string) => Promise<boolean | void>
  updateLicenseProgress?: boolean
}

export const useConvertToRegularLicense = (): UpdateTrialLicense => {
  const [confirmUpdateTrial, updateStatus] = useUpdateTrialLicenseMutation()
  const { keycloak } = useKeycloak()
  const orgs = useOrganizations()
  const realmName = useMemo(() => {
    if (orgs?.length) {
      return orgs.find((o) => o.cloudLicenseType === 'trial')?.realmName
    }
  }, [orgs])

  const submitUpdateLicense = async (licenseString: string) => {
    if (!licenseString || !realmName) {
      return
    }
    try {
      const resp = await confirmUpdateTrial({
        variables: {
          realmName: realmName,
          licenseString: licenseString,
        },
      })
      resp.data?.updateOrganizationLicense && keycloak.login(environment.redirect)
      if (resp.errors) {
        return !!resp.errors
      }
    } catch (err) {
      console.error('error updating Trial to regular', err)
    }
  }
  return {
    updateTrialLicense: submitUpdateLicense,
    updateLicenseProgress: updateStatus.loading,
  }
}

export const useActivateRegularLicense = (
  displayName: string,
): {
  errorMessage?: string
  activate: (licKey: string) => Promise<void>
  activating?: boolean
  activated?: boolean
} => {
  const { keycloak } = useKeycloak()
  const userInfoResponse = useGetUserProfileQuery()
  const [username, setUsername] = useState<string>()
  const [activateLic, { data, error, loading }] = useActivateOrganizationMutation()
  useEffect(() => {
    if (keycloak.authenticated && userInfoResponse.data?.getUserProfile && !userInfoResponse.loading) {
      setUsername(userInfoResponse.data.getUserProfile.UserID)
    }
  }, [keycloak, userInfoResponse])

  const confirmLic = async (licenseKey: string) => {
    try {
      if (!displayName || !licenseKey || !username) {
        return
      }
      const result = await activateLic({
        variables: {
          userID: username ?? '',
          licenseString: licenseKey,
          displayName: displayName,
        },
      })
      if (result.data?.activateOrganization) {
        keycloak.login(environment.redirect)
      }
    } catch (er) {
      console.error('error activating Org', er)
    }
  }

  return {
    errorMessage: error?.message,
    activate: confirmLic,
    activating: loading,
    activated: !!data?.activateOrganization,
  }
}
