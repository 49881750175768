const constants = {
  DATE: {
    DEFAULT_FORMAT: 'YYYY-MM-DDTHH:mm:ss',
    ZONED_FORMAT: 'YYYY-MM-DDTHH:mm:ssZZ',
  },
  SENDTO: 'info@cs-instruments.com',
  LOGGING: {
    LOGGERS: {
      BASE: 'sighnhere-management.base',
    },
    LEVELS: {
      TRACE: { index: 0, key: 'trace' },
      DEBUG: { index: 1, key: 'DEBUG' },
      INFO: { index: 2, key: 'info' },
      WARN: { index: 3, key: 'warn' },
      ERROR: { index: 4, key: 'error' },
    },
  },
  NAVIGATION: {
    HOME: '',
    TEST: 'test',
    LIST: 'list-edit',
    LISTS: 'list',
    AUFGABENPLANUNG: 'tasks',

    SERVER_ERROR_PAGE: 'server-error',
    NO_ACCESS_PAGE: 'no-access',
  },
  ROUTES: {},
}

export const LRProductPage = 'https://www.cs-instruments.com/products/d/leak-detection/cs-leak-reporter-cloud-solution'
export const LREnquiryPage =
  'https://outlook.office365.com/owa/calendar/CSInstrumentsGmbHCoKG@cs-instruments.com/bookings/'

export default constants
