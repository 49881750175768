import { CsButtonPrimary } from '@csinstruments/cs-react-theme'
import { Grid, Typography } from '@material-ui/core'
import { useKeycloak } from '@react-keycloak/web'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalForm } from './ModalForm'

const DeleteAccountModal: React.FC = () => {
  const { t, i18n } = useTranslation('common')
  const [confirmDeleteAcnt, setConfirmDeleteAcnt] = useState<boolean>(false)

  const clickConfirmDelete = () => {
    keycloak?.login({ action: 'delete_account', locale: i18n.language })
  }

  const { keycloak } = useKeycloak()
  return (
    <>
      <ModalForm
        formOpen={confirmDeleteAcnt}
        formClose={() => setConfirmDeleteAcnt(!confirmDeleteAcnt)}
        headingText={t('modals.confirmationHeading')}
        submitForm={clickConfirmDelete}
      >
        <Grid item container spacing={2} alignContent="space-between">
          <Typography color="error">{t('modals.standardText')}</Typography>
          <Typography variant="subtitle1" gutterBottom>
            {t('descriptions.deleteAccount')}
          </Typography>
        </Grid>
      </ModalForm>

      <Grid item>
        <CsButtonPrimary
          fullwidth
          label={t('actions.deleteAc')}
          onClick={() => setConfirmDeleteAcnt(!confirmDeleteAcnt)}
        />
      </Grid>
    </>
  )
}

export default DeleteAccountModal
