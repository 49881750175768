export const NAVIGATION = {
  HOME: '',
  REGISTER_ORGANIZATION: 'register-organization',
  FORGOT_PASSWORD: 'reset-password',
  DASHBOARD: 'personalinfo',
  SINGLESIGNIN: 'singlesignin',
  LOGIN: 'login',
  SERVER_ERROR_PAGE: 'server-error',
  NO_ACCESS_PAGE: 'no-access',
  SESSION_EXPIRED: 'session-expired',
  ACTIVATE_ORGANIZATION: 'activatelicense',

  MANGAGEUSERS: 'manageusers',
  MANAGERESOURCES: 'manageresources',
  MANAGEAPPS: 'manageapps',
  INFO: 'appinfo',
  MANAGEORGANIZATION: 'manageorganization',
  MANAGEROLES: 'manageroles',
  RECYCLEBIN: 'bin',

  LISTUSERS: 'manageusers/userslist',
  ADDUSER: 'manageusers/adduser',
  CREATEROLE: 'manageroles/addrole',
  ABOUT: 'about',
}
export const ROUTES = {
  home: `/${NAVIGATION.HOME}`,
  dashboard: `/${NAVIGATION.DASHBOARD}`,
  registerOrganization: `/${NAVIGATION.REGISTER_ORGANIZATION}`,
  login: `/${NAVIGATION.LOGIN}`,
  forgotpassword: `/${NAVIGATION.FORGOT_PASSWORD}`,
  serverError: `/${NAVIGATION.SERVER_ERROR_PAGE}`,
  singleSignIn: `/${NAVIGATION.SINGLESIGNIN}`,
  sessionExpired: `/${NAVIGATION.SESSION_EXPIRED}`,
  accessDenied: `/${NAVIGATION.NO_ACCESS_PAGE}`,
  activate: `/${NAVIGATION.ACTIVATE_ORGANIZATION}`,
  about: `/${NAVIGATION.ABOUT}`,
  licenseOfferRequest: `${NAVIGATION.HOME}/offer-request`,
}
