import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
  };

export type Mutation = {
    __typename?: 'Mutation';
    addDemoData: Scalars['Boolean'];
  };


  export type AddDemoDataMutationVariables = Exact<{
    realmName: Scalars['String'];
  }>;


export type AddDemoDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addDemoData'>
);

/**
 * __useAddDemoDataMutation__
 *
 * To run a mutation, you first call `useAddDemoDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddDemoDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [AddDemoDataMutation, { data, loading, error }] = useAddDemoDataMutation({
 *   variables: {
 *   },
 * });
 */
export function useAddDemoDataMutation(baseOptions?: Apollo.MutationHookOptions<AddDemoDataMutation, AddDemoDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddDemoDataMutation, AddDemoDataMutationVariables>(AddDemoDataDocument, options);
      }
export type AddDemoDataMutationHookResult = ReturnType<typeof useAddDemoDataMutation>;
export type AddDemoDataMutationResult = Apollo.MutationResult<AddDemoDataMutation>;
export type AddDemoDataMutationOptions = Apollo.BaseMutationOptions<AddDemoDataMutation, AddDemoDataMutationVariables>;
export const AddDemoDataDocument = gql`
    mutation AddDemoData($realmName: String!) {
  addDemoData(realmName: $realmName)
}
    `;
export type AddDemoDataMutationFn = Apollo.MutationFunction<AddDemoDataMutation, AddDemoDataMutationVariables>;