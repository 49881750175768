import { ApolloProvider } from '@apollo/client'
import { FollowDrawer } from '@csinstruments/cs-react-theme'
import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { completeContainer } from '../styles/shared'
import About from './components/pages/userprofile/About'
import { UserProfile } from './components/pages/userprofile/userprofile'
import ActivateLicense from './components/partials/ActivateLicense'
import { ProfileAppTop } from './components/partials/Navigation/ProfileAppTop'
import { ProfileNavigationDrawer } from './components/partials/Navigation/ProfileNavigationDrawer'
import { PageContent } from './components/partials/PageContent'
import { ROUTES } from './helpers/routes'
import { useIamApolloClient } from './hooks/apolloClient'

const App: React.FC = () => {
  const [tokensAvailable, setTokensAvailable] = useState(false)
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false)
  const { keycloak } = useKeycloak()
  const client = useIamApolloClient()

  useEffect(() => {
    const [token] = [keycloak?.token ?? null]
    if (!token) {
      // wait for local storage
      setTimeout(() => {
        const [token] = [keycloak?.token ?? '']
        if (token) {
          setTokensAvailable(true)
        }
      }, 500)
    } else {
      setTokensAvailable(true)
    }
  }, [keycloak?.token])

  if (keycloak && !tokensAvailable) {
    return <></>
  }

  return (
    <>
      <ApolloProvider client={client}>
        <div style={completeContainer}>
          <ProfileNavigationDrawer onDrawerAction={(open) => setDrawerOpen(open)} />
          <ProfileAppTop />
          <FollowDrawer open={drawerOpen}>
            <PageContent>
              <Switch>
                <Route exact path={ROUTES.about} render={() => <About />} />
                <Route exact path={ROUTES.activate} render={() => <ActivateLicense />} />
                <Route render={() => <UserProfile />} />
              </Switch>
            </PageContent>
          </FollowDrawer>
        </div>
      </ApolloProvider>
    </>
  )
}

export default App
