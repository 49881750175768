import * as Sentry from '@sentry/browser'
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations'
import { environment } from './environment'

export const prepareSentry = (): void => {
  const dsn = environment.SENTRY_DSN.replace('%40', '@')

  if (dsn && dsn.length > 0) {
    try {
      Sentry.init({
        dsn,
        release: `${environment.APP_VERSION}-${environment.STAGE}`,
        integrations: [
          // --
          // TODO: If we set console: true, we'd get previous console logs in sentry in the case of errors,
          // but logging output in browser comes from instrument.ts instead of the actual source.
          // https://github.com/getsentry/sentry-javascript/issues/1577#issuecomment-424033663
          // Is there another way to get both?
          new Sentry.Integrations.Breadcrumbs({ console: false }),
          // --
          new CaptureConsoleIntegration({ levels: ['error'] }),
        ],
      })

      Sentry.configureScope((scope) => {
        scope.setTag('environment', environment.STAGE)
        scope.setTag('app.version', environment.APP_VERSION)
      })
    } catch (e) {
      console.error('sentry failed')
    }
  }
}
