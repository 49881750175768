export const getRemainingDays = (dateToConvert?: string): number => {
  if (dateToConvert) {
    const currentDate = new Date().getTime()
    const expiryDate = new Date(dateToConvert).getTime()
    const diffTime = expiryDate - currentDate
    //console.log('days', Math.ceil(diffTime / (1000 * 3600 * 24)))
    return Math.ceil(diffTime / (1000 * 3600 * 24))
  }
  return 0
}

export const isLicenseExpired = (date?: string): boolean => {
  if (date) {
    return getRemainingDays(date) <= 0
  }
  return true
}

export const convertToLocaleDate = (dateToConvert?: string): string => {
  return dateToConvert ? new Date(dateToConvert as string).toLocaleString(navigator.language) : ' '
}

export const getCurrentUnixTime = (): number => {
  const unixTimeStamp = Math.round(new Date().getTime() / 1000)
  return unixTimeStamp
}

export const enableMaintenanceAlert = (): void => {
  localStorage.setItem('_showAlert', 'true')
}

export const disableMaintenanceAlert = (): void => {
  localStorage.setItem('_showAlert', 'false')
}

export const isAlertNotSet = (): boolean => {
  return localStorage.getItem('_showAlert') === null
}

export const getMaintenanceAlert = (): boolean => {
  return localStorage.getItem('_showAlert') === 'true'
}

export const clearLocalStorage = (): void => {
  localStorage.clear()
}
