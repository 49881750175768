import { CsButtonPrimary } from '@csinstruments/cs-react-theme'
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ListSubheader,
  ListItemIcon,
  ListItemAvatar,
  Divider,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LREnquiryPage, LRProductPage } from '../../../constants/constants'
import { environment } from '../../helpers/environment'
import { isLicenseExpired } from '../../helpers/utils'
import { RootPermissions, useIsAdmin } from '../../hooks/useOrganizations'
import BusinessCenterRoundedIcon from '@material-ui/icons/BusinessCenterRounded'
import PlaceRoundedIcon from '@material-ui/icons/PlaceRounded'
import EditIcon from '@material-ui/icons/Edit'
import UpdateOrganization from './ChangeOranizationName'

import { PacketNameInput } from '../../Interfaces/KeycloakTypes'
import { useKeycloak } from '@react-keycloak/web'
import RenamePacket from './RenamePacket'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../helpers/routes'
import { useModifyPacketNameMutation } from '../../../api/organization.models'

const LeakReporterCloudInfo: React.FC = () => {
  const { t } = useTranslation('common')
  const history = useHistory()
  const { keycloak } = useKeycloak()
  const [orgRealmName, setOrgRealmName] = useState('')
  const [orgDisplayName, setOrgDisplayName] = useState('')
  const [changeNameTab, setChangeNameTab] = useState(false)
  const [modifyPacketTab, setModifyPacketTab] = useState(false)
  const [accesibleOrgs, setAccessibleOrgs] = useState<RootPermissions[]>([])

  const [packetNameInput, setPacketNameInput] = useState<PacketNameInput>({
    realmName: '',
    packetId: '',
    packetName: '',
  })

  const { fetching, permissions } = useIsAdmin()
  const [updatePacket] = useModifyPacketNameMutation()

  const changePacketVar = (orgName: string, packetId: string): void => {
    //console.log({ orgName }, { packetId })
    const orgPacketInfo = permissions
      ?.find((p) => p.realmName === orgName)
      ?.packetInfo?.find((pack) => pack.packetId === packetId)

    setPacketNameInput({
      realmName: orgName,
      packetId: packetId,
      packetName: orgPacketInfo?.packetName ?? '',
    })
    orgPacketInfo?.packetName && setModifyPacketTab(true)
  }
  const updatePacketName = (packetName: string) => {
    setPacketNameInput((prv) => ({
      ...prv,
      packetName: packetName,
    }))
  }
  const confirmChangePacket = async (): Promise<void> => {
    try {
      if (packetNameInput.packetId && packetNameInput.packetName && packetNameInput.realmName) {
        console.log({ packetNameInput })
        const packt = await updatePacket({
          variables: packetNameInput,
        })
        if (packt.data?.changePacketName) {
          keycloak.login()
        }
      }
    } catch (er) {
      console.error({ er })
    }
  }

  useEffect(() => {
    if (!fetching && permissions?.length) {
      setAccessibleOrgs(permissions)
    }
  }, [permissions, fetching])

  const changeOrgName = (orgName: string): void => {
    setOrgRealmName(orgName)
    setOrgDisplayName(permissions?.find((p) => p.realmName === orgName)?.displayName ?? '')
    setChangeNameTab(true)
  }

  return (
    <>
      <UpdateOrganization
        active={changeNameTab}
        realmName={orgRealmName}
        orgDisplayName={orgDisplayName}
        closeChangeNameTab={setChangeNameTab}
      />
      <RenamePacket
        openPopUp={modifyPacketTab}
        submit={confirmChangePacket}
        packetName={packetNameInput.packetName}
        updateName={updatePacketName}
        closePopUp={setModifyPacketTab}
      />
      <Grid item lg={4} container justifyContent="flex-start" alignItems="stretch" spacing={1} direction="column">
        <Grid item container alignItems="center">
          <Grid item>
            <Typography variant="h4" color="secondary" gutterBottom align="center">
              {t('labels.lrSolution')}
            </Typography>
          </Grid>
          <Grid item container alignContent="space-around" spacing={2} direction="column">
            <Grid item>
              <CsButtonPrimary
                fullwidth
                label={t('actions.activateLicense')}
                onClick={() => history.push(ROUTES.activate)}
              />
            </Grid>

            <Grid item>
              <CsButtonPrimary
                fullwidth
                label={t('actions.requestEnquiry')}
                onClick={() => window.open(LREnquiryPage, '_open')}
              />
            </Grid>
            <Grid item>
              <CsButtonPrimary
                fullwidth
                label={t('actions.lrCloudApp')}
                onClick={() => window.open(environment.LEAKAGE, '_open')}
              />
            </Grid>
            <Grid item>
              <CsButtonPrimary
                fullwidth
                label={t('actions.productPage')}
                onClick={() => window.open(LRProductPage, '_open')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item container alignContent="space-around" spacing={2} direction="column">
          <Grid item>
            <Typography variant="h6" color="secondary" gutterBottom>
              {accesibleOrgs.length ? t('labels.accessibleOrganizations') : null}
            </Typography>
          </Grid>
          <Grid item>
            {accesibleOrgs?.map((org) => (
              <List key={org.realmName}>
                <ListItem>
                  <ListItemAvatar>
                    <BusinessCenterRoundedIcon />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <>
                        <Typography
                          variant="subtitle2"
                          color={isLicenseExpired(org.expirationDate) ? 'error' : 'inherit'}
                          gutterBottom
                        >
                          {org.displayName}
                          {isLicenseExpired(org.expirationDate) && ` (${t('licenses.licenseExpired')})`}
                        </Typography>
                      </>
                    }
                    secondary={org.cloudLicenseType === 'trial' && <>({t('licenses.trialLicense')})</>}
                  />

                  <ListItemSecondaryAction style={{ paddingLeft: 2 }}>
                    <IconButton
                      aria-label="edit"
                      onClick={() => changeOrgName(org.realmName)}
                      disabled={!org.rootAdmin || isLicenseExpired(org.expirationDate)}
                      title={t('actions.editOrgName')}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>

                {org.packetInfo?.length ? (
                  <>
                    <ListSubheader style={{ paddingLeft: '4rem' }}>{t('labels.accessiblePackets')}</ListSubheader>
                    {org.packetInfo.map((packet) => (
                      <ListItem key={packet.packetId} style={{ paddingLeft: '2rem' }}>
                        <ListItemIcon>
                          <PlaceRoundedIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography
                              variant="caption"
                              color={isLicenseExpired(org.expirationDate) ? 'error' : 'inherit'}
                              gutterBottom
                            >
                              {packet.packetName}
                            </Typography>
                          }
                        />

                        <ListItemSecondaryAction>
                          <IconButton
                            aria-label="edit"
                            edge="end"
                            onClick={() => changePacketVar(org.realmName, packet.packetId)}
                            disabled={!org.rootAdmin || isLicenseExpired(org.expirationDate)}
                            title={t('actions.editPacketName')}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </>
                ) : (
                  <></>
                )}
                <Divider />
              </List>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default LeakReporterCloudInfo
