import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { environment } from '../../helpers/environment'
import { ThemeProvider as CSThemeProvider } from '@csinstruments/cs-react-theme'

type Props = {
  children: ReactNode
}

const ThemeProvider: React.FC<Props> = ({ children }: Props) => {
  const { i18n } = useTranslation('common')
  const selectedTheme = environment.STAGE === 'neutral' ? 'neutralLight' : 'csLight'
  return (
    <CSThemeProvider theme={selectedTheme} language={i18n.language.toLowerCase()}>
      {children}
    </CSThemeProvider>
  )
}

export default ThemeProvider
