import { paletteColors } from '@csinstruments/cs-react-theme'
import React from 'react'

export const completeContainer = {
  overflowX: 'hidden' as const,
  backgroundColor: paletteColors().primary,
}

export const fullHeight = {
  height: '100%',
  lineHeight: '100%',
  verticalAlign: 'center',
  display: 'flex',
  alignItems: 'center',
}

export const outerPageContainer = {
  backgroundColor: paletteColors().primary,
}

export const innerPageContainer = {
  backgroundColor: '#fff',
  borderTopLeftRadius: 50,
}

export const userForm = {
  border: '1px #008E77 solid',
  borderRadius: 5,
  padding: '2em',
} as React.CSSProperties

export const mainPageContainer = {
  width: '90%',
  margin: 'auto',
  maxHeight: '100%',
  paddingBottom: '20px',
  paddingTop: '30px',
  flexGrow: 1,
} as React.CSSProperties

export const cardDefault = {
  display: 'flex',
  flexDirection: 'column' as const,
  height: '175px',
  justifyContent: 'space-between',
  padding: 15,
  cursor: 'pointer',
}

export const paddingTopBottom = {
  paddingTop: '20px',
  paddingBottom: '30px',
}

export const standardContentPadding = {
  padding: '30px',
}
