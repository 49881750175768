import { i18n, TFunction } from 'i18next'
import { useTranslation as useReactTranslation } from 'react-i18next'

export const useTranslation = (): [TFunction, i18n, boolean] & {
  t: TFunction
  i18n: i18n
  ready: boolean
} => {
  return useReactTranslation('common')
}
