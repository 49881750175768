import { CsButton } from '@csinstruments/cs-react-theme'
import { Button, Checkbox, FormControlLabel, FormHelperText, Grid, TextField, Typography } from '@material-ui/core'

import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LicensePacketInfo, useGetLicensePacketInformationQuery } from '../../../api/iam.models'

import { useGetUserProfileQuery, UserProfile } from '../../../api/models'
import { useActivateLicensePacketMutation } from '../../../api/organization.models'

import { environment } from '../../helpers/environment'
import {
  useActivateRegularLicense,
  useActivateTrialLicense,
  useConvertToRegularLicense,
} from '../../hooks/licenseHooks'
import { RootPermissions, useIsAdmin } from '../../hooks/useOrganizations'
import { ActivationVariable, initActivationParams } from '../../Interfaces/KeycloakTypes'
import ProgressBar from './ProgressBar'
import { ResultAlert } from './ResultAlert'
import { ModalForm } from './ModalForm'

const ActivateLicense: React.FC = () => {
  const { t } = useTranslation('common')
  const { keycloak } = useKeycloak()

  const [userInfo, setUserInfo] = useState<UserProfile>()

  const [activationVariables, setActivationVariables] = useState<ActivationVariable>(initActivationParams)
  const [trialLicense, setTrialLicense] = useState(false)
  const [displayWarning, setDisplayWarning] = useState(false)
  const [trialLicErr, setTrialLicErr] = useState<boolean>(false)
  const [keyError, setKeyError] = useState<boolean>(false)
  const [hasTrialLicense, setHasTrialLicense] = useState(false)
  const [hasPacketKey, setHasPacketKey] = useState(false)
  const [licPacketInfo, setLicPacketInfo] = useState<LicensePacketInfo>()
  const [accesibleOrgs, setAccessibleOrgs] = useState<RootPermissions[]>([])
  const [fetchNewData, setFetchNewData] = useState<boolean>(false)
  const { activate, activating, activated } = useActivateRegularLicense(activationVariables.displayName)
  const { data: userProfileData } = useGetUserProfileQuery()
  const { data: licensePacketInfo, error: packetErr } = useGetLicensePacketInformationQuery({
    variables: {
      licenseString: activationVariables.licenseString,
    },
    skip: !activationVariables.licenseString || !hasPacketKey || !fetchNewData,
  })
  const [activateLicensePacket] = useActivateLicensePacketMutation()
  //const [assignedLicenses, setAssignedLicenes] = useState<CloudLicensePacketGroup[]>([])

  const { permissions } = useIsAdmin()
  const { updateTrialLicense, updateLicenseProgress } = useConvertToRegularLicense()
  const { activateTrial, activationProgress } = useActivateTrialLicense()

  const changeActivationVar = (name: string, value: string) => {
    //console.log({ name, value })
    setActivationVariables((prev) => ({
      ...prev,
      [name]: value.trim(),
      displayName: '',
    }))
    setFetchNewData(false)
    setLicPacketInfo(undefined)
    //console.log({ value })
  }

  const realmID = useMemo(() => {
    return licPacketInfo?.activatedOrganizationInfo?.organizationName || ''
  }, [licPacketInfo?.activatedOrganizationInfo])

  const confirmActivate = async () => {
    try {
      if (hasPacketKey && !hasTrialLicense && !realmID && !packetErr) {
        await activate(activationVariables.licenseString)
      }
      if (trialLicense) {
        const trialActivated = await activateTrial(activationVariables.displayName, activationVariables.userID)
        if (!trialActivated) {
          setTrialLicErr(true)
        }
      }
      if (hasTrialLicense && !realmID && !packetErr) {
        await updateTrialLicense(activationVariables.licenseString)
      }
      if (hasPacketKey && realmID && userInfo?.Email && !packetErr) {
        //const realmID = licPacketInfo?.activatedOrganizationInfo?.organizationName
        const packetInstanceRes = await activateLicensePacket({
          variables: {
            licenseString: activationVariables.licenseString,
            userID: userInfo?.UserID,
            packetName: `${activationVariables.displayName}`,
            reserved: false,
            realmId: realmID,
          },
        })
        if (packetInstanceRes.data?.activateLicensePacket) {
          keycloak?.login(environment.redirect)
        }
      }
    } catch (er) {
      console.error('error submitting', er)
    }
  }
  useEffect(() => {
    if (permissions?.length) {
      setAccessibleOrgs(permissions)

      if (permissions.some((o) => o.cloudLicenseType === 'trial' && o.rootAdmin)) {
        setHasTrialLicense(true)
        //const trialRealmPerms = permissions.find((o) => o.cloudLicenseType === 'trial' && o.rootAdmin)
        // if (trialRealmPerms && trialRealmPerms) {
        //   setActivationVariables((prv) => ({
        //     ...prv,
        //     displayName: trialRealmPerms.displayName,
        //   }))
        // }
      }
    }
  }, [permissions])

  useEffect(() => {
    if (userProfileData?.getUserProfile) {
      setUserInfo(userProfileData.getUserProfile)
    }
  }, [userProfileData?.getUserProfile])

  useEffect(() => {
    if (licensePacketInfo?.getLicensePacketInformation) {
      //const defaultPacketName = licensePacketInfo.getLicensePacketInformation?.licensePacket.packetName ?? ''
      setLicPacketInfo(licensePacketInfo.getLicensePacketInformation as LicensePacketInfo)
      setDisplayWarning(true)

      //const { activatedOrganizationInfo, activatedLicensePacketInfo } = licensePacketInfo.getLicensePacketInformation

      // setActivationVariables((prv) => ({
      //   ...prv,
      //   displayName:
      //     hasTrialLicense && !activatedOrganizationInfo?.displayName
      //       ? prv.displayName
      //       : !hasTrialLicense && !activatedOrganizationInfo?.displayName
      //       ? userInfo?.CompanyName ?? ''
      //       : !activatedLicensePacketInfo
      //       ? userInfo?.City
      //         ? userInfo.City
      //         : defaultPacketName
      //       : '',
      // }))
    }
  }, [
    hasPacketKey,
    userInfo?.City,
    licensePacketInfo?.getLicensePacketInformation,
    activationVariables.licenseString,
    hasTrialLicense,
    userInfo?.CompanyName,
  ])

  useEffect(() => {
    if (packetErr && packetErr) {
      setKeyError(true)
      //setHasPacketKey(false)
      setLicPacketInfo(undefined)
    }
  }, [packetErr])

  const changeTrialLicenseHandle = (checked: boolean) => {
    setTrialLicense(checked)
    setHasPacketKey(false)
    const trialRealmPerms = accesibleOrgs.find((o) => o.cloudLicenseType === 'trial' && o.rootAdmin)
    if (trialRealmPerms && checked) {
      setActivationVariables((prv) => ({
        ...prv,
        licenseString: '',
        displayName: trialRealmPerms.displayName,
      }))
    }
    if (!trialRealmPerms) {
      {
        setActivationVariables({
          licenseString: '',
          displayName: userInfo?.CompanyName ?? '',
          userID: userInfo?.UserID ?? '',
        })
      }
    }
  }

  const changeLicensePacket = (checked: boolean) => {
    setHasPacketKey(checked)
    setLicPacketInfo(undefined)
    setKeyError(false)
    if (checked) {
      setActivationVariables((prv) => ({
        ...prv,
        displayName: hasTrialLicense ? prv.displayName : '',
      }))
    }
  }

  const disableActivate = useMemo(() => {
    return (
      (trialLicense && !activationVariables.displayName) ||
      (hasPacketKey && (!activationVariables.displayName || !activationVariables.licenseString)) ||
      updateLicenseProgress ||
      activating ||
      activationProgress ||
      !activationVariables.displayName ||
      (!!activationVariables.displayName && !(trialLicense || hasPacketKey)) ||
      !!licPacketInfo?.activatedLicensePacketInfo
    )
  }, [
    activationVariables,
    hasPacketKey,
    trialLicense,
    activating,
    updateLicenseProgress,
    activationProgress,
    licPacketInfo,
  ])

  const enableWarning = () => {
    setFetchNewData(true)
    if (trialLicense) {
      setDisplayWarning(true)
    }
  }

  const closeForm = () => {
    setFetchNewData(false)
    setDisplayWarning(false)
  }
  return (
    <>
      <ModalForm
        headingText={!trialLicense ? t('modals.headingText') : t('modals.confirmationHeading')}
        formOpen={displayWarning}
        formClose={closeForm}
        disableSubmit={disableActivate || keyError}
        submitForm={confirmActivate}
      >
        {hasPacketKey && licPacketInfo && (
          <Grid item container alignContent="center">
            <Grid item xs={2} />
            <Grid item xs={10} container>
              <Grid item xs={5}>
                <Typography variant="h6">{`${t('licenses.displayName')} : ${
                  licPacketInfo.activatedOrganizationInfo?.displayName ?? ''
                } `}</Typography>
              </Grid>
              <Grid item xs={5}>
                <Typography
                  variant="subtitle1"
                  color={!licPacketInfo?.activatedOrganizationInfo?.displayName ? 'error' : 'inherit'}
                >
                  {!licPacketInfo?.activatedOrganizationInfo?.displayName ? t('licenses.notActivated') : ' '}
                </Typography>
              </Grid>
            </Grid>

            <Grid item container spacing={2}>
              <Grid item xs={2} />
              <Grid item xs={10} container alignContent="center">
                <Grid item container>
                  {licPacketInfo?.activatedOrganizationInfo?.displayName && (
                    <Typography
                      variant="subtitle1"
                      color={!!licPacketInfo.activatedLicensePacketInfo ? 'secondary' : 'error'}
                    >
                      {`${t('licenses.packet')} : `}
                      {!!licPacketInfo.activatedLicensePacketInfo
                        ? licPacketInfo.licensePacket.packetName + ' ' + t('licenses.activated')
                        : t('licenses.notActivated')}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={6} container alignContent="center">
                  {licPacketInfo?.assignedGroupLicences && (
                    <>
                      <Grid item>
                        <Typography variant="subtitle2">{t('licenses.overview')}</Typography>
                      </Grid>
                      {licPacketInfo.assignedGroupLicences.map((grp) => {
                        return (
                          <Grid item key={grp?.groupname}>
                            <Typography key={grp?.groupname}>
                              {grp?.groupname} {'// '}
                              {grp?.licenseGroupTypeDetails.map((typ) => {
                                return `${typ?.licenseType ?? ''}: ${typ?.numberOfLicenses} `
                              })}
                            </Typography>
                          </Grid>
                        )
                      })}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </ModalForm>
      {!activated && (
        <Grid item container xs={12} md={8} lg={12} justifyContent="center" alignItems="center" alignContent="center">
          <Grid item lg={4} />
          <Grid item container xs md={8} lg={8} spacing={2}>
            {(activating || updateLicenseProgress || activationProgress) && <ProgressBar />}
            {/* {errorMessage && <ResultAlert severity="error" alertText={t('licenses.keyInvalid')} />} */}
            {keyError && (
              <ResultAlert
                severity="error"
                alertText={`${t('licenses.keyInvalid')} / ${t('licenses.inputIncomplete')}`}
                closeAlert={setKeyError}
              />
            )}
            {trialLicErr && <ResultAlert severity="error" alertText={'licenses.trialLicenseAlreadyUsed'} />}
            <Grid item xs={12} lg={7} md={8} />
            <Grid item xs={12} lg={7} md={8}>
              <FormControlLabel
                label={
                  <>
                    <Typography variant="body1">{t('labels.activateTrialLicense')}</Typography>
                    {hasTrialLicense && (
                      <FormHelperText error={hasTrialLicense}>({t('labels.trialLiceneseWarning')})</FormHelperText>
                    )}
                  </>
                }
                control={
                  <Checkbox
                    checked={trialLicense}
                    onChange={({ target }) => changeTrialLicenseHandle(target.checked)}
                    disabled={
                      hasTrialLicense ||
                      hasPacketKey ||
                      permissions?.some((porg) => porg.rootAdmin && porg.cloudLicenseType !== 'trial')
                    }
                  />
                }
              />
            </Grid>

            <Grid item xs={12} lg={7} md={8}>
              <FormControlLabel
                label={<Typography variant="body1">{t('labels.purchasedKey')}</Typography>}
                control={
                  <Checkbox
                    checked={hasPacketKey}
                    onChange={({ target }) => changeLicensePacket(target.checked)}
                    disabled={trialLicense}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} lg={7} md={8}>
              <TextField
                label={t('licenses.licenseKey')}
                variant="standard"
                fullWidth
                required
                disabled={trialLicense || !hasPacketKey}
                placeholder="xxxx-xxxx-xxxx-xxxx"
                name="licenseString"
                onChange={({ target }) => changeActivationVar(target.name, target.value)}
                value={activationVariables.licenseString}
              />
            </Grid>
            <Grid item xs={12} lg={7} md={8}>
              <TextField
                label={`${t('licenses.displayName') + '/' + t('labels.packetName')}`}
                required
                fullWidth
                variant="standard"
                name="displayName"
                placeholder={!licPacketInfo?.activatedOrganizationInfo?.displayName ? 'MagnaGmbh' : 'Magna-stuttgart'}
                onChange={({ target }) => setActivationVariables((prv) => ({ ...prv, displayName: target.value }))}
                value={activationVariables?.displayName ?? ''}
              />
            </Grid>

            <Grid item xs={6} lg={7} md={8}>
              <CsButton
                solid
                fullwidth
                disabled={activating || updateLicenseProgress || activationProgress}
                onClick={() => keycloak.logout(environment.redirect)}
              >
                {t('modals.cancel')}
              </CsButton>
            </Grid>
            <Grid item xs={6} lg={7} md={8}>
              <Button
                //startIcon={<SearchOutlinedIcon />}
                fullWidth
                variant="contained"
                color="primary"
                title={t('actions.activateLicense')}
                disabled={disableActivate}
                onClick={enableWarning}
              >
                {t('actions.activateLicense')}
              </Button>
            </Grid>

            {/* <CsButton solid fullwidth disabled={disableActivate} onClick={confirmActivate}>
                  {t('actions.activateLicense')}
                </CsButton> */}

            <Grid item xs={12} md={8} lg={7} />
          </Grid>
          <Grid item lg={4} />
        </Grid>
      )}
    </>
  )
}

export default ActivateLicense
