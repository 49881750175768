import { useKeycloak } from '@react-keycloak/web'
import { useEffect, useState } from 'react'

import { isLicenseExpired } from '../helpers/utils'
import { TokenOrganizationAccess } from '../Interfaces/TokenParams'
import { Base64 } from 'js-base64'
import { UserProfileType } from '../Interfaces/KeycloakTypes'
import { useHasAllRootPermissionsQuery } from '../../api/organization.models'
import { useGetAccessibleOrgLicensePacketsLazyQuery } from '../../api/iam.models'

export const useOrganizations = (): TokenOrganizationAccess[] | undefined => {
  const { keycloak } = useKeycloak()
  const t = keycloak.authenticated ? keycloak?.token : ''
  //console.log({ keycloak })
  if (!t) {
    return []
  }

  try {
    const parsedToken = JSON.parse(Base64.decode((t ?? '').split('.')[1]))
    //console.log('orgs', keycloak)
    const orgs = parsedToken.organizations as TokenOrganizationAccess[]
    return orgs
  } catch (e) {
    console.error(e)
  }
}

export type PacketInfo = {
  packetId: string
  packetName: string
}
export interface RootPermissions extends TokenOrganizationAccess {
  rootAdmin?: boolean
  licenseExpired?: boolean
  packetInfo?: PacketInfo[]
}

export type IsAdminResponse = {
  fetching?: boolean
  errorFetching?: boolean
  permissions?: RootPermissions[]
}

export const useIsAdmin = (): IsAdminResponse => {
  const [permOrgs, setPermOrgs] = useState<TokenOrganizationAccess[]>([])
  //const [realmsList, setRealmsList] = useState<string[]>([])
  //const [licensePackets, setLicensePackets] = useState<ActivatedCloudLicense[]>([])
  const [rootPerms, setRootPerms] = useState<RootPermissions[]>([])
  const orgs = useOrganizations()
  const { keycloak } = useKeycloak()
  const user_Info: UserProfileType | undefined = keycloak.userInfo
  const { data, error, loading } = useHasAllRootPermissionsQuery({
    variables: {
      realmName: permOrgs.map((o) => o.realmName),
    },
    skip: !permOrgs.length || permOrgs.some((p) => !p.realmName),
  })

  const [getPacketPermissions, packetPermissions] = useGetAccessibleOrgLicensePacketsLazyQuery()

  useEffect(() => {
    if (orgs && orgs.length) {
      //console.log({ orgs })
      setPermOrgs(orgs)
      //setRealmsList(orgs.map((o) => o.realmName))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    if (permOrgs.length && user_Info?.email) {
      getPacketPermissions({
        variables: {
          realmName: permOrgs.map((p) => p.realmName),
          superior: user_Info.email,
        },
      })
    }
  }, [getPacketPermissions, permOrgs.length, user_Info?.email])

  useEffect(() => {
    if (data?.isRootAdminEx && packetPermissions.data?.getAllAssignablePacketLicensesEx) {
      const licensePackets = packetPermissions.data?.getAllAssignablePacketLicensesEx
      const rPerms: RootPermissions[] = permOrgs.map((pOrg) => {
        const packetInfo = licensePackets.find((p) => p?.organizationName === pOrg.realmName)
        return {
          ...pOrg,
          rootAdmin: !!data.isRootAdminEx[permOrgs.findIndex((p) => p.realmName === pOrg.realmName)],
          licenseExpired: isLicenseExpired(pOrg.expirationDate),
          packetInfo: packetInfo?.licensePackets
            ? packetInfo.licensePackets.map((packet) => ({
                packetId: packet?.id ?? '',
                packetName: packet?.packetName ?? '',
              })) ?? undefined
            : undefined,
        }
      })
      setRootPerms(rPerms)
    }
  }, [data, packetPermissions.data?.getAllAssignablePacketLicensesEx, permOrgs])

  /* useEffect(() => {
    if (data?.isRootAdminEx) {
      const rPerms: RootPermissions[] = permOrgs.map((pOrg) => {
        return {
          ...pOrg,
          rootAdmin: !!data.isRootAdminEx[permOrgs.findIndex((p) => p.realmName === pOrg.realmName)],
          licenseExpired: isLicenseExpired(pOrg.expirationDate),
        }
      })
      setRootPerms(rPerms)
    }
  }, [data]) */

  return {
    fetching: loading,
    errorFetching: !!error?.message,
    permissions: rootPerms,
  }
}
