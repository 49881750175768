import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AmVersionInformation = {
  __typename?: 'AMVersionInformation';
  /** version of am */
  Version: Scalars['String'];
  /** timestamp of the am build */
  BuildTimestamp: Scalars['String'];
};

export type AsAppRole = {
  __typename?: 'ASAppRole';
  /** id of the role */
  roleID: Scalars['String'];
  /** name of the role */
  roleName: Scalars['String'];
  /** translation key of the role - null for individual roles */
  displayNameKey?: Maybe<Scalars['String']>;
};

export type AsAppRoleInput = {
  /** name of the role */
  roleName: Scalars['String'];
  /** translation key of the role - null for individual roles */
  displayNameKey?: Maybe<Scalars['String']>;
};

export type AccessPermission = {
  __typename?: 'AccessPermission';
  roleID: Scalars['String'];
  roleDisplayKey: Scalars['String'];
  roleDescriptionKey: Scalars['String'];
};

export type ActivatedCloudLicense = {
  __typename?: 'ActivatedCloudLicense';
  /** name of the organization */
  organizationName: Scalars['String'];
  /** licenses of a cloud are grouped in license packets */
  licensePackets?: Maybe<Array<Maybe<ActivatedLicensePacket>>>;
  /** whether the whole cloud license can be assigned */
  forbidden: Scalars['Boolean'];
  /** whether the whole cloud license needs to be assigned */
  required: Scalars['Boolean'];
};

export type ActivatedCloudLicenseInput = {
  /** name of the organization */
  organizationName: Scalars['String'];
  /** licenses of a cloud are grouped in license packets */
  licensePackets?: Maybe<Array<Maybe<ActivatedLicensePacketInput>>>;
};

export type ActivatedLicense = {
  __typename?: 'ActivatedLicense';
  /** unique id of the license */
  id: Scalars['String'];
  /** if not null, name of the user for which the license is reserved */
  reserved?: Maybe<Scalars['String']>;
  /** id of the user to which this license is assigned, null if not assigned */
  userId?: Maybe<Scalars['String']>;
  /** whether the license needs to be assigned */
  required: Scalars['Boolean'];
};

export type ActivatedLicenseGroup = {
  __typename?: 'ActivatedLicenseGroup';
  /** unique of the license group */
  id: Scalars['String'];
  /** name of the group e.g. cs-leak-reporter */
  groupname: Scalars['String'];
  /** translation key of the license group */
  displayNameKey?: Maybe<Scalars['String']>;
  /** description key of the license group */
  descriptionKey?: Maybe<Scalars['String']>;
  /** list of license types of the group e.g. user and guest */
  licenseTypes?: Maybe<Array<Maybe<ActivatedLicenseType>>>;
  /** whether the whole license group can be assigned */
  forbidden: Scalars['Boolean'];
  /** whether the whole license group needs to be assigned */
  required: Scalars['Boolean'];
};

export type ActivatedLicenseGroupInput = {
  /** unique of the license group */
  id: Scalars['String'];
  /** name of the group e.g. cs-leak-reporter */
  groupname: Scalars['String'];
  /** translation key of the license group */
  displayNameKey?: Maybe<Scalars['String']>;
  /** description key of the license group */
  descriptionKey?: Maybe<Scalars['String']>;
  /** list of license types of the group e.g. user and guest */
  licenseTypes?: Maybe<Array<Maybe<ActivatedLicenseTypeInput>>>;
};

export type ActivatedLicenseInput = {
  /** unique id of the license */
  id: Scalars['String'];
  /** if not null, name of the user for which the license is reserved */
  reserved?: Maybe<Scalars['String']>;
  /** id of the user to which this license is assigned, null if not assigned */
  userId?: Maybe<Scalars['String']>;
};

export type ActivatedLicensePacket = {
  __typename?: 'ActivatedLicensePacket';
  /** license packet id */
  id: Scalars['String'];
  /** packet name */
  packetName: Scalars['String'];
  /** apps / licensegroup e.g. cs-leak-reporter */
  licenseGroups?: Maybe<Array<Maybe<ActivatedLicenseGroup>>>;
  /** whether the whole license packet can be assigned */
  forbidden: Scalars['Boolean'];
  /** whether the whole license license needs to be assigned */
  required: Scalars['Boolean'];
};

export type ActivatedLicensePacketInfo = {
  __typename?: 'ActivatedLicensePacketInfo';
  /** date when the license packet was activated */
  licensePacketActivatedAt: Scalars['String'];
  /** id of the user who activated the license packet */
  licensePacketActivator: Scalars['String'];
  /** expiration date of the license packet */
  expirationDate: Scalars['String'];
};

export type ActivatedLicensePacketInput = {
  /** license packet id */
  id: Scalars['String'];
  /** packet name */
  packetName: Scalars['String'];
  /** apps / licensegroup e.g. cs-leak-reporter */
  licenseGroups?: Maybe<Array<Maybe<ActivatedLicenseGroupInput>>>;
};

export type ActivatedLicenseType = {
  __typename?: 'ActivatedLicenseType';
  /** unique id of the license type */
  id: Scalars['String'];
  /** type of the license e.g. user */
  licenseType: Scalars['String'];
  /** translation key of the license type */
  displayNameKey?: Maybe<Scalars['String']>;
  /** description key of the license type */
  descriptionKey?: Maybe<Scalars['String']>;
  /** list of single licenses of this type in the group in the packet */
  licenses?: Maybe<Array<Maybe<ActivatedLicense>>>;
  /** whether the whole license type can be assigned */
  forbidden: Scalars['Boolean'];
  /** whether the whole license type needs to be assigned */
  required: Scalars['Boolean'];
};

export type ActivatedLicenseTypeInput = {
  /** unique id of the license type */
  id: Scalars['String'];
  /** type of the license e.g. user */
  licenseType: Scalars['String'];
  /** translation key of the license type */
  displayNameKey?: Maybe<Scalars['String']>;
  /** description key of the license type */
  descriptionKey?: Maybe<Scalars['String']>;
  /** list of single licenses of this type in the group in the packet */
  licenses?: Maybe<Array<Maybe<ActivatedLicenseInput>>>;
};

export type ActivatedOrganizationLicenseInfo = {
  __typename?: 'ActivatedOrganizationLicenseInfo';
  /** name of the organization */
  organizationName: Scalars['String'];
  /** given name of the organization */
  displayName: Scalars['String'];
  /** date when the organization was activated */
  organizationActivatedAt: Scalars['String'];
  /** id of the user who activated the cloud license */
  organizationActivator: Scalars['String'];
  /** expiration date of the license packet */
  expirationDate: Scalars['String'];
};

export type AppRole = {
  __typename?: 'AppRole';
  role: Role;
  isCustomRole: Scalars['Boolean'];
  appRoleInfo?: Maybe<Array<RoleDisplayInformation>>;
  editableBy: Array<Scalars['String']>;
  assignableBy: Array<Maybe<Scalars['String']>>;
  /** permissible action scopes: e.g view, edit, delete  */
  userAccessScopes: Array<Maybe<Scalars['String']>>;
  userPermissions: RoleUserPermission;
};

/** resources assigned to the user in a particular app */
export type AppState = {
  __typename?: 'AppState';
  /** name of the app */
  appID: Scalars['String'];
  /** roles granted to the user in the given app */
  roles: Array<AsAppRole>;
  /** roles which the user is allowed to assign to other users */
  assignableRoles: Array<AsAppRole>;
  /** roles which the user is allowed to edit */
  editableRoles: Array<AsAppRole>;
  /** explicit permissions on resource of different clients */
  filePermission: Array<FilePermission>;
};

/** resources in a particular app which will be assigned to the user */
export type AppStateInput = {
  /** name of the app */
  appID: Scalars['String'];
  /** roles granted to the user in the given app */
  roles: Array<Scalars['String']>;
  /** roles which the user is allowed to assign to other users */
  assignableRoles: Array<Scalars['String']>;
  /** roles which the user is allowed to edit */
  editableRoles: Array<Scalars['String']>;
  /** explicit permissions on resource of different clients */
  filePermission: Array<Scalars['String']>;
};

/** resources assigned to the user in a particular app */
export type AppStateSimple = {
  __typename?: 'AppStateSimple';
  /** name of the app */
  appID: Scalars['String'];
  /** roles granted to the user in the given app */
  roles: Array<AsAppRole>;
  /** roles which the user is allowed to assign to other users */
  assignableRoles: Array<AsAppRole>;
  /** roles which the user is allowed to edit */
  editableRoles: Array<AsAppRole>;
  /** explicit permissions on resource of different clients */
  filePermission: Array<FilePermissionSimple>;
};

/** resources in a particular app which will be assigned to the user */
export type AppStateSimpleInput = {
  /** name of the app */
  appID: Scalars['String'];
  /** roles granted to the user in the given app. String --> roleIDS */
  roles: Array<Scalars['String']>;
  /** roles which the user is allowed to assign to other users. String --> roleIDS */
  assignableRoles: Array<Scalars['String']>;
  /** roles which the user is allowed to edit. String --> roleIDS */
  editableRoles: Array<Scalars['String']>;
  /** explicit permissions on resource of different clients. String --> fileIDs */
  filePermission: Array<Scalars['String']>;
};

export type AppUserAssignRole = {
  __typename?: 'AppUserAssignRole';
  appUserRole: AppUserRole;
  isCustomRole: Scalars['Boolean'];
  appUserAssignRequired: Array<Maybe<Scalars['String']>>;
  appUserEditableRoles?: Maybe<Array<Scalars['String']>>;
};

export type AppUserRole = {
  __typename?: 'AppUserRole';
  roleID: Scalars['String'];
  roleName: Scalars['String'];
  roleDisplayKey?: Maybe<Scalars['String']>;
  roleDescriptionKey?: Maybe<Scalars['String']>;
  status: StateOfRole;
  appRoleInfo?: Maybe<Array<RoleDisplayInformation>>;
};

/** license assigned to a user */
export type AssignableLicense = {
  __typename?: 'AssignableLicense';
  /** id of the license packet from which to assign the license */
  licensePacketId?: Maybe<Scalars['String']>;
  /** name of the group (application), e.g. leak-reporter */
  licenseGroupID?: Maybe<Scalars['String']>;
  /** type of the license e.g. user or guest */
  licenseTypeID?: Maybe<Scalars['String']>;
  /** type of the license e.g. user or guest */
  licenseID?: Maybe<Scalars['String']>;
};

/** license assigned to a user */
export type AssignableLicenseInput = {
  /** id of the license packet from which to assign the license */
  licensePacketId?: Maybe<Scalars['String']>;
  /** name of the group (application), e.g. leak-reporter */
  licenseGroupID?: Maybe<Scalars['String']>;
  /** type of the license e.g. user or guest */
  licenseTypeID?: Maybe<Scalars['String']>;
  /** type of the license e.g. user or guest */
  licenseID?: Maybe<Scalars['String']>;
};

export type AssignedRoleInput = {
  /** name of the app, e.g. iam */
  appName: Scalars['String'];
  /** name of the role e.g. adduser */
  roles: Array<Maybe<Scalars['String']>>;
};

export type CloudLicensePacket = {
  __typename?: 'CloudLicensePacket';
  /** unique id of the license packet */
  packetId: Scalars['String'];
  /** name of the packet - can be set by the user */
  packetName: Scalars['String'];
  /** number of licenses of this type e.g. 20 */
  numberOfLicenses: Scalars['Int'];
  /** number of licenses already assigned e.g. 5 (the number of available licenses of this type is LicenseNumber - LicensesAssigned */
  assignedLicenses: Scalars['Int'];
  /** names of the user for whom a license is reserved */
  reservedLicenses: Array<Maybe<Scalars['String']>>;
};

export type CloudLicensePacketGroup = {
  __typename?: 'CloudLicensePacketGroup';
  groupId?: Maybe<Scalars['ID']>;
  groupname: Scalars['String'];
  displayNameKey?: Maybe<Scalars['String']>;
  descriptionKey?: Maybe<Scalars['String']>;
  licenseGroupTypeDetails: Array<Maybe<LicenseGroupTypeDetails>>;
};

export type CompanyStats = {
  __typename?: 'CompanyStats';
  companyName: Scalars['String'];
  noOfUsers: Scalars['Int'];
  noOfProjects: Scalars['Int'];
  noOfBuildings: Scalars['Int'];
};

export enum ExternType {
  /** user belonging to the organization */
  Intern = 'intern',
  /** user extern to the organization */
  Extern = 'extern'
}

export type FileAccessPermissionEx = {
  __typename?: 'FileAccessPermissionEx';
  hasFullAccess: Scalars['Boolean'];
  filePermission?: Maybe<Array<FilePermissionExSimple>>;
};

/** Structure used to represent the permission on a resource */
export type FilePermission = {
  __typename?: 'FilePermission';
  /** arbitrary internal id which might have been set by the application registering the corresponding resource */
  fileID: Scalars['String'];
  /** path where the resource is located e.g. [Bosch, Renningen] */
  parentFileID?: Maybe<Scalars['String']>;
  /** name of the resource (has to be unique in the directory) */
  filename: Scalars['String'];
  /** type of the resource - must correspond to a registered meta resource e.g. project */
  filetypeID: Scalars['String'];
  /** meta information related to the resource */
  metaInfo?: Maybe<ResourceMetaInformation>;
};

export type FilePermissionEx = {
  __typename?: 'FilePermissionEx';
  hasFullAccess: Scalars['Boolean'];
  filePermission?: Maybe<Array<FilePermission>>;
};

/** Structure used to represent the permission on a resource */
export type FilePermissionExSimple = {
  __typename?: 'FilePermissionExSimple';
  /** arbitrary internal id which might have been set by the application registering the corresponding resource */
  fileID: Scalars['String'];
  /** path where the resource is located e.g. [Bosch, Renningen] */
  parentFileID?: Maybe<Scalars['String']>;
  /** name of the resource (has to be unique in the directory) */
  filename: Scalars['String'];
  /** type of the resource - must correspond to a registered meta resource e.g. project */
  filetypeID: Scalars['String'];
  /** meta information related to the resource */
  metaInfo?: Maybe<ResourceMetaInformation>;
  /** whether the resource is required by one of the subordinates */
  required: Scalars['Boolean'];
  /** wheter the given superior has access to this resource */
  superiorHasAccess: Scalars['Boolean'];
};

/** Structure used to represent the permission on a resource */
export type FilePermissionSimple = {
  __typename?: 'FilePermissionSimple';
  /** arbitrary internal id which might have been set by the application registering the corresponding resource */
  fileID: Scalars['String'];
  /** path where the resource is located e.g. [Bosch, Renningen] */
  parentFileID?: Maybe<Scalars['String']>;
  /** name of the resource (has to be unique in the directory) */
  filename: Scalars['String'];
  /** type of the resource - must correspond to a registered meta resource e.g. project */
  filetypeID: Scalars['String'];
  /** meta information related to the resource */
  metaInfo?: Maybe<ResourceMetaInformation>;
};

/** License group - only one of the license types in this group can be assigned to a user */
export type LicenseGroup = {
  __typename?: 'LicenseGroup';
  groupId: Scalars['String'];
  /** name of the group e.g. cs-lekage */
  groupname: Scalars['String'];
  /** key for the translation of the license group name */
  displayNameKey: Scalars['String'];
  /** list of license types within this group e.g. leak-reporter-user and leak-reporter-guest */
  licenseTypes: Array<Maybe<LicenseType>>;
};

export type LicenseGroupTypeDetails = {
  __typename?: 'LicenseGroupTypeDetails';
  id?: Maybe<Scalars['String']>;
  licenseType: Scalars['String'];
  displayNameKey?: Maybe<Scalars['String']>;
  descriptionKey?: Maybe<Scalars['String']>;
  numberOfLicenses: Scalars['Int'];
  assignedLicenses?: Maybe<Scalars['Int']>;
  licenseUsers?: Maybe<Array<Maybe<ActivatedLicense>>>;
  numberOfReservedLicenses?: Maybe<Scalars['Int']>;
};

export type LicensePacketInfo = {
  __typename?: 'LicensePacketInfo';
  /** Order ID of the license packet */
  orderId: Scalars['String'];
  /** date when the license packet was created */
  creationDate: Scalars['String'];
  /** null if the organization for this license packet has not yet been activated */
  activatedOrganizationInfo?: Maybe<ActivatedOrganizationLicenseInfo>;
  /** null if the license packet has not been activated */
  activatedLicensePacketInfo?: Maybe<ActivatedLicensePacketInfo>;
  /** license packet information */
  licensePacket: ActivatedLicensePacket;
  assignedGroupLicences?: Maybe<Array<Maybe<CloudLicensePacketGroup>>>;
};

/** License Type - can be assigned to a user and gives the right on an application */
export type LicenseType = {
  __typename?: 'LicenseType';
  licenseTypeId: Scalars['String'];
  /** type of the license, e.g. cs-lekage-user */
  licenseType: Scalars['String'];
  /** key for the translation of the license type */
  displayNameKey: Scalars['String'];
  /** number of licenses of this type e.g. 20 */
  numberOfLicenses: Scalars['Int'];
  /** number of licenses already assigned e.g. 5 (the number of available licenses of this type is LicenseNumber - LicensesAssigned */
  assignedLicenses: Scalars['Int'];
  /** number of reserved licenses (tied to a user) */
  numberOfReservedLicenses: Scalars['Int'];
  /** license packets activated for this cloud */
  licensePackets: Array<Maybe<CloudLicensePacket>>;
};

export enum LicenseUser {
  /** regular user */
  Regular = 'regular',
  /** cs user - no license required */
  Csuser = 'csuser'
}

export enum MsrRequirement {
  Optional = 'OPTIONAL',
  Required = 'REQUIRED',
  Forbidden = 'FORBIDDEN'
}

export type MinimalUserInfo = {
  __typename?: 'MinimalUserInfo';
  orgUserID: Scalars['String'];
  email: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  doSome?: Maybe<Scalars['Boolean']>;
  deactivateLicensePacket?: Maybe<Scalars['Boolean']>;
  updateLicensePacket?: Maybe<Scalars['Boolean']>;
  createRole?: Maybe<Scalars['Boolean']>;
  updateRole?: Maybe<Scalars['Boolean']>;
  updateRoleState?: Maybe<Scalars['Boolean']>;
  deleteRole?: Maybe<Scalars['Boolean']>;
  /**
   * create a user with the given user information. This includes username (used for login)
   * as well email address (for passowrd forget). The username must be unique
   * in the organization and the username. The given permissions will be granted to the user.
   */
  createUser?: Maybe<Scalars['Boolean']>;
  /**
   * create a user with the given user information. This includes username (used for login)
   * as well email address (for passowrd forget) and passowrd. The username must be unique
   * in the organization. The given permissions will be granted to the user.
   */
  createUserSimple?: Maybe<Scalars['Boolean']>;
  /**
   * updates a user. The parameter are basically the same as for create user.
   * Note that all permissions previously assigned are removed, i.e. it will
   * only get the permissions specified in this call. If userInfo
   * is not given the corresponding values are not changed.
   */
  updateUser?: Maybe<Scalars['Boolean']>;
  /**
   * updates a user. The parameter are basically the same as for create user.
   * Note that all permissions previously assigned are removed, i.e. it will
   * only get the permissions specified in this call. If userInfo
   * is not given the corresponding values are not changed.
   */
  updateUserSimple?: Maybe<Scalars['Boolean']>;
  /** updates the state of a user (move to trash or activate again) */
  updateUserState?: Maybe<Scalars['Boolean']>;
  /**
   * sets a new user as the root admin. The old user will still exists. If keppLicenses is true
   * and sufficient licenses are available the old admin will basically keep the licenses and
   * can still work with the software. If keepLicenses is false of not enough licenses are
   * available the old admin may not have licenses and can no longer use the software. In this
   * case the user can still be edited by the root admin in the ia.
   */
  setRootAdmin: Scalars['Boolean'];
  /** deletes the user with the given name */
  deleteUser?: Maybe<Scalars['Boolean']>;
};


export type MutationDeactivateLicensePacketArgs = {
  realmId: Scalars['String'];
  licensePacketId: Scalars['String'];
};


export type MutationUpdateLicensePacketArgs = {
  realmId: Scalars['String'];
  licensePacket: ActivatedLicensePacketInput;
};


export type MutationCreateRoleArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  role: RoleInput;
};


export type MutationUpdateRoleArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  role: RoleInput;
};


export type MutationUpdateRoleStateArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  roleID: Scalars['String'];
  state: StateOfRole;
};


export type MutationDeleteRoleArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  roleID: Scalars['String'];
};


export type MutationCreateUserArgs = {
  realmName: Scalars['String'];
  userState: UserStateInput;
  sendEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationCreateUserSimpleArgs = {
  realmName: Scalars['String'];
  userState: UserStateSimpleInput;
  sendEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
  userState?: Maybe<UserStateInput>;
  sendEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserSimpleArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
  userState?: Maybe<UserStateSimpleInput>;
  sendEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateUserStateArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
  state: StateOfUser;
};


export type MutationSetRootAdminArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
  keepLicenses: Scalars['Boolean'];
};


export type MutationDeleteUserArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
};

/** Information on the license assigned to an organization */
export type OrganizationLicense = {
  __typename?: 'OrganizationLicense';
  /** unique id for the organization license */
  organizationLicenseId: Scalars['String'];
  /** name of the organization corresponding to the license (e.g. Kaeser) */
  organizationName: Scalars['String'];
  /** date when the license was activated */
  activationDate: Scalars['String'];
  /** date when the license expires */
  expirationDate: Scalars['String'];
  /** licenses for applications i.e. leak-reporter, cs-network, ... */
  licenseGroups: Array<Maybe<LicenseGroup>>;
};

export type Permission = {
  __typename?: 'Permission';
  resourceId: Scalars['String'];
  resourceDisplayKey?: Maybe<Scalars['String']>;
  resourceDescriptionKey?: Maybe<Scalars['String']>;
  resourceScopes: Array<ResourceScope>;
  groupID: Scalars['Int'];
  indexID: Scalars['Int'];
};

export type PermissionInput = {
  resourceId: Scalars['String'];
  scopeId?: Maybe<Array<Scalars['String']>>;
};

export type Query = {
  __typename?: 'Query';
  /** get AM version information */
  getVersion: AmVersionInformation;
  getAppStatistics?: Maybe<Array<CompanyStats>>;
  getAssignableFileResources?: Maybe<Array<FilePermissionExSimple>>;
  /** Returns information on the available licenses of the organization */
  getOrganizationLicense?: Maybe<OrganizationLicense>;
  /**
   * Get the licenses which can be assigned by the invoker, considering
   * the selected superior and subordinates.
   */
  getAllAssignableLicensesEx?: Maybe<ActivatedCloudLicense>;
  /**
   * Get the assignable Packets list which can be assigned by the invoker, considering
   * the selected superior and accessible realms.
   */
  getAllAssignablePacketLicensesEx?: Maybe<Array<Maybe<ActivatedCloudLicense>>>;
  /** get various information on the license packet */
  getLicensePacketInformation?: Maybe<LicensePacketInfo>;
  getRole?: Maybe<Role>;
  getAccessibleRoles: Array<Maybe<AppRole>>;
  getAppUserRoles: Array<AppUserAssignRole>;
  getAppRole?: Maybe<AppRole>;
  getAppAssignableMetaPermission: Array<Permission>;
  /**
   * Return the user info, licenses and permissions (settings) for the given user. The result
   * corresponds exactly to the permissions specified in createUser or updateUser.
   * @fileFilter e.g company-id, project-id, group-id, building-id
   */
  getUser?: Maybe<UserState>;
  /** Returns access manangement roles that determine if a user is allowed to create user or role.  */
  getUserSpecialPermissions?: Maybe<Array<SpecialPermissions>>;
  /**
   * Return the user info, licenses and permissions (settings) for the given user. The result
   * corresponds exactly to the permissions specified in createUser or updateUser.
   */
  getUserSimple?: Maybe<UserStateSimple>;
  /**
   * Returns a list of users (along with the permissions on them) to which
   * the invoker (according to the token) has access
   */
  getAccessibleUsers?: Maybe<Array<Maybe<UserResourcePermission>>>;
  /**
   * Returns a list of users (along with the permissions on them) to which
   * the invoker (according to the token) has access. Is bassically the same as
   * getAccessibleUsers but does not return users without user management rights.
   * If licenses are given only those users are returned which are allowed to
   * assign that licenses.
   */
  getAccessibleSuperiors: Array<Maybe<UserResourcePermission>>;
  /**
   * same as getAccessibleSuperiors but for an existing user (with username). This
   * query will also indicate whether it is a full superior in the sense that the
   * accessible user has at least the permission of the existing user (i.e. it is not
   * necessary to reduce the rights of the existing user if that accessible user
   * becomes the superior). Furthermore it is indicated whether the accessible user
   * is a subordinate (or subordiante of a subordinate etc.) of the existing user.
   */
  getAccessibleSuperiorsExUser: Array<Maybe<UserResourcePermissionExUser>>;
  /**
   * For use when editing a role (is not required in role creation). Returns the
   * users to which the invoker (user given) has access. In contrast to
   * getAccessibleUsers there is a field in the structure of every user which
   * indicates whether the user must be allowed (REQUIRED) to assign the role, he
   * can assign the role (OPTIONAL) or cannot be allowerd to assign the role
   * (FORBIDEN). If this indications are not considered it will not be possible to
   * configure a set of consistent permissions and always an error will occur when
   * trying to update the role.
   *
   * Example REQUIRED: If a subordinate of a user has this custom role, then the
   * user is clearly required to be able to assign this role, i.e. REQUIRED.
   *
   * Example FORBIDDEN: This can happen if a user with this custom role has
   * subordinates or assignable / editable roles in this app (otherwise it should
   * not occur). Clearly the permissions of this custom role must include at
   * least the permissions of the roles of all the subordinates. Now if there is
   * a user which has a role with permissions less than that minimum required set
   * the user cannot be allowed to assign this role (this user would require the
   * role to have less permissions then the minimum according to the subordinates
   * (or editable / assignable roles) which is not possible).
   *
   * Note that it is very well possible that a user is OPTIONAL which has less
   * permissions then the existing role has (but of course only if there are no
   * subordinates of a user with this role which require them). That is if the
   * user is allowed to assign this role then some permissions which the role
   * previously had will not be allowed any more (this is considered then in
   * getSpecialResourcesRoleReq).
   *
   * Note also that there are potentially more users who are allowed to assign
   * this role, however they will not be returned as the invoker does not have
   * access to them.
   */
  getAccessibleUsersRole?: Maybe<Array<Maybe<UserResourcePermissionRole>>>;
  /**
   * returns true if the invoker is the root admin (false otherwise). Only the root
   * admin should be allowed to clear database.
   */
  isRootAdmin: Scalars['Boolean'];
  /** returns true for every realmName for which the user is root admin (false otherwise), */
  isRootAdminEx: Array<Maybe<Scalars['Boolean']>>;
  getUserInfo: RegisteredUserInfo;
};


export type QueryGetAppStatisticsArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
};


export type QueryGetAssignableFileResourcesArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  superiorID: Scalars['String'];
  subordinateIDs?: Maybe<Array<Scalars['String']>>;
  fileFilter?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetOrganizationLicenseArgs = {
  realmName: Scalars['String'];
};


export type QueryGetAllAssignableLicensesExArgs = {
  realmName: Scalars['String'];
  superiorID?: Maybe<Scalars['String']>;
  subordinatesIDs?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetAllAssignablePacketLicensesExArgs = {
  realmNames: Array<Maybe<Scalars['String']>>;
  superior: Scalars['String'];
};


export type QueryGetLicensePacketInformationArgs = {
  licenseString: Scalars['String'];
};


export type QueryGetRoleArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
};


export type QueryGetAccessibleRolesArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  state: StateOfRole;
};


export type QueryGetAppUserRolesArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  superiorID: Scalars['String'];
  subordinateIDs: Array<Scalars['String']>;
};


export type QueryGetAppRoleArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetAppAssignableMetaPermissionArgs = {
  organizationID: Scalars['String'];
  appName: Scalars['String'];
  editableBy: Array<Scalars['String']>;
  assignableBy: Array<Scalars['String']>;
};


export type QueryGetUserArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
  fileFilter?: Maybe<Array<Scalars['String']>>;
};


export type QueryGetUserSpecialPermissionsArgs = {
  realmName: Scalars['String'];
};


export type QueryGetUserSimpleArgs = {
  realmName: Scalars['String'];
  username: Scalars['String'];
};


export type QueryGetAccessibleUsersArgs = {
  realmName: Scalars['String'];
  state?: Maybe<StateOfUser>;
  username: Scalars['String'];
};


export type QueryGetAccessibleSuperiorsArgs = {
  realmName: Scalars['String'];
  state?: Maybe<StateOfUser>;
  licenses: Array<Maybe<SingleUserLicenseInput>>;
};


export type QueryGetAccessibleSuperiorsExUserArgs = {
  realmName: Scalars['String'];
  state?: Maybe<StateOfUser>;
  username: Scalars['String'];
};


export type QueryGetAccessibleUsersRoleArgs = {
  realmName: Scalars['String'];
  appName: Scalars['String'];
  roleName?: Maybe<Scalars['String']>;
};


export type QueryIsRootAdminArgs = {
  realmName?: Maybe<Scalars['String']>;
};


export type QueryIsRootAdminExArgs = {
  realmName: Array<Maybe<Scalars['String']>>;
};


export type QueryGetUserInfoArgs = {
  username: Scalars['String'];
};

/** organization independent information on a (registered) user */
export type RegisteredUserInfo = {
  __typename?: 'RegisteredUserInfo';
  /** license requirements for this user */
  licenseUser: LicenseUser;
  /** whether the user is already registered */
  registered: Scalars['Boolean'];
};

export type RequiredResourceScope = {
  __typename?: 'RequiredResourceScope';
  resourceID: Scalars['String'];
  requiredScopes: Array<Maybe<Scalars['String']>>;
};

/**
 * Meta information related to the resource. This is intended to provide
 * helpfull information for a user which is assigning resources to a user
 */
export type ResourceMetaInformation = {
  __typename?: 'ResourceMetaInformation';
  /** Data and time of the resource e.g. when it was measured we recommend RFC 3339 for the string format */
  dateTime?: Maybe<Scalars['String']>;
  /** Version of software / device which created the resource */
  version?: Maybe<Scalars['String']>;
  /** state of the resource e.g. active, discarded, ... */
  state?: Maybe<StateOfResource>;
  /** Date and time when the resource was registered */
  registerDateTime?: Maybe<Scalars['String']>;
};

/**
 * Meta information related to the resource. This is intended to provide
 * helpfull information for a user which is assigning resources to a user
 */
export type ResourceMetaInformationInput = {
  /** Data and time of the resource e.g. when it was measured we recommend RFC 3339 for the string format */
  dateTime: Scalars['String'];
  /** Version of software / device which created the resource */
  version: Scalars['String'];
  /** state of the resource e.g. active, discarded, ... */
  state?: Maybe<StateOfResource>;
};

/**
 *  type ResourceScopeDependencies {
 *     scopeID: String!
 *     requiredResourceDependencies: [RequiredResourceScope]!
 * }
 */
export type ResourceScope = {
  __typename?: 'ResourceScope';
  scopeID: Scalars['String'];
  scopeDisplayKey: Scalars['String'];
  scopeDescriptionKey: Scalars['String'];
  scopeAssigned: Scalars['Boolean'];
  scopeAssignRequired?: Maybe<Scalars['Boolean']>;
  scopeDependencies?: Maybe<Array<RequiredResourceScope>>;
};

export type Role = {
  __typename?: 'Role';
  roleId: Scalars['String'];
  roleName: Scalars['String'];
  roleDisplayKey?: Maybe<Scalars['String']>;
  roleDescriptionKey?: Maybe<Scalars['String']>;
  rolePermissions?: Maybe<Array<Permission>>;
  status: StateOfRole;
};

export type RoleDisplayInformation = {
  __typename?: 'RoleDisplayInformation';
  roleDisplayName: Scalars['String'];
  roleDescription: Scalars['String'];
  langKey: Scalars['String'];
};

export type RoleDisplayInput = {
  roleDisplayName: Scalars['String'];
  roleDescription?: Maybe<Scalars['String']>;
  langKey: Scalars['String'];
};

export type RoleInput = {
  roleId: Scalars['String'];
  roleName: Scalars['String'];
  appRoleInfo?: Maybe<Array<RoleDisplayInput>>;
  rolePermissions?: Maybe<Array<PermissionInput>>;
  status: StateOfRole;
  userPermissions?: Maybe<RoleUserPermissionInput>;
};

export type RoleUserPermission = {
  __typename?: 'RoleUserPermission';
  editableBy: Array<Maybe<MinimalUserInfo>>;
  assignableBy: Array<Maybe<MinimalUserInfo>>;
};

export type RoleUserPermissionInput = {
  editableBy: Array<Maybe<Scalars['String']>>;
  assignableBy: Array<Maybe<Scalars['String']>>;
};

/** license assigned to a user */
export type SingleUserLicense = {
  __typename?: 'SingleUserLicense';
  /** id of the license packet to which this license belongs */
  licensePacketID: Scalars['String'];
  /** id of the license group (application), e.g. leak-reporter-id */
  licenseGroupID: Scalars['String'];
  /** id of the type of the license e.g. user-id or guest-id */
  licenseTypeID: Scalars['String'];
  /** date until the license is valid */
  expirationDate: Scalars['String'];
  /** if null or regular a license of the cloud licenses. csuser is a free license */
  regularLicense?: Maybe<LicenseUser>;
};

/** license assigned to a user */
export type SingleUserLicenseInput = {
  /** id of the license packet to which this license belongs */
  licensePacketID: Scalars['String'];
  /** id of the license group (application), e.g. leak-reporter-id */
  licenseGroupID: Scalars['String'];
  /** id of the type of the license e.g. user-id or guest-id */
  licenseTypeID: Scalars['String'];
  /** if null or regular the license is taken from the available cloud licenses. For the value csuser no license is taken - this is however only allowed for corresponding user. */
  regularLicense?: Maybe<LicenseUser>;
};

export type SpecialPermissions = {
  __typename?: 'SpecialPermissions';
  roleID: Scalars['String'];
  roleName: Scalars['String'];
  displayNameKey: Scalars['String'];
  descriptionKey: Scalars['String'];
};

export enum StateOfResource {
  /** normal resource state */
  Active = 'active',
  /** resource was moved to trash - not deleted yet */
  Intrash = 'intrash'
}

export enum StateOfRole {
  /** normal file state */
  Active = 'active',
  /** file was moved to trash - not deleted yet */
  Intrash = 'intrash'
}

export enum StateOfUser {
  /** normal user state */
  Active = 'active',
  /** user was moved to trash - not deleted yet */
  Intrash = 'intrash'
}

/** App specific user roles */
export type UpAppRole = {
  __typename?: 'UPAppRole';
  /** name of the app to which the role list corresponds */
  appName: Scalars['String'];
  /** roles granted to the user for the given app */
  roles: Array<Maybe<UpAppRoleRole>>;
};

export type UpAppRoleRole = {
  __typename?: 'UPAppRoleRole';
  /** name of the role */
  roleName: Scalars['String'];
  /** key of the role, null for individual roles */
  displayNameKey?: Maybe<Scalars['String']>;
};

export enum UrpRequirment {
  Optional = 'OPTIONAL',
  Required = 'REQUIRED',
  Forbidden = 'FORBIDDEN'
}

export type UserAccessPermission = {
  __typename?: 'UserAccessPermission';
  access: AccessPermission;
  assignPermissionDeps: Array<AccessPermission>;
};

/** Various information about a created user */
export type UserInfo = {
  __typename?: 'UserInfo';
  /** organizationUserId of the user */
  orgUserID: Scalars['String'];
  /** name of the user */
  username?: Maybe<Scalars['String']>;
  /** email address of the user */
  email: Scalars['String'];
  /** information on whether the user is extern */
  extern: ExternType;
  /** company information */
  company: Scalars['String'];
  /** arbitrary note */
  note?: Maybe<Scalars['String']>;
  /** date and time in when the user logged in the last time (in RFC 3339) */
  lastLogIn?: Maybe<Scalars['String']>;
  /** whether the user is already registered */
  registered: Scalars['Boolean'];
};

/** Various information about a user */
export type UserInfoInput = {
  /** email of the user */
  email: Scalars['String'];
  /** username of the user */
  username: Scalars['String'];
  /** information on whether the user is extern */
  extern: ExternType;
  /** arbitrary note */
  note?: Maybe<Scalars['String']>;
};

/** information about a user along with the access rights on it */
export type UserResourcePermission = {
  __typename?: 'UserResourcePermission';
  /** user information */
  userInfo: UserInfo;
  /** superior of this user (is null only for organization admin) */
  superior?: Maybe<MinimalUserInfo>;
  /** permissions of the caller on the user */
  userPermission: Array<AccessPermission>;
  /** roles assigned to this user */
  appRoles: Array<Maybe<UpAppRole>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** permissible action scopes: e.g view, edit, delete  */
  scopes: Array<Maybe<Scalars['String']>>;
  subordinateUsers: Array<MinimalUserInfo>;
  /** adduser or adduserandrole or none */
  assignableUserPermission: Array<UserAccessPermission>;
};

/** information about a user along with the access rights on it */
export type UserResourcePermissionEx = {
  __typename?: 'UserResourcePermissionEx';
  /** user information */
  userInfo: UserInfo;
  /** superior of this user (is null only for organization admin) */
  superior?: Maybe<Scalars['String']>;
  /** permissions of the caller on the user */
  userPermission: Array<Maybe<Scalars['String']>>;
  /** roles assigned to this user */
  appRoles: Array<Maybe<UpAppRole>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** users which may be assigned as subordinate */
  includesPermissionsOfUser: Array<Maybe<Scalars['String']>>;
  /** whether the user can be a subordinate */
  isManageable: Scalars['Boolean'];
};

/**
 * information about a user along with the access rights on it
 * and additional relations to an existing user
 */
export type UserResourcePermissionExUser = {
  __typename?: 'UserResourcePermissionExUser';
  /** user information */
  userInfo: UserInfo;
  /** superior of this user (is null only for organization admin) */
  superior?: Maybe<Scalars['String']>;
  /** permissions of the caller on the user */
  userPermission: Array<Maybe<Scalars['String']>>;
  /** roles assigned to this user */
  appRoles: Array<Maybe<UpAppRole>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** if this user has at least the rights of the existing user */
  isFullSuperior: Scalars['Boolean'];
  /** if the user is a subordinate (or subordinate of subordinate) */
  isSubordinate: Scalars['Boolean'];
};

/** information about a user along with the access rights on it */
export type UserResourcePermissionRole = {
  __typename?: 'UserResourcePermissionRole';
  /** user information */
  userInfo: UserInfo;
  /** superior of this user (is null only for organization admin) */
  superior?: Maybe<Scalars['String']>;
  /** permissions of the caller on the user */
  userPermission: Array<Maybe<Scalars['String']>>;
  /** roles assigned to this user */
  appRoles: Array<Maybe<UpAppRole>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** subordinates of the user */
  subordinates: Array<Maybe<Scalars['String']>>;
  /** consistency constraint for a given role */
  required: UrpRequirment;
};

/**
 * The complete state of the user in the IAM, from user information over assigned licenses to permissions.
 * The roles and permissions explicitly assigned to the user. That means this does not
 * necessarily include all resources the user has access to. If for example the user has
 * permissions on a resource type within that directory, the corresponding resources
 * will not be listed (unlike the were also explicitly granted and not via the directory).
 */
export type UserState = {
  __typename?: 'UserState';
  /** various user related information */
  userInfo?: Maybe<UserInfo>;
  /** ids of the licenses assigned to the user */
  licenses: Array<SingleUserLicense>;
  /** whether the user is enabled (can login or not) */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** user which is allowed to manage this user, can be only null if the user is an admin */
  superior?: Maybe<MinimalUserInfo>;
  /** user which are directly below this user in the hierarchy */
  subordinates: Array<MinimalUserInfo>;
  /** permissions of the users in the given app */
  appStates: Array<AppState>;
  userPermission: Array<AccessPermission>;
  assignableUserPermission: Array<AccessPermission>;
  /** licenses assignable by this user null if none */
  assignableLicenses: Array<AssignableLicense>;
};

/**
 * The complete state of the user in the IAM, from user information over assigned licenses to permissions.
 * The roles and permissions which are explicity assigned to the user. Note
 * that by giving the user access to directories (or rather access to scopes
 * on certain types of resources with that directories) the user gets
 * access to resources which don't have to be specified in the resources field.
 */
export type UserStateInput = {
  /** information on the user, like email, extern, ... */
  userInfo: UserInfoInput;
  /** ids of the licenses assigned to the user */
  licenses: Array<Maybe<SingleUserLicenseInput>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** state of the user e.g. active */
  state: StateOfUser;
  /** user which is allowed to manage this user, can be only null if the user is an admin */
  superior?: Maybe<Scalars['String']>;
  /** user which are directly below this user in the hierarchy */
  subordinates: Array<Maybe<Scalars['String']>>;
  /** permissions of the users in the given app */
  appStates: Array<Maybe<AppStateInput>>;
  /** licenses assignable by this user null if none */
  assignableLicenses: Array<AssignableLicenseInput>;
  /** adduser or adduserandrole or none */
  userPermission?: Maybe<Array<Scalars['String']>>;
  userAssignablePermission: Array<Scalars['String']>;
};

/**
 * The complete state of the user in the IAM, from user information over assigned licenses to permissions.
 * The roles and permissions explicitly assigned to the user. That means this does not
 * necessarily include all resources the user has access to. If for example the user has
 * permissions on a resource type within that directory, the corresponding resources
 * will not be listed (unlike the were also explicitly granted and not via the directory).
 */
export type UserStateSimple = {
  __typename?: 'UserStateSimple';
  /** various user related information */
  userInfo?: Maybe<UserInfo>;
  /** ids of the licenses assigned to the user */
  licenses: Array<SingleUserLicense>;
  /** whether the user is enabled (can login or not) */
  enabled: Scalars['Boolean'];
  /** user which is allowed to manage this user, can be only null if the user is an admin */
  superior?: Maybe<Scalars['String']>;
  /** user which are directly below this user in the hierarchy */
  subordinates: Array<Scalars['String']>;
  /** permissions of the users in the given app */
  appStates: Array<AppStateSimple>;
  /** licenses assignable by this user null if none */
  assignableLicenses: Array<AssignableLicense>;
};

/**
 * The complete state of the user in the IAM, from user information over assigned licenses to permissions.
 * The roles and permissions which are explicity assigned to the user. Note
 * that by giving the user access to directories (or rather access to scopes
 * on certain types of resources with that directories) the user gets
 * access to resources which don't have to be specified in the resources field.
 */
export type UserStateSimpleInput = {
  /** information on the user, like email, extern, ... */
  userInfo: UserInfoInput;
  /** ids of the licenses assigned to the user */
  licenses: Array<Maybe<SingleUserLicenseInput>>;
  /** whether the user is enabled (can login or not) - not if the user is not enabled the licenses will be not assigned to him */
  enabled: Scalars['Boolean'];
  /** user which is allowed to manage this user, can be only null if the user is an admin */
  superior?: Maybe<Scalars['String']>;
  /** user which are directly below this user in the hierarchy */
  subordinates: Array<Maybe<Scalars['String']>>;
  /** permissions of the users in the given app */
  appStates: Array<Maybe<AppStateSimpleInput>>;
  /** licenses assignable by this user null if none */
  assignableLicenses: Array<AssignableLicenseInput>;
};

export type GetAccessibleUsersQueryVariables = Exact<{
  realmName: Scalars['String'];
  state?: Maybe<StateOfUser>;
  username: Scalars['String'];
}>;


export type GetAccessibleUsersQuery = (
  { __typename?: 'Query' }
  & { getAccessibleUsers?: Maybe<Array<Maybe<(
    { __typename?: 'UserResourcePermission' }
    & Pick<UserResourcePermission, 'enabled'>
    & { userInfo: (
      { __typename?: 'UserInfo' }
      & Pick<UserInfo, 'username' | 'email' | 'registered'>
    ) }
  )>>> }
);

export type GetLicensePacketPermissionsQueryVariables = Exact<{
  realmName: Scalars['String'];
  superior: Scalars['String'];
  subordinates?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type GetLicensePacketPermissionsQuery = (
  { __typename?: 'Query' }
  & { getAllAssignableLicensesEx?: Maybe<(
    { __typename?: 'ActivatedCloudLicense' }
    & Pick<ActivatedCloudLicense, 'organizationName' | 'forbidden' | 'required'>
    & { licensePackets?: Maybe<Array<Maybe<(
      { __typename?: 'ActivatedLicensePacket' }
      & Pick<ActivatedLicensePacket, 'id' | 'packetName' | 'forbidden' | 'required'>
    )>>> }
  )> }
);

export type GetLicensePacketInformationQueryVariables = Exact<{
  licenseString: Scalars['String'];
}>;


export type GetLicensePacketInformationQuery = (
  { __typename?: 'Query' }
  & { getLicensePacketInformation?: Maybe<(
    { __typename?: 'LicensePacketInfo' }
    & Pick<LicensePacketInfo, 'orderId' | 'creationDate'>
    & { activatedOrganizationInfo?: Maybe<(
      { __typename?: 'ActivatedOrganizationLicenseInfo' }
      & Pick<ActivatedOrganizationLicenseInfo, 'organizationName' | 'displayName' | 'organizationActivatedAt' | 'organizationActivator' | 'expirationDate'>
    )>, activatedLicensePacketInfo?: Maybe<(
      { __typename?: 'ActivatedLicensePacketInfo' }
      & Pick<ActivatedLicensePacketInfo, 'licensePacketActivatedAt' | 'licensePacketActivator' | 'expirationDate'>
    )>, licensePacket: (
      { __typename?: 'ActivatedLicensePacket' }
      & Pick<ActivatedLicensePacket, 'id' | 'packetName'>
      & { licenseGroups?: Maybe<Array<Maybe<(
        { __typename?: 'ActivatedLicenseGroup' }
        & Pick<ActivatedLicenseGroup, 'id' | 'groupname' | 'displayNameKey' | 'descriptionKey'>
        & { licenseTypes?: Maybe<Array<Maybe<(
          { __typename?: 'ActivatedLicenseType' }
          & Pick<ActivatedLicenseType, 'id' | 'licenseType' | 'displayNameKey' | 'descriptionKey'>
          & { licenses?: Maybe<Array<Maybe<(
            { __typename?: 'ActivatedLicense' }
            & Pick<ActivatedLicense, 'id' | 'reserved' | 'userId' | 'required'>
          )>>> }
        )>>> }
      )>>> }
    ), assignedGroupLicences?: Maybe<Array<Maybe<(
      { __typename?: 'CloudLicensePacketGroup' }
      & Pick<CloudLicensePacketGroup, 'groupId' | 'groupname' | 'displayNameKey'>
      & { licenseGroupTypeDetails: Array<Maybe<(
        { __typename?: 'LicenseGroupTypeDetails' }
        & Pick<LicenseGroupTypeDetails, 'id' | 'licenseType' | 'displayNameKey' | 'numberOfLicenses'>
      )>> }
    )>>> }
  )> }
);

export type GetAccessibleOrgLicensePacketsQueryVariables = Exact<{
  realmName: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
  superior: Scalars['String'];
}>;


export type GetAccessibleOrgLicensePacketsQuery = (
  { __typename?: 'Query' }
  & { getAllAssignablePacketLicensesEx?: Maybe<Array<Maybe<(
    { __typename?: 'ActivatedCloudLicense' }
    & Pick<ActivatedCloudLicense, 'organizationName' | 'forbidden' | 'required'>
    & { licensePackets?: Maybe<Array<Maybe<(
      { __typename?: 'ActivatedLicensePacket' }
      & Pick<ActivatedLicensePacket, 'id' | 'packetName'>
    )>>> }
  )>>> }
);

export type ModifyRootAdminMutationVariables = Exact<{
  realmName: Scalars['String'];
  username: Scalars['String'];
  keepLicenses: Scalars['Boolean'];
}>;


export type ModifyRootAdminMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'setRootAdmin'>
);


export const GetAccessibleUsersDocument = gql`
    query GetAccessibleUsers($realmName: String!, $state: StateOfUser, $username: String!) {
  getAccessibleUsers(realmName: $realmName, state: $state, username: $username) {
    userInfo {
      username
      email
      registered
    }
    enabled
  }
}
    `;

/**
 * __useGetAccessibleUsersQuery__
 *
 * To run a query within a React component, call `useGetAccessibleUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessibleUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessibleUsersQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      state: // value for 'state'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useGetAccessibleUsersQuery(baseOptions: Apollo.QueryHookOptions<GetAccessibleUsersQuery, GetAccessibleUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessibleUsersQuery, GetAccessibleUsersQueryVariables>(GetAccessibleUsersDocument, options);
      }
export function useGetAccessibleUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessibleUsersQuery, GetAccessibleUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessibleUsersQuery, GetAccessibleUsersQueryVariables>(GetAccessibleUsersDocument, options);
        }
export type GetAccessibleUsersQueryHookResult = ReturnType<typeof useGetAccessibleUsersQuery>;
export type GetAccessibleUsersLazyQueryHookResult = ReturnType<typeof useGetAccessibleUsersLazyQuery>;
export type GetAccessibleUsersQueryResult = Apollo.QueryResult<GetAccessibleUsersQuery, GetAccessibleUsersQueryVariables>;
export const GetLicensePacketPermissionsDocument = gql`
    query GetLicensePacketPermissions($realmName: String!, $superior: String!, $subordinates: [String!]) {
  getAllAssignableLicensesEx(
    realmName: $realmName
    superiorID: $superior
    subordinatesIDs: $subordinates
  ) {
    organizationName
    licensePackets {
      id
      packetName
      forbidden
      required
    }
    forbidden
    required
  }
}
    `;

/**
 * __useGetLicensePacketPermissionsQuery__
 *
 * To run a query within a React component, call `useGetLicensePacketPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicensePacketPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicensePacketPermissionsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      superior: // value for 'superior'
 *      subordinates: // value for 'subordinates'
 *   },
 * });
 */
export function useGetLicensePacketPermissionsQuery(baseOptions: Apollo.QueryHookOptions<GetLicensePacketPermissionsQuery, GetLicensePacketPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLicensePacketPermissionsQuery, GetLicensePacketPermissionsQueryVariables>(GetLicensePacketPermissionsDocument, options);
      }
export function useGetLicensePacketPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLicensePacketPermissionsQuery, GetLicensePacketPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLicensePacketPermissionsQuery, GetLicensePacketPermissionsQueryVariables>(GetLicensePacketPermissionsDocument, options);
        }
export type GetLicensePacketPermissionsQueryHookResult = ReturnType<typeof useGetLicensePacketPermissionsQuery>;
export type GetLicensePacketPermissionsLazyQueryHookResult = ReturnType<typeof useGetLicensePacketPermissionsLazyQuery>;
export type GetLicensePacketPermissionsQueryResult = Apollo.QueryResult<GetLicensePacketPermissionsQuery, GetLicensePacketPermissionsQueryVariables>;
export const GetLicensePacketInformationDocument = gql`
    query getLicensePacketInformation($licenseString: String!) {
  getLicensePacketInformation(licenseString: $licenseString) {
    orderId
    creationDate
    activatedOrganizationInfo {
      organizationName
      displayName
      organizationActivatedAt
      organizationActivator
      expirationDate
    }
    activatedLicensePacketInfo {
      licensePacketActivatedAt
      licensePacketActivator
      expirationDate
    }
    licensePacket {
      id
      packetName
      licenseGroups {
        id
        groupname
        displayNameKey
        descriptionKey
        licenseTypes {
          id
          licenseType
          displayNameKey
          descriptionKey
          licenses {
            id
            reserved
            userId
            required
          }
        }
      }
    }
    assignedGroupLicences {
      groupId
      groupname
      displayNameKey
      licenseGroupTypeDetails {
        id
        licenseType
        displayNameKey
        numberOfLicenses
      }
    }
  }
}
    `;

/**
 * __useGetLicensePacketInformationQuery__
 *
 * To run a query within a React component, call `useGetLicensePacketInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLicensePacketInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLicensePacketInformationQuery({
 *   variables: {
 *      licenseString: // value for 'licenseString'
 *   },
 * });
 */
export function useGetLicensePacketInformationQuery(baseOptions: Apollo.QueryHookOptions<GetLicensePacketInformationQuery, GetLicensePacketInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLicensePacketInformationQuery, GetLicensePacketInformationQueryVariables>(GetLicensePacketInformationDocument, options);
      }
export function useGetLicensePacketInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLicensePacketInformationQuery, GetLicensePacketInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLicensePacketInformationQuery, GetLicensePacketInformationQueryVariables>(GetLicensePacketInformationDocument, options);
        }
export type GetLicensePacketInformationQueryHookResult = ReturnType<typeof useGetLicensePacketInformationQuery>;
export type GetLicensePacketInformationLazyQueryHookResult = ReturnType<typeof useGetLicensePacketInformationLazyQuery>;
export type GetLicensePacketInformationQueryResult = Apollo.QueryResult<GetLicensePacketInformationQuery, GetLicensePacketInformationQueryVariables>;
export const GetAccessibleOrgLicensePacketsDocument = gql`
    query GetAccessibleOrgLicensePackets($realmName: [String]!, $superior: String!) {
  getAllAssignablePacketLicensesEx(realmNames: $realmName, superior: $superior) {
    organizationName
    licensePackets {
      id
      packetName
    }
    forbidden
    required
  }
}
    `;

/**
 * __useGetAccessibleOrgLicensePacketsQuery__
 *
 * To run a query within a React component, call `useGetAccessibleOrgLicensePacketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccessibleOrgLicensePacketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccessibleOrgLicensePacketsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      superior: // value for 'superior'
 *   },
 * });
 */
export function useGetAccessibleOrgLicensePacketsQuery(baseOptions: Apollo.QueryHookOptions<GetAccessibleOrgLicensePacketsQuery, GetAccessibleOrgLicensePacketsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccessibleOrgLicensePacketsQuery, GetAccessibleOrgLicensePacketsQueryVariables>(GetAccessibleOrgLicensePacketsDocument, options);
      }
export function useGetAccessibleOrgLicensePacketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccessibleOrgLicensePacketsQuery, GetAccessibleOrgLicensePacketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccessibleOrgLicensePacketsQuery, GetAccessibleOrgLicensePacketsQueryVariables>(GetAccessibleOrgLicensePacketsDocument, options);
        }
export type GetAccessibleOrgLicensePacketsQueryHookResult = ReturnType<typeof useGetAccessibleOrgLicensePacketsQuery>;
export type GetAccessibleOrgLicensePacketsLazyQueryHookResult = ReturnType<typeof useGetAccessibleOrgLicensePacketsLazyQuery>;
export type GetAccessibleOrgLicensePacketsQueryResult = Apollo.QueryResult<GetAccessibleOrgLicensePacketsQuery, GetAccessibleOrgLicensePacketsQueryVariables>;
export const ModifyRootAdminDocument = gql`
    mutation ModifyRootAdmin($realmName: String!, $username: String!, $keepLicenses: Boolean!) {
  setRootAdmin(
    realmName: $realmName
    username: $username
    keepLicenses: $keepLicenses
  )
}
    `;
export type ModifyRootAdminMutationFn = Apollo.MutationFunction<ModifyRootAdminMutation, ModifyRootAdminMutationVariables>;

/**
 * __useModifyRootAdminMutation__
 *
 * To run a mutation, you first call `useModifyRootAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyRootAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyRootAdminMutation, { data, loading, error }] = useModifyRootAdminMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      username: // value for 'username'
 *      keepLicenses: // value for 'keepLicenses'
 *   },
 * });
 */
export function useModifyRootAdminMutation(baseOptions?: Apollo.MutationHookOptions<ModifyRootAdminMutation, ModifyRootAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyRootAdminMutation, ModifyRootAdminMutationVariables>(ModifyRootAdminDocument, options);
      }
export type ModifyRootAdminMutationHookResult = ReturnType<typeof useModifyRootAdminMutation>;
export type ModifyRootAdminMutationResult = Apollo.MutationResult<ModifyRootAdminMutation>;
export type ModifyRootAdminMutationOptions = Apollo.BaseMutationOptions<ModifyRootAdminMutation, ModifyRootAdminMutationVariables>;