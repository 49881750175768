/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, MenuItem, Select } from '@material-ui/core'
import React, { useCallback, useEffect } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { createStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

import LanguageOutlinedIcon from '@material-ui/icons/LanguageOutlined'
import { useState } from 'react'
import { useUpdateUserLangMutation } from '../../../api/preferences.models'

export const contrast = {
  primary: '#e4f6ed',
  secondary: '#ffffff',
  greyText: '#888888',
}

export const useStyles = makeStyles(() =>
  createStyles({
    select: {
      color: contrast.secondary,
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiOutlinedInput-input.MuiInputBase-input': {
        paddingRight: 30,
        paddingLeft: 0,
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    icon: {
      fill: contrast.primary,
    },
    box: {
      paddingLeft: 60,
    },
  }),
)

type LanguageMenuProps = {
  userLang?: string
}
export const LanguageMenu: React.FC<LanguageMenuProps> = ({ userLang }: LanguageMenuProps) => {
  const { i18n } = useTranslation('common')
  const [languageSelected, setLanguageSelected] = useState<string>('')
  const [submitLangPreference] = useUpdateUserLangMutation()
  const classes = useStyles()

  const changeLang = useCallback(
    (lng: string) => {
      setLanguageSelected(lng)
      i18n.changeLanguage(lng)
      submitLangPreference({
        variables: {
          langauge: lng,
        },
      })
    },
    [i18n],
  )

  useEffect(() => {
    if (userLang) {
      changeLang(userLang)
    }
  }, [changeLang, userLang])

  return (
    <>
      <Grid item xs lg={2} />
      <LanguageOutlinedIcon fontSize="medium" />
      <Select
        className={classes.select}
        variant={'outlined'}
        onChange={({ target }) => changeLang(target.value as string)}
        value={languageSelected}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {[
          'en',
          'de',
          'cs',
          'es',
          'fr',
          'it',
          'jp',
          'pl',
          'pt',
          'ro',
          'ru',
          'sl',
          'sv',
          'zh',
          'tr',
          'ua',
          'zho' /* , 'key' */,
        ]
          .sort()
          .map((l) => {
            return (
              <MenuItem key={l} value={l}>
                {l.toUpperCase()}
              </MenuItem>
            )
          })}
      </Select>
    </>
  )
}

export default LanguageMenu
