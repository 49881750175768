import { useApolloClient } from '@apollo/client'
import { AppTop, AppTopUserInfo, CsButtonText } from '@csinstruments/cs-react-theme'
import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect, useState } from 'react'
import LanguageMenu from '../LanguageMenu'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { UserProfileType } from '../../../Interfaces/KeycloakTypes'
import { Grid } from '@material-ui/core'
import { ROUTES } from '../../../helpers/routes'
import { environment } from '../../../helpers/environment'
import { MaintenaceAlert } from '../MaintenaceAlert'
import { clearLocalStorage } from '../../../helpers/utils'
/* import HelpIcon from '@material-ui/icons/Help'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next' */

export const ProfileAppTop: React.FC = () => {
  const client = useApolloClient()
  const [userInfo, setUserInfo] = useState<UserProfileType>()
  const { keycloak } = useKeycloak()

  const logoutApp = () => {
    client.resetStore()
    keycloak?.logout({ redirectUri: environment.WEB_URL + ROUTES.home })
    clearLocalStorage()
  }

  useEffect(() => {
    if (!userInfo && keycloak?.authenticated) {
      keycloak
        ?.loadUserInfo()
        .then((u: UserProfileType) => {
          //console.log({ u })
          setUserInfo({
            ...u,
            username: u.name ?? u.email,
            locale: u.locale ?? 'en',
          })
        })
        .catch((e) => {
          console.error('er', e)
        })
    }
  }, [keycloak, userInfo])
  return (
    <>
      <AppTop>
        <MaintenaceAlert adminMessage={userInfo?.admin_message} />
        <AppTopUserInfo username={`${userInfo?.username}`} />
        <Grid item xs={1} />
        <LanguageMenu userLang={userInfo?.locale} />
        <CsButtonText id="logout" color="inherit" title="Logout" onClick={logoutApp} small>
          <ExitToAppIcon />
        </CsButtonText>
        {/* <CsButtonText color="inherit" title={t('actions.about')} onClick={aboutInfo} small>
          <HelpIcon />
        </CsButtonText> */}
      </AppTop>
    </>
  )
}
