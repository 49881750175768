import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import React from 'react'
import { environment } from '../../../helpers/environment'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
type ReleaseData = {
  appVersion: string
  notes: string[]
  fixes: string[]
}
const About: React.FC = () => {
  //const demoReleaseData = { appVersion: '0.1.72', notes: ['point 1', 'point 2'], fixes: ['point 1', 'point 2'] }
  const { appVersion, notes, fixes } = environment.RELEASE_NOTES
  const releaseInfo: ReleaseData = {
    appVersion: appVersion,
    notes: notes.split(','),
    fixes: fixes.split(','),
  }

  return (
    <>
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item xs md lg>
          <Typography variant="h6" color="primary">
            Client-version: {releaseInfo.appVersion}
          </Typography>
        </Grid>
        <Grid item xs md lg>
          <Typography variant="h6" color="primary">
            Release Notes
          </Typography>
        </Grid>
        <Grid item xs={12} md={12} lg={12} container spacing={2}>
          <Grid item xs={12} md={12} lg={12} container spacing={2}>
            <List
              subheader={
                <Typography variant="h6" gutterBottom>
                  Whats New
                </Typography>
              }
            >
              {releaseInfo.notes.map((point) => (
                <ListItem alignItems="flex-start" key={point}>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <Typography>{point}</Typography>
                  </ListItemText>
                  <Divider variant="fullWidth" />
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={12} md={12} lg={12} container spacing={2}>
            <List
              subheader={
                <Typography variant="h6" gutterBottom>
                  Fixes
                </Typography>
              }
            >
              {releaseInfo.fixes.map((point) => (
                <ListItem alignItems="flex-start" key={point}>
                  <ListItemIcon>
                    <FiberManualRecordIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText disableTypography>
                    <Typography>{point}</Typography>
                  </ListItemText>
                  <Divider variant="fullWidth" />
                </ListItem>
              ))}
            </List>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default About
