import { KeycloakProfile } from 'keycloak-js'

export interface UserProfileType extends KeycloakProfile {
  locale?: string
  preferred_username?: string
  preferred_org?: string
  admin_message?: string
  family_name?: string
  given_name?: string
  name?: string
}

export type PacketNameInput = {
  realmName: string
  packetId: string
  packetName: string
}

export type ActivationVariable = {
  userID: string
  displayName: string
  licenseString: string
}

export const initActivationParams: ActivationVariable = {
  userID: '',
  displayName: '',
  licenseString: '',
}

export type AdminMessageParams = {
  date: string
  fromTime: string
  toTime: string
  isUnderMaintenance: string
}
