import React from 'react'
import { outerPageContainer, mainPageContainer, innerPageContainer } from '../../../styles/shared'

type PageContentProps = {
  children?: React.ReactNode
}

export const PageContent: React.FC<PageContentProps> = ({ children }) => {
  return (
    <div style={outerPageContainer}>
      <div style={innerPageContainer}>
        <div style={mainPageContainer}>{children}</div>
      </div>
    </div>
  )
}
