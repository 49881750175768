import { GetUserProfileDocument } from '../../api/models'
import { useChangeEmailMutation } from '../../api/preferences.models'

import { environment } from '../helpers/environment'
type Resp = {
  confirmChange: (email: string) => Promise<void>
  updated?: boolean | null
  updating?: boolean
  error?: string
}
export const useUpdateEmail = (): Resp => {
  const [updateEmail, { data, loading, error }] = useChangeEmailMutation()
  const changeEmail = async (email: string) => {
    updateEmail({
      variables: { email: email, redirectUri: environment.WEB_URL },
      refetchQueries: [
        {
          query: GetUserProfileDocument,
        },
      ],
    })
  }
  return {
    confirmChange: changeEmail,
  }
}
