import { ROUTES } from './routes'

const checkForUndefinedVar = (variable: string | undefined) => {
  if (variable && variable === 'undefined') {
    return undefined
  }
  return variable
}

interface API {
  GQL_HTTP_URI: string
  GQL_WS_URI: string
  GQL_SCHEMA_URI: string
}

interface IAMAPI {
  GQL_HTTP_URI: string
  GQL_WS_URI: string
  GQL_SCHEMA_URI: string
}

interface DEMOSERVICEAPI {
  GQL_HTTP_URI: string
  GQL_WS_URI: string
  GQL_SCHEMA_URI: string
}

interface REDIRECTURI {
  redirectUri: string
}

export type ReleaseType = {
  appVersion: string
  notes: string
  fixes: string
}
export interface Environment {
  APP_NAME: string
  ADAPTER_SCRIPT: string
  APP_VERSION: string
  STAGE: string
  WEB_URL: string
  SENTRY_DSN: string
  LOGIN_URL: string
  LOGIN_REALM: string
  CLIENT_ID: string
  API: API
  IAMAPI: string
  ORGAPI: string
  DEMOSERVICEAPI: DEMOSERVICEAPI
  IAM: string
  LEAKAGE: string
  NETWORK: string
  LICENSEAPI: string
  LR_DEMO_SERVICE: string
  RELEASE_NOTES: ReleaseType
  redirect: REDIRECTURI
  USER_PREFERENCE_API: string
}

export const environment: Environment = {
  APP_NAME: process.env.REACT_APP_NAME || '',
  ADAPTER_SCRIPT: process.env.REACT_APP_ADAPTER || '',
  APP_VERSION: process.env.REACT_APP_VERSION || '',
  USER_PREFERENCE_API: process.env.REACT_APP_USER_PREFERENCE_API || '',
  STAGE: checkForUndefinedVar(process.env.REACT_APP_STAGE) || 'local',
  LOGIN_URL: process.env.REACT_APP_LOGIN_URL || '',
  LOGIN_REALM: process.env.REACT_APP_LOGIN_REALM || '',
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID || '',
  RELEASE_NOTES: {
    appVersion: process.env.REACT_APP_VERSION || '',
    notes: process.env.REACT_APP_NOTES || '',
    fixes: process.env.REACT_APP_FIXES || '',
  },
  WEB_URL: checkForUndefinedVar(process.env.REACT_APP_WEB_URL) || '',
  redirect: {
    redirectUri: checkForUndefinedVar(process.env.REACT_APP_WEB_URL) || '' + ROUTES.home,
  },
  SENTRY_DSN: checkForUndefinedVar(process.env.REACT_APP_SENTRY_DSN) || '',
  API: {
    GQL_HTTP_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_HTTP_URI) || '',
    GQL_WS_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_WS_URI) || '',
    GQL_SCHEMA_URI: checkForUndefinedVar(process.env.REACT_APP_API_GQL_SCHEMA_URI) || '',
  },
  IAMAPI: checkForUndefinedVar(process.env.REACT_APP_IAM_GQL_HTTP_URI) || '',
  ORGAPI: checkForUndefinedVar(process.env.REACT_APP_ORG_GQL_HTTP_URI) || '',
  DEMOSERVICEAPI: {
    GQL_HTTP_URI: checkForUndefinedVar(process.env.REACT_APP_DEMOSERVICE_GQL_HTTP_URI) || '',
    GQL_WS_URI: checkForUndefinedVar(process.env.REACT_APP_DEMOSERVICE_GQL_WS_URI) || '',
    GQL_SCHEMA_URI: checkForUndefinedVar(process.env.REACT_APP_DEMOSERVICE_GQL_SCHEMA_URI) || '',
  },
  IAM: process.env.REACT_APP_IAM || '',
  LEAKAGE: process.env.REACT_APP_LEAKAGE || '',
  NETWORK: process.env.REACT_APP_NETWORK || '',
  LICENSEAPI: process.env.REACT_APP_CL_GQL_HTTP_URI || '',
  LR_DEMO_SERVICE: process.env.REACT_APP_LR_DEMO_HTTP_URI || '',
}

//console.log('release', process.env.REACT_APP_RELEASE_NOTES)
