import React from 'react'
import { GetUserProfileDocument, useGetUserProfileQuery, useUpdateUserProfileOptinMutation } from '../../api/models'

export type NewsLetterRes = {
  updatePreference: (preference: boolean) => Promise<void>
  updatingPreference?: boolean
  updatingError?: string
  updatedPreference?: boolean
}
export const useUpdateNewsLetterPreference = (): NewsLetterRes => {
  const [preference, { data, loading, error }] = useUpdateUserProfileOptinMutation({
    refetchQueries: [
      {
        query: GetUserProfileDocument,
      },
    ],
  })

  const changePreference = async (prefer: boolean) => {
    try {
      await preference({
        variables: {
          userProfileOptinInput: { Newsletter: prefer },
        },
      })
    } catch (err) {
      console.error('error updating preference', err)
    }
  }
  return {
    updatePreference: changePreference,
    updatingError: error?.message,
    updatingPreference: loading,
    updatedPreference: data?.updateUserProfileOptin,
  }
}

export const useGetNewsLetterPreference = (): boolean | undefined => {
  const { data } = useGetUserProfileQuery()

  return React.useMemo(() => data?.getUserProfile.Newsletter, [data])
}
