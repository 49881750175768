import PolicyIcon from '@material-ui/icons/Policy'

import { SvgIcon } from '@material-ui/core'

//import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna'
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined'
import OpacityOutlinedIcon from '@material-ui/icons/OpacityOutlined'
//import AccountBoxIcon from '@material-ui/icons/AccountBox'
import { environment } from './environment'

type DrawerItems = {
  text: string
  link: string
  Icon: typeof SvgIcon
}[]

const drawerItems: DrawerItems = [
  {
    text: 'menu.home',
    link: '',
    Icon: HomeOutlinedIcon,
  },
  /* {
    text: 'MYINFO',
    link: 'personalinfo',
    Icon: AccountBoxIcon,
  }, */
  {
    text: 'menu.iam',
    link: environment.IAM,
    Icon: PolicyIcon,
  },
  {
    text: 'menu.leak-reporter',
    link: environment.LEAKAGE,
    Icon: OpacityOutlinedIcon,
  },
  /* {
    text: 'CS-NETWORK',
    link: 'cs',
    Icon: SettingsInputAntennaIcon,
  }, */
]
export const accessibleItems =
  environment.STAGE === 'neutral' ? drawerItems.filter((i) => !i.link.startsWith('http')) : drawerItems

export default drawerItems
