import { useKeycloak } from '@react-keycloak/web'
import React from 'react'
import { StateOfUser, useGetAccessibleUsersQuery, useModifyRootAdminMutation } from '../../api/iam.models'

import { UserProfileType } from '../Interfaces/KeycloakTypes'

type UsersList = {
  superAdmins: string[]
  fetchingAdmins?: boolean
}

export const useGetUsers = (realmName: string): UsersList => {
  const { keycloak } = useKeycloak()
  const userProfile: UserProfileType | undefined = keycloak.userInfo

  const { data, loading, error } = useGetAccessibleUsersQuery({
    variables: {
      realmName: realmName,
      state: StateOfUser.Active,
      username: userProfile?.email ?? '',
    },
    skip: !realmName || !userProfile?.email,
  })

  const users = React.useMemo(() => {
    if (data?.getAccessibleUsers && !loading) {
      const tempUsers = data.getAccessibleUsers.filter(
        (u) => u?.userInfo.registered && u.enabled && u.userInfo.email !== userProfile?.email,
      )
      const selectUserOptions = tempUsers.some((u) => u?.userInfo.email || u?.userInfo.username)
        ? tempUsers.map((u) => u?.userInfo.email as string)
        : []
      return selectUserOptions
    }
  }, [data?.getAccessibleUsers, loading, userProfile?.email])
  if (error) {
    console.error(error.message)
  }
  return {
    superAdmins: users ?? [],
    fetchingAdmins: loading,
  }
}

export type ChangeAdminResult = {
  changingAdmin?: boolean
  changeSuperAdmin: (username: string) => Promise<boolean | undefined>
}

export const useUpdateAdmin = (realmName: string): ChangeAdminResult => {
  const [modifyAdmin, modifiedResult] = useModifyRootAdminMutation()
  const confirmAdmin = async (username: string) => {
    try {
      if (!realmName || !username) {
        return
      }
      const queryRes = await modifyAdmin({
        variables: {
          realmName: realmName,
          username: username,
          keepLicenses: true,
        },
      })
      return !!queryRes.data?.setRootAdmin
    } catch (err) {
      console.error(err)
    }
  }
  return {
    changingAdmin: modifiedResult.loading,
    changeSuperAdmin: confirmAdmin,
  }
}
