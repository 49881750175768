import {
  ApolloClient,
  DefaultOptions,
  HttpLink,
  ApolloLink,
  from,
  NormalizedCacheObject,
  InMemoryCache,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import * as Sentry from '@sentry/browser'
import { useKeycloak } from '@react-keycloak/web'
import { getApolloURI } from './webHelper/webDataService'

const updateTokenError = (reason?: unknown) => {
  try {
    Sentry.captureException(new Error('Error updating accessToken'), (scope) => {
      scope.setTransactionName('could not fetch new Access token')
      scope.setExtra('session expired', reason)
      //console.log('session expired please login again', reason)
      return scope
    })
  } catch (e) {
    console.error('failed to send event to sentry', e)
  }
}

export const useIamApolloClient = (): ApolloClient<NormalizedCacheObject> => {
  const cache = new InMemoryCache()
  const { keycloak } = useKeycloak()
  const tkn = async () => {
    if (keycloak.authenticated && keycloak?.isTokenExpired(5)) {
      try {
        const refreshed = await keycloak?.updateToken(0)
        if (refreshed) {
          return keycloak.token
        }
      } catch (err: unknown) {
        keycloak.clearToken()
        updateTokenError(err)
      }
    }
    return keycloak?.token
  }

  const httpLink = new HttpLink({
    uri: (operation) => {
      return getApolloURI(operation.operationName)
    },
    credentials: 'same-origin',
  })
  const authLink: ApolloLink = setContext(async (_, { headers }) => {
    const _accessTkn = await tkn()
    if (keycloak.authenticated) {
      return {
        headers: {
          ...headers,
          authorization: _accessTkn ? `Bearer ${_accessTkn}` : '',
        },
      }
    }
  })

  const errorLink: ApolloLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      return forward(operation)
    }
    if (networkError) {
      return forward(operation)
    }
  })

  const defaultOptions: DefaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  return new ApolloClient({
    link: from([errorLink, authLink, httpLink]),
    queryDeduplication: false,
    cache,
    defaultOptions,
  })
}
