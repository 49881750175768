export const countries = [
  { country: 'Afg', value: 'Afghanistan' },
  { country: 'Alb', value: 'Albania' },
  { country: 'Dza', value: 'Algeria' },
  { country: 'And', value: 'Andorra' },
  { country: 'Ago', value: 'Angola' },
  { country: 'Aia', value: 'Anguilla' },
  { country: 'Atg', value: 'Antigua and barbuda' },
  { country: 'Arg', value: 'Argentina' },
  { country: 'Arm', value: 'Armenia' },
  { country: 'Abw', value: 'Aruba' },
  { country: 'Aus', value: 'Australia' },
  { country: 'Aze', value: 'Azerbaidjan' },
  { country: 'Bhs', value: 'Bahamas' },
  { country: 'Bhr', value: 'Bahrain' },
  { country: 'Bgd', value: 'Bangladesh' },
  { country: 'Brb', value: 'Barbados' },
  { country: 'Blr', value: 'Belarus' },
  { country: 'Bel', value: 'Belgium' },
  { country: 'Blz', value: 'Belize' },
  { country: 'Ben', value: 'Benin' },
  { country: 'Bmu', value: 'Bermuda' },
  { country: 'Btn', value: 'Bhutan' },
  { country: 'Bol', value: 'Bolivia' },
  { country: 'Bih', value: 'Bosnia-herzegovina' },
  { country: 'Bwa', value: 'Botsuana' },
  { country: 'Bwa', value: 'Botswana' },
  { country: 'Bvt', value: 'Bouvet' },
  { country: 'Bra', value: 'Brazil' },
  { country: 'Iot', value: 'British indian' },
  { country: 'Brn', value: 'Brunei' },
  { country: 'Bgr', value: 'Bulgaria' },
  { country: 'Bfa', value: 'Burkina faso' },
  { country: 'Bdi', value: 'Burundi' },
  { country: 'Khm', value: 'Cambodia' },
  { country: 'Cmr', value: 'Cameroon' },
  { country: 'Can', value: 'Canada' },
  { country: 'Cpv', value: 'Cape verde' },
  { country: 'Cym', value: 'Cayman islands' },
  { country: 'Caf', value: 'Central african republic' },
  { country: 'Tcd', value: 'Chad' },
  { country: 'Chl', value: 'Chile' },
  { country: 'Chn', value: 'China' },
  { country: 'Cxr', value: 'Christmas island' },
  { country: 'Cck', value: 'Cocos' },
  { country: 'Col', value: 'Colombia' },
  { country: 'Com', value: 'Comoros' },
  { country: 'Cod', value: 'Congo' },
  { country: 'Cok', value: 'Cook islands' },
  { country: 'Cri', value: 'Costa rica' },
  { country: 'Hrv', value: 'Croatia' },
  { country: 'Cub', value: 'Cuba' },
  { country: 'Cyp', value: 'Cyprus' },
  { country: 'Cze', value: 'Czech republic' },
  { country: 'Dnk', value: 'Denmark' },
  { country: 'Dji', value: 'Djibouti' },
  { country: 'Dma', value: 'Dominica' },
  { country: 'Dom', value: 'Dominican republic' },
  { country: 'Ecu', value: 'Ecuador' },
  { country: 'Egy', value: 'Egypt' },
  { country: 'Slv', value: 'El salvador' },
  { country: 'Gnq', value: 'Equatorial guinea' },
  { country: 'Est', value: 'Estonia' },
  { country: 'Eth', value: 'Ethiopia' },
  { country: 'Flk', value: 'Falkland islands' },
  { country: 'Fro', value: 'Faroe islands' },
  { country: 'Fji', value: 'Fiji' },
  { country: 'Fin', value: 'Finland' },
  { country: 'Fra', value: 'France' },
  { country: 'Guf', value: 'French guyana' },
  { country: 'Atf', value: 'French southern territories' },
  { country: 'Gab', value: 'Gabon' },
  { country: 'Gmb', value: 'Gambia' },
  { country: 'Geo', value: 'Georgia' },
  { country: 'Deu', value: 'Germany' },
  { country: 'Gha', value: 'Ghana' },
  { country: 'Gib', value: 'Gibraltar' },
  { country: 'Grc', value: 'Greece' },
  { country: 'Grl', value: 'Greenland' },
  { country: 'Grd', value: 'Grenada' },
  { country: 'Glp', value: 'Guadeloupe (french)' },
  { country: 'Gum', value: 'Guam (usa)' },
  { country: 'Gtm', value: 'Guatemala' },
  { country: 'Gin', value: 'Guinea' },
  { country: 'Gnb', value: 'Guinea bissau' },
  { country: 'Guy', value: 'Guyana' },
  { country: 'Hti', value: 'Haiti' },
  { country: 'Hmd', value: 'Heard and mcdonald islands' },
  { country: 'Hnd', value: 'Honduras' },
  { country: 'Hkg', value: 'Hong kong' },
  { country: 'Hun', value: 'Hungary' },
  { country: 'Isl', value: 'Iceland' },
  { country: 'Ind', value: 'India' },
  { country: 'Idn', value: 'Indonesia' },
  { country: 'Irn', value: 'Iran' },
  { country: 'Irq', value: 'Iraq' },
  { country: 'Irl', value: 'Ireland' },
  { country: 'Isr', value: 'Israel' },
  { country: 'Ita', value: 'Italy' },
  { country: 'Civ', value: "Ivory coast (cote d'ivoire)" },
  { country: 'Jam', value: 'Jamaica' },
  { country: 'Jpn', value: 'Japan' },
  { country: 'Jor', value: 'Jordan' },
  { country: 'Kaz', value: 'Kazakhstan' },
  { country: 'Ken', value: 'Kenya' },
  { country: 'Kir', value: 'Kiribati' },
  { country: 'Kwt', value: 'Kuwait' },
  { country: 'Kgz', value: 'Kyrgyzstan' },
  { country: 'Lao', value: 'Laos' },
  { country: 'Lva', value: 'Latvia' },
  { country: 'Lbn', value: 'Lebanon' },
  { country: 'Lso', value: 'Lesotho' },
  { country: 'Lbr', value: 'Liberia' },
  { country: 'Lby', value: 'Libya' },
  { country: 'Lie', value: 'Liechtenstein' },
  { country: 'Ltu', value: 'Lithuania' },
  { country: 'Lux', value: 'Luxembourg' },
  { country: 'Mac', value: 'Macau' },
  { country: 'Mkd', value: 'Macedonia' },
  { country: 'Mdg', value: 'Madagascar' },
  { country: 'Mwi', value: 'Malawi' },
  { country: 'Mys', value: 'Malaysia' },
  { country: 'Mdv', value: 'Maldives' },
  { country: 'Mli', value: 'Mali' },
  { country: 'Mlt', value: 'Malta' },
  { country: 'Mar', value: 'Marocco' },
  { country: 'Mhl', value: 'Marshall islands' },
  { country: 'Mtq', value: 'Martinique' },
  { country: 'Mrt', value: 'Mauritania' },
  { country: 'Mus', value: 'Mauritius' },
  { country: 'Myt', value: 'Mayotte' },
  { country: 'Mex', value: 'Mexico' },
  { country: 'Fsm', value: 'Micronesia' },
  { country: 'Mda', value: 'Moldavia' },
  { country: 'Mng', value: 'Mongolia' },
  { country: 'Mne', value: 'Montenegro' },
  { country: 'Msr', value: 'Montserrat' },
  { country: 'Moz', value: 'Mozambique' },
  { country: 'Mmr', value: 'Myanmar' },
  { country: 'Nam', value: 'Namibia' },
  { country: 'Nru', value: 'Nauru' },
  { country: 'Npl', value: 'Nepal' },
  { country: 'Nld', value: 'Netherlands' },
  { country: 'Ant', value: 'Netherlands antilles' },
  { country: 'Ncl', value: 'New caledonia (french)' },
  { country: 'Nzl', value: 'New zealand' },
  { country: 'Nic', value: 'Nicaragua' },
  { country: 'Ner', value: 'Niger' },
  { country: 'Nga', value: 'Nigeria' },
  { country: 'Niu', value: 'Niue' },
  { country: 'Nfk', value: 'Norfolk' },
  { country: 'Prk', value: 'North korea' },
  { country: 'Mnp', value: 'Northern mariana islands' },
  { country: 'Nor', value: 'Norway' },
  { country: 'Omn', value: 'Oman' },
  { country: 'Aut', value: '\u00d6sterreich' },
  { country: 'Pak', value: 'Pakistan' },
  { country: 'Pse', value: 'Pal\u00c4stina ' },
  { country: 'Plw', value: 'Palau' },
  { country: 'Pan', value: 'Panama' },
  { country: 'Png', value: 'Papua new guinea' },
  { country: 'Pry', value: 'Paraguay' },
  { country: 'Per', value: 'Peru' },
  { country: 'Phl', value: 'Philippines' },
  { country: 'Pcn', value: 'Pitcairn' },
  { country: 'Pol', value: 'Poland' },
  { country: 'Pyf', value: 'Polynesia (french)' },
  { country: 'Prt', value: 'Portugal' },
  { country: 'Pri', value: 'Puerto rico' },
  { country: 'Qat', value: 'Qatar' },
  { country: 'Kor', value: 'Republik korea' },
  { country: 'Reu', value: 'Reunion (french)' },
  { country: 'Rom', value: 'Romania' },
  { country: 'Rus', value: 'Russian federation' },
  { country: 'Rwa', value: 'Rwanda' },
  { country: 'Sgs', value: 'S. georgia & s. sandwich isls.' },
  { country: 'Shn', value: 'Saint helena' },
  { country: 'Kna', value: 'Saint kitts & nevis anguilla' },
  { country: 'Lca', value: 'Saint lucia' },
  { country: 'Spm', value: 'Saint pierre and miquelon' },
  { country: 'Stp', value: 'Saint tome and principe' },
  { country: 'Vct', value: 'Saint vincent & grenadines' },
  { country: 'Wsm', value: 'Samoa' },
  { country: 'Smr', value: 'San marino' },
  { country: 'Sau', value: 'Saudi arabia' },
  { country: 'Che', value: 'Switzerland ' },
  { country: 'Sen', value: 'Senegal' },
  { country: 'Srb', value: 'Serbia' },
  { country: 'Rsd', value: 'Serbien und kosovo' },
  { country: 'Yug', value: 'Serbien und montenegro (inaktiv)' },
  { country: 'Syc', value: 'Seychelles' },
  { country: 'Sle', value: 'Sierra leone' },
  { country: 'Sgp', value: 'Singapore' },
  { country: 'Svk', value: 'Slovak republic' },
  { country: 'Svn', value: 'Slovenia' },
  { country: 'Slb', value: 'Solomon' },
  { country: 'Som', value: 'Somalia' },
  { country: 'Zaf', value: 'South africa' },
  { country: 'Kor', value: 'South korea' },
  { country: 'Esp', value: 'Spain' },
  { country: 'Lka', value: 'Sri lanka' },
  { country: 'Eri', value: 'State of eritrea' },
  { country: 'Sdn', value: 'Sudan' },
  { country: 'Sur', value: 'Suriname' },
  { country: 'Swz', value: 'Swaziland' },
  { country: 'Swe', value: 'Sweden' },
  { country: 'Syr', value: 'Syria' },
  { country: 'Tjk', value: 'Tadjikistan' },
  { country: 'Twn', value: 'Taiwan' },
  { country: 'Tza', value: 'Tanzania' },
  { country: 'Tha', value: 'Thailand' },
  { country: 'Tgo', value: 'Togo' },
  { country: 'Tkl', value: 'Tokelau' },
  { country: 'Ton', value: 'Tonga' },
  { country: 'Tto', value: 'Trinidad and tobago' },
  { country: 'Tun', value: 'Tunisia' },
  { country: 'Tur', value: 'Turkey' },
  { country: 'Tkm', value: 'Turkmenistan' },
  { country: 'Tca', value: 'Turks and caicos islands' },
  { country: 'Tuv', value: 'Tuvalu' },
  { country: 'Uga', value: 'Uganda' },
  { country: 'Ukr', value: 'Ukraine' },
  { country: 'Are', value: 'United arab emirates' },
  { country: 'Gbr', value: 'United kingdom' },
  { country: 'Usa', value: 'United states of america' },
  { country: 'Ury', value: 'Uruguay' },
  { country: 'Uzb', value: 'Uzbekistan' },
  { country: 'Vut', value: 'Vanuatu' },
  { country: 'Vat', value: 'Vatican city state' },
  { country: 'Ven', value: 'Venezuela' },
  { country: 'Vnm', value: 'Vietnam' },
  { country: 'Vgb', value: 'Virgin islands (british)' },
  { country: 'Vir', value: 'Virgin islands (usa)' },
  { country: 'Wlf', value: 'Wallis and futuna islands' },
  { country: 'Yem', value: 'Yemen' },
  { country: 'Zmb', value: 'Zambia' },
  { country: 'Zwe', value: 'Zimbabwe' },
]
