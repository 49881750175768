import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type CloudLicenseInfo = {
  __typename?: 'CloudLicenseInfo';
  /** organizations */
  cloudLicenseOrganizations: Array<Maybe<CloudLicenseOrganization>>;
};

export type CloudLicenseOrganization = {
  __typename?: 'CloudLicenseOrganization';
  /** id of the realm */
  realmId: Scalars['String'];
  /** displayname of the organization */
  displayName: Scalars['String'];
  /** cloud users */
  cloudLicenseUsers: Array<Maybe<CloudLicenseUser>>;
};

export type CloudLicenseUser = {
  __typename?: 'CloudLicenseUser';
  /** email address of the user */
  email: Scalars['String'];
  orgUserId: Scalars['String'];
  isRootAdmin: Scalars['Boolean'];
};

export enum LicenseUser {
  /** regular user */
  Regular = 'regular',
  /** cs user - no license required */
  Csuser = 'csuser'
}

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * register a user with the given email and returns the user Id. The user still
   * has to register normally but will then get this id. (only for development purposes)
   */
  registerUserId: Scalars['String'];
  /** UserID is the id of a registered user which becomes the root admin. If not user ID from the access token is used. */
  activateOrganization?: Maybe<Scalars['String']>;
  /**
   * activate a license packet for an exisiting organization. The user with the given email will be created as local admin (but with
   * no access to existing companies/projects). RealmId must be id of the realm for which the license packet was created. Packetname
   * can be arbitrary string. Licenses can be marked as reserved so that they can not be assigned freely - should be false for now.
   */
  activateLicensePacket?: Maybe<Scalars['String']>;
  /**
   * Update organization / cloud license (usually used to update a trial license). Note the invoker
   * must be the root admin of the organization. That is each user can only update the trial license
   * he has created.
   */
  updateOrganizationLicense?: Maybe<Scalars['String']>;
  /** change the organization (realm) name shown (does not correspond to the keycloak realmname) */
  changeDisplayName?: Maybe<Scalars['Boolean']>;
  /** deactivate organization - all data will be removed */
  deactivateOrganization?: Maybe<Scalars['Boolean']>;
  /** update Packet Name. Packet Name must be unique per organization. */
  changePacketName?: Maybe<Scalars['String']>;
};


export type MutationRegisterUserIdArgs = {
  email: Scalars['String'];
};


export type MutationActivateOrganizationArgs = {
  licenseString: Scalars['String'];
  displayName: Scalars['String'];
  userID?: Maybe<Scalars['String']>;
};


export type MutationActivateLicensePacketArgs = {
  licenseString: Scalars['String'];
  realmId: Scalars['String'];
  packetName?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  reserved: Scalars['Boolean'];
};


export type MutationUpdateOrganizationLicenseArgs = {
  licenseString: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationChangeDisplayNameArgs = {
  realmName: Scalars['String'];
  displayName: Scalars['String'];
};


export type MutationDeactivateOrganizationArgs = {
  licenseString: Scalars['String'];
  realmName: Scalars['String'];
};


export type MutationChangePacketNameArgs = {
  realmName: Scalars['String'];
  packetId: Scalars['String'];
  packetName: Scalars['String'];
};

export type OrganizationAccess = {
  __typename?: 'OrganizationAccess';
  /** id of the organization */
  organizationID: Scalars['String'];
  /** name of the realm - name used by iam */
  organizationName: Scalars['String'];
  /** name of the organization which should be displayed to the user - instead of realmName */
  displayName: Scalars['String'];
  /** username in the realm */
  username: Scalars['String'];
  /** list of roles the user has in this realm */
  appRoles: Array<UpAppRole>;
  /** licenses assigned to the user */
  licenses: Array<SingleUserLicense>;
};

export type Query = {
  __typename?: 'Query';
  /** get the organizations to which the user has access */
  getOrganizationAccess: Array<OrganizationAccess>;
  /**
   * returns true if the invoker is the root admin (false otherwise). Only the root
   * admin should be allowed to clear database.
   */
  isRootAdmin: Scalars['Boolean'];
  isRootAdminEx: Array<Maybe<Scalars['Boolean']>>;
  getCloudLicenseInfo: CloudLicenseInfo;
};


export type QueryIsRootAdminArgs = {
  realmName?: Maybe<Scalars['String']>;
};


export type QueryIsRootAdminExArgs = {
  realmName: Array<Maybe<Scalars['String']>>;
};


export type QueryGetCloudLicenseInfoArgs = {
  organizationID?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** license assigned to a user */
export type SingleUserLicense = {
  __typename?: 'SingleUserLicense';
  /** id of the license packet to which this license belongs */
  licensePacketId: Scalars['String'];
  /** id of the group (application), e.g. leak-reporter */
  licenseGroupID: Scalars['String'];
  /** id type of the license e.g. user or guest */
  licenseTypeID: Scalars['String'];
  /** id of the license */
  licenseID: Scalars['String'];
  /** date until the license is valid */
  expirationDate: Scalars['String'];
  /** if null or regular a license of the cloud licenses. csuser is a free license */
  regularLicense?: Maybe<LicenseUser>;
};

/** App specific user roles */
export type UpAppRole = {
  __typename?: 'UPAppRole';
  /** id of the application */
  appID: Scalars['String'];
  /** name of the app to which the role list corresponds */
  appName: Scalars['String'];
  /** roles granted to the user for the given app */
  roles: Array<UpAppRoleRole>;
};

export type UpAppRoleRole = {
  __typename?: 'UPAppRoleRole';
  /** id of the role */
  roleID: Scalars['String'];
  /** name of the role */
  roleName: Scalars['String'];
  /** key of the role, null for individual roles */
  displayNameKey?: Maybe<Scalars['String']>;
};

export type ActivateOrganizationMutationVariables = Exact<{
  licenseString: Scalars['String'];
  displayName: Scalars['String'];
  userID: Scalars['String'];
}>;


export type ActivateOrganizationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateOrganization'>
);

export type UpdateTrialLicenseMutationVariables = Exact<{
  licenseString: Scalars['String'];
  realmName: Scalars['String'];
}>;


export type UpdateTrialLicenseMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateOrganizationLicense'>
);

export type ChangeOrganizationNameMutationVariables = Exact<{
  realmName: Scalars['String'];
  displayName: Scalars['String'];
}>;


export type ChangeOrganizationNameMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeDisplayName'>
);

export type ModifyPacketNameMutationVariables = Exact<{
  realmName: Scalars['String'];
  packetId: Scalars['String'];
  packetName: Scalars['String'];
}>;


export type ModifyPacketNameMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePacketName'>
);

export type ActivateLicensePacketMutationVariables = Exact<{
  licenseString: Scalars['String'];
  realmId: Scalars['String'];
  packetName: Scalars['String'];
  userID: Scalars['String'];
  reserved: Scalars['Boolean'];
}>;


export type ActivateLicensePacketMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activateLicensePacket'>
);

export type HasRootPermissionsQueryVariables = Exact<{
  realmName: Scalars['String'];
}>;


export type HasRootPermissionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isRootAdmin'>
);

export type HasAllRootPermissionsQueryVariables = Exact<{
  realmName: Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>;
}>;


export type HasAllRootPermissionsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isRootAdminEx'>
);


export const ActivateOrganizationDocument = gql`
    mutation ActivateOrganization($licenseString: String!, $displayName: String!, $userID: String!) {
  activateOrganization(
    licenseString: $licenseString
    displayName: $displayName
    userID: $userID
  )
}
    `;
export type ActivateOrganizationMutationFn = Apollo.MutationFunction<ActivateOrganizationMutation, ActivateOrganizationMutationVariables>;

/**
 * __useActivateOrganizationMutation__
 *
 * To run a mutation, you first call `useActivateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateOrganizationMutation, { data, loading, error }] = useActivateOrganizationMutation({
 *   variables: {
 *      licenseString: // value for 'licenseString'
 *      displayName: // value for 'displayName'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useActivateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<ActivateOrganizationMutation, ActivateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateOrganizationMutation, ActivateOrganizationMutationVariables>(ActivateOrganizationDocument, options);
      }
export type ActivateOrganizationMutationHookResult = ReturnType<typeof useActivateOrganizationMutation>;
export type ActivateOrganizationMutationResult = Apollo.MutationResult<ActivateOrganizationMutation>;
export type ActivateOrganizationMutationOptions = Apollo.BaseMutationOptions<ActivateOrganizationMutation, ActivateOrganizationMutationVariables>;
export const UpdateTrialLicenseDocument = gql`
    mutation UpdateTrialLicense($licenseString: String!, $realmName: String!) {
  updateOrganizationLicense(licenseString: $licenseString, realmName: $realmName)
}
    `;
export type UpdateTrialLicenseMutationFn = Apollo.MutationFunction<UpdateTrialLicenseMutation, UpdateTrialLicenseMutationVariables>;

/**
 * __useUpdateTrialLicenseMutation__
 *
 * To run a mutation, you first call `useUpdateTrialLicenseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrialLicenseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrialLicenseMutation, { data, loading, error }] = useUpdateTrialLicenseMutation({
 *   variables: {
 *      licenseString: // value for 'licenseString'
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useUpdateTrialLicenseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrialLicenseMutation, UpdateTrialLicenseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrialLicenseMutation, UpdateTrialLicenseMutationVariables>(UpdateTrialLicenseDocument, options);
      }
export type UpdateTrialLicenseMutationHookResult = ReturnType<typeof useUpdateTrialLicenseMutation>;
export type UpdateTrialLicenseMutationResult = Apollo.MutationResult<UpdateTrialLicenseMutation>;
export type UpdateTrialLicenseMutationOptions = Apollo.BaseMutationOptions<UpdateTrialLicenseMutation, UpdateTrialLicenseMutationVariables>;
export const ChangeOrganizationNameDocument = gql`
    mutation ChangeOrganizationName($realmName: String!, $displayName: String!) {
  changeDisplayName(realmName: $realmName, displayName: $displayName)
}
    `;
export type ChangeOrganizationNameMutationFn = Apollo.MutationFunction<ChangeOrganizationNameMutation, ChangeOrganizationNameMutationVariables>;

/**
 * __useChangeOrganizationNameMutation__
 *
 * To run a mutation, you first call `useChangeOrganizationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeOrganizationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeOrganizationNameMutation, { data, loading, error }] = useChangeOrganizationNameMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useChangeOrganizationNameMutation(baseOptions?: Apollo.MutationHookOptions<ChangeOrganizationNameMutation, ChangeOrganizationNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeOrganizationNameMutation, ChangeOrganizationNameMutationVariables>(ChangeOrganizationNameDocument, options);
      }
export type ChangeOrganizationNameMutationHookResult = ReturnType<typeof useChangeOrganizationNameMutation>;
export type ChangeOrganizationNameMutationResult = Apollo.MutationResult<ChangeOrganizationNameMutation>;
export type ChangeOrganizationNameMutationOptions = Apollo.BaseMutationOptions<ChangeOrganizationNameMutation, ChangeOrganizationNameMutationVariables>;
export const ModifyPacketNameDocument = gql`
    mutation ModifyPacketName($realmName: String!, $packetId: String!, $packetName: String!) {
  changePacketName(
    realmName: $realmName
    packetId: $packetId
    packetName: $packetName
  )
}
    `;
export type ModifyPacketNameMutationFn = Apollo.MutationFunction<ModifyPacketNameMutation, ModifyPacketNameMutationVariables>;

/**
 * __useModifyPacketNameMutation__
 *
 * To run a mutation, you first call `useModifyPacketNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyPacketNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyPacketNameMutation, { data, loading, error }] = useModifyPacketNameMutation({
 *   variables: {
 *      realmName: // value for 'realmName'
 *      packetId: // value for 'packetId'
 *      packetName: // value for 'packetName'
 *   },
 * });
 */
export function useModifyPacketNameMutation(baseOptions?: Apollo.MutationHookOptions<ModifyPacketNameMutation, ModifyPacketNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ModifyPacketNameMutation, ModifyPacketNameMutationVariables>(ModifyPacketNameDocument, options);
      }
export type ModifyPacketNameMutationHookResult = ReturnType<typeof useModifyPacketNameMutation>;
export type ModifyPacketNameMutationResult = Apollo.MutationResult<ModifyPacketNameMutation>;
export type ModifyPacketNameMutationOptions = Apollo.BaseMutationOptions<ModifyPacketNameMutation, ModifyPacketNameMutationVariables>;
export const ActivateLicensePacketDocument = gql`
    mutation ActivateLicensePacket($licenseString: String!, $realmId: String!, $packetName: String!, $userID: String!, $reserved: Boolean!) {
  activateLicensePacket(
    licenseString: $licenseString
    realmId: $realmId
    packetName: $packetName
    userID: $userID
    reserved: $reserved
  )
}
    `;
export type ActivateLicensePacketMutationFn = Apollo.MutationFunction<ActivateLicensePacketMutation, ActivateLicensePacketMutationVariables>;

/**
 * __useActivateLicensePacketMutation__
 *
 * To run a mutation, you first call `useActivateLicensePacketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateLicensePacketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateLicensePacketMutation, { data, loading, error }] = useActivateLicensePacketMutation({
 *   variables: {
 *      licenseString: // value for 'licenseString'
 *      realmId: // value for 'realmId'
 *      packetName: // value for 'packetName'
 *      userID: // value for 'userID'
 *      reserved: // value for 'reserved'
 *   },
 * });
 */
export function useActivateLicensePacketMutation(baseOptions?: Apollo.MutationHookOptions<ActivateLicensePacketMutation, ActivateLicensePacketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateLicensePacketMutation, ActivateLicensePacketMutationVariables>(ActivateLicensePacketDocument, options);
      }
export type ActivateLicensePacketMutationHookResult = ReturnType<typeof useActivateLicensePacketMutation>;
export type ActivateLicensePacketMutationResult = Apollo.MutationResult<ActivateLicensePacketMutation>;
export type ActivateLicensePacketMutationOptions = Apollo.BaseMutationOptions<ActivateLicensePacketMutation, ActivateLicensePacketMutationVariables>;
export const HasRootPermissionsDocument = gql`
    query hasRootPermissions($realmName: String!) {
  isRootAdmin(realmName: $realmName)
}
    `;

/**
 * __useHasRootPermissionsQuery__
 *
 * To run a query within a React component, call `useHasRootPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasRootPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasRootPermissionsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useHasRootPermissionsQuery(baseOptions: Apollo.QueryHookOptions<HasRootPermissionsQuery, HasRootPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasRootPermissionsQuery, HasRootPermissionsQueryVariables>(HasRootPermissionsDocument, options);
      }
export function useHasRootPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasRootPermissionsQuery, HasRootPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasRootPermissionsQuery, HasRootPermissionsQueryVariables>(HasRootPermissionsDocument, options);
        }
export type HasRootPermissionsQueryHookResult = ReturnType<typeof useHasRootPermissionsQuery>;
export type HasRootPermissionsLazyQueryHookResult = ReturnType<typeof useHasRootPermissionsLazyQuery>;
export type HasRootPermissionsQueryResult = Apollo.QueryResult<HasRootPermissionsQuery, HasRootPermissionsQueryVariables>;
export const HasAllRootPermissionsDocument = gql`
    query hasAllRootPermissions($realmName: [String]!) {
  isRootAdminEx(realmName: $realmName)
}
    `;

/**
 * __useHasAllRootPermissionsQuery__
 *
 * To run a query within a React component, call `useHasAllRootPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasAllRootPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasAllRootPermissionsQuery({
 *   variables: {
 *      realmName: // value for 'realmName'
 *   },
 * });
 */
export function useHasAllRootPermissionsQuery(baseOptions: Apollo.QueryHookOptions<HasAllRootPermissionsQuery, HasAllRootPermissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasAllRootPermissionsQuery, HasAllRootPermissionsQueryVariables>(HasAllRootPermissionsDocument, options);
      }
export function useHasAllRootPermissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasAllRootPermissionsQuery, HasAllRootPermissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasAllRootPermissionsQuery, HasAllRootPermissionsQueryVariables>(HasAllRootPermissionsDocument, options);
        }
export type HasAllRootPermissionsQueryHookResult = ReturnType<typeof useHasAllRootPermissionsQuery>;
export type HasAllRootPermissionsLazyQueryHookResult = ReturnType<typeof useHasAllRootPermissionsLazyQuery>;
export type HasAllRootPermissionsQueryResult = Apollo.QueryResult<HasAllRootPermissionsQuery, HasAllRootPermissionsQueryVariables>;