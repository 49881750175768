import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type FullUserProfile = {
  __typename?: 'FullUserProfile';
  userId: Scalars['String'];
  CreationDate: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Data: Array<Maybe<UserProfileData>>;
  Image: Array<Maybe<UserProfileImage>>;
  Email: Array<Maybe<UserProfileEmail>>;
  OptIn: Array<Maybe<UserProfileOptIn>>;
};

export type FullUserProfileDataInput = {
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type FullUserProfileEmailInput = {
  Email: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type FullUserProfileImageInput = {
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type FullUserProfileInput = {
  userId: Scalars['String'];
  CreationDate: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Data: Array<Maybe<FullUserProfileDataInput>>;
  Image: Array<Maybe<FullUserProfileImageInput>>;
  Email: Array<Maybe<FullUserProfileEmailInput>>;
  OptIn: Array<Maybe<FullUserProfileOptInInput>>;
};

export type FullUserProfileOptInInput = {
  Newsletter: Scalars['Boolean'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createUserProfileEmail: Scalars['Boolean'];
  updateUserProfileEmailOptin: Scalars['Boolean'];
  deleteUserProfileEmailFull: Scalars['Boolean'];
  createUserProfile: Scalars['Boolean'];
  updateUserProfileData: Scalars['Boolean'];
  updateUserProfileOptin: Scalars['Boolean'];
  updateUserProfileImage: Scalars['Boolean'];
  changeUserProfileEmail: Scalars['Boolean'];
  deleteUserProfile: Scalars['Boolean'];
  deleteUserProfileFull: Scalars['Boolean'];
  userProfileImport: Scalars['Boolean'];
};


export type MutationCreateUserProfileEmailArgs = {
  userProfile: UserProfileEmailInput;
};


export type MutationUpdateUserProfileEmailOptinArgs = {
  userProfileOptin: UserProfileEmailOptinInput;
};


export type MutationDeleteUserProfileEmailFullArgs = {
  email: Scalars['String'];
};


export type MutationCreateUserProfileArgs = {
  userProfile: UserProfileInput;
};


export type MutationUpdateUserProfileDataArgs = {
  userProfileData: UserProfileDataInput;
};


export type MutationUpdateUserProfileOptinArgs = {
  userProfileOptin: UserProfileOptinInput;
};


export type MutationUpdateUserProfileImageArgs = {
  userProfileImage: UserProfileIdImageInput;
};


export type MutationUserProfileImportArgs = {
  userProfiles: Array<Maybe<FullUserProfileInput>>;
};

export type Query = {
  __typename?: 'Query';
  /** get user profile version information */
  getVersion: UserProfileVersionInformation;
  getUserProfile: UserProfile;
  getUserInfo: Array<Maybe<UserInfo>>;
  getUserProfiles?: Maybe<Array<UserProfileLight>>;
  getUserProfilesByEmailID?: Maybe<Array<Maybe<UserProfileLight>>>;
  userProfileExport: Array<Maybe<FullUserProfile>>;
  /**
   * searches for the given string in all fields of the user data. If there
   * is a match in the row the corresponding id of the user is returned.
   */
  searchUserData: Array<Scalars['String']>;
};


export type QueryGetUserProfilesArgs = {
  userIds?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type QueryGetUserProfilesByEmailIdArgs = {
  emailIDs?: Maybe<Array<Scalars['String']>>;
};


export type QuerySearchUserDataArgs = {
  searchString: Scalars['String'];
};

export type UserInfo = {
  __typename?: 'UserInfo';
  company: Scalars['String'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  UserID: Scalars['String'];
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Image?: Maybe<UserProfileIdImage>;
  CreationDate: Scalars['String'];
};

export type UserProfileData = {
  __typename?: 'UserProfileData';
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileDataInput = {
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
};

export type UserProfileEmail = {
  __typename?: 'UserProfileEmail';
  Email: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileEmailInput = {
  Email: Scalars['String'];
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  Date: Scalars['String'];
  Toolname?: Maybe<Scalars['String']>;
};

export type UserProfileEmailOptinInput = {
  Email: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Date: Scalars['String'];
  Toolname?: Maybe<Scalars['String']>;
};

export type UserProfileIdImage = {
  __typename?: 'UserProfileIdImage';
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
};

export type UserProfileIdImageInput = {
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
};

export type UserProfileImage = {
  __typename?: 'UserProfileImage';
  Name: Scalars['String'];
  Type: Scalars['String'];
  Content: Scalars['String'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileInput = {
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
  CreationDate: Scalars['String'];
};

export type UserProfileLight = {
  __typename?: 'UserProfileLight';
  userId: Scalars['String'];
  Title: Scalars['String'];
  FirstName: Scalars['String'];
  LastName: Scalars['String'];
  CompanyName: Scalars['String'];
  Phone: Scalars['String'];
  Mobile?: Maybe<Scalars['String']>;
  Fax?: Maybe<Scalars['String']>;
  Email: Scalars['String'];
  Street?: Maybe<Scalars['String']>;
  Zip?: Maybe<Scalars['String']>;
  City?: Maybe<Scalars['String']>;
  Country: Scalars['String'];
  Newsletter: Scalars['Boolean'];
  Department: Scalars['String'];
  Relationship: Scalars['String'];
  TermsOfUse: Scalars['Boolean'];
};

export type UserProfileOptIn = {
  __typename?: 'UserProfileOptIn';
  Newsletter: Scalars['Boolean'];
  Status: Scalars['String'];
  ValidFrom: Scalars['String'];
};

export type UserProfileOptinInput = {
  Newsletter: Scalars['Boolean'];
};

export type UserProfileVersionInformation = {
  __typename?: 'UserProfileVersionInformation';
  /** version of user profile service */
  version: Scalars['String'];
  /** timestamp of the iam build */
  buildTimestamp: Scalars['String'];
};

export type GetUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserProfileQuery = (
  { __typename?: 'Query' }
  & { getUserProfile: (
    { __typename?: 'UserProfile' }
    & Pick<UserProfile, 'UserID' | 'Title' | 'FirstName' | 'LastName' | 'CompanyName' | 'Phone' | 'Mobile' | 'Fax' | 'Email' | 'Street' | 'Zip' | 'City' | 'Country' | 'Newsletter' | 'Department' | 'Relationship' | 'TermsOfUse' | 'CreationDate'>
    & { Image?: Maybe<(
      { __typename?: 'UserProfileIdImage' }
      & Pick<UserProfileIdImage, 'Name' | 'Type' | 'Content'>
    )> }
  ) }
);

export type CreateUserProfileMutationVariables = Exact<{
  userProfileInput: UserProfileInput;
}>;


export type CreateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUserProfile'>
);

export type UpdateUserProfileDataMutationVariables = Exact<{
  userProfileDataInput: UserProfileDataInput;
}>;


export type UpdateUserProfileDataMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserProfileData'>
);

export type DeleteUserProfileMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserProfileMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserProfile'>
);

export type DeleteUserProfileFullMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserProfileFullMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUserProfileFull'>
);

export type UpdateUserProfileOptinMutationVariables = Exact<{
  userProfileOptinInput: UserProfileOptinInput;
}>;


export type UpdateUserProfileOptinMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserProfileOptin'>
);

export type UpdateUserProfileImageMutationVariables = Exact<{
  userProfileImage: UserProfileIdImageInput;
}>;


export type UpdateUserProfileImageMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUserProfileImage'>
);


export const GetUserProfileDocument = gql`
    query GetUserProfile {
  getUserProfile {
    UserID
    Title
    FirstName
    LastName
    CompanyName
    Phone
    Mobile
    Fax
    Email
    Street
    Zip
    City
    Country
    Newsletter
    Department
    Relationship
    TermsOfUse
    Image {
      Name
      Type
      Content
    }
    CreationDate
  }
}
    `;

/**
 * __useGetUserProfileQuery__
 *
 * To run a query within a React component, call `useGetUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
      }
export function useGetUserProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserProfileQuery, GetUserProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserProfileQuery, GetUserProfileQueryVariables>(GetUserProfileDocument, options);
        }
export type GetUserProfileQueryHookResult = ReturnType<typeof useGetUserProfileQuery>;
export type GetUserProfileLazyQueryHookResult = ReturnType<typeof useGetUserProfileLazyQuery>;
export type GetUserProfileQueryResult = Apollo.QueryResult<GetUserProfileQuery, GetUserProfileQueryVariables>;
export const CreateUserProfileDocument = gql`
    mutation CreateUserProfile($userProfileInput: UserProfileInput!) {
  createUserProfile(userProfile: $userProfileInput)
}
    `;
export type CreateUserProfileMutationFn = Apollo.MutationFunction<CreateUserProfileMutation, CreateUserProfileMutationVariables>;

/**
 * __useCreateUserProfileMutation__
 *
 * To run a mutation, you first call `useCreateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserProfileMutation, { data, loading, error }] = useCreateUserProfileMutation({
 *   variables: {
 *      userProfileInput: // value for 'userProfileInput'
 *   },
 * });
 */
export function useCreateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserProfileMutation, CreateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserProfileMutation, CreateUserProfileMutationVariables>(CreateUserProfileDocument, options);
      }
export type CreateUserProfileMutationHookResult = ReturnType<typeof useCreateUserProfileMutation>;
export type CreateUserProfileMutationResult = Apollo.MutationResult<CreateUserProfileMutation>;
export type CreateUserProfileMutationOptions = Apollo.BaseMutationOptions<CreateUserProfileMutation, CreateUserProfileMutationVariables>;
export const UpdateUserProfileDataDocument = gql`
    mutation UpdateUserProfileData($userProfileDataInput: UserProfileDataInput!) {
  updateUserProfileData(userProfileData: $userProfileDataInput)
}
    `;
export type UpdateUserProfileDataMutationFn = Apollo.MutationFunction<UpdateUserProfileDataMutation, UpdateUserProfileDataMutationVariables>;

/**
 * __useUpdateUserProfileDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileDataMutation, { data, loading, error }] = useUpdateUserProfileDataMutation({
 *   variables: {
 *      userProfileDataInput: // value for 'userProfileDataInput'
 *   },
 * });
 */
export function useUpdateUserProfileDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileDataMutation, UpdateUserProfileDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileDataMutation, UpdateUserProfileDataMutationVariables>(UpdateUserProfileDataDocument, options);
      }
export type UpdateUserProfileDataMutationHookResult = ReturnType<typeof useUpdateUserProfileDataMutation>;
export type UpdateUserProfileDataMutationResult = Apollo.MutationResult<UpdateUserProfileDataMutation>;
export type UpdateUserProfileDataMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileDataMutation, UpdateUserProfileDataMutationVariables>;
export const DeleteUserProfileDocument = gql`
    mutation DeleteUserProfile {
  deleteUserProfile
}
    `;
export type DeleteUserProfileMutationFn = Apollo.MutationFunction<DeleteUserProfileMutation, DeleteUserProfileMutationVariables>;

/**
 * __useDeleteUserProfileMutation__
 *
 * To run a mutation, you first call `useDeleteUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserProfileMutation, { data, loading, error }] = useDeleteUserProfileMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserProfileMutation, DeleteUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserProfileMutation, DeleteUserProfileMutationVariables>(DeleteUserProfileDocument, options);
      }
export type DeleteUserProfileMutationHookResult = ReturnType<typeof useDeleteUserProfileMutation>;
export type DeleteUserProfileMutationResult = Apollo.MutationResult<DeleteUserProfileMutation>;
export type DeleteUserProfileMutationOptions = Apollo.BaseMutationOptions<DeleteUserProfileMutation, DeleteUserProfileMutationVariables>;
export const DeleteUserProfileFullDocument = gql`
    mutation DeleteUserProfileFull {
  deleteUserProfileFull
}
    `;
export type DeleteUserProfileFullMutationFn = Apollo.MutationFunction<DeleteUserProfileFullMutation, DeleteUserProfileFullMutationVariables>;

/**
 * __useDeleteUserProfileFullMutation__
 *
 * To run a mutation, you first call `useDeleteUserProfileFullMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserProfileFullMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserProfileFullMutation, { data, loading, error }] = useDeleteUserProfileFullMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteUserProfileFullMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserProfileFullMutation, DeleteUserProfileFullMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserProfileFullMutation, DeleteUserProfileFullMutationVariables>(DeleteUserProfileFullDocument, options);
      }
export type DeleteUserProfileFullMutationHookResult = ReturnType<typeof useDeleteUserProfileFullMutation>;
export type DeleteUserProfileFullMutationResult = Apollo.MutationResult<DeleteUserProfileFullMutation>;
export type DeleteUserProfileFullMutationOptions = Apollo.BaseMutationOptions<DeleteUserProfileFullMutation, DeleteUserProfileFullMutationVariables>;
export const UpdateUserProfileOptinDocument = gql`
    mutation UpdateUserProfileOptin($userProfileOptinInput: UserProfileOptinInput!) {
  updateUserProfileOptin(userProfileOptin: $userProfileOptinInput)
}
    `;
export type UpdateUserProfileOptinMutationFn = Apollo.MutationFunction<UpdateUserProfileOptinMutation, UpdateUserProfileOptinMutationVariables>;

/**
 * __useUpdateUserProfileOptinMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileOptinMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileOptinMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileOptinMutation, { data, loading, error }] = useUpdateUserProfileOptinMutation({
 *   variables: {
 *      userProfileOptinInput: // value for 'userProfileOptinInput'
 *   },
 * });
 */
export function useUpdateUserProfileOptinMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileOptinMutation, UpdateUserProfileOptinMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileOptinMutation, UpdateUserProfileOptinMutationVariables>(UpdateUserProfileOptinDocument, options);
      }
export type UpdateUserProfileOptinMutationHookResult = ReturnType<typeof useUpdateUserProfileOptinMutation>;
export type UpdateUserProfileOptinMutationResult = Apollo.MutationResult<UpdateUserProfileOptinMutation>;
export type UpdateUserProfileOptinMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileOptinMutation, UpdateUserProfileOptinMutationVariables>;
export const UpdateUserProfileImageDocument = gql`
    mutation UpdateUserProfileImage($userProfileImage: UserProfileIdImageInput!) {
  updateUserProfileImage(userProfileImage: $userProfileImage)
}
    `;
export type UpdateUserProfileImageMutationFn = Apollo.MutationFunction<UpdateUserProfileImageMutation, UpdateUserProfileImageMutationVariables>;

/**
 * __useUpdateUserProfileImageMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileImageMutation, { data, loading, error }] = useUpdateUserProfileImageMutation({
 *   variables: {
 *      userProfileImage: // value for 'userProfileImage'
 *   },
 * });
 */
export function useUpdateUserProfileImageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileImageMutation, UpdateUserProfileImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileImageMutation, UpdateUserProfileImageMutationVariables>(UpdateUserProfileImageDocument, options);
      }
export type UpdateUserProfileImageMutationHookResult = ReturnType<typeof useUpdateUserProfileImageMutation>;
export type UpdateUserProfileImageMutationResult = Apollo.MutationResult<UpdateUserProfileImageMutation>;
export type UpdateUserProfileImageMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileImageMutation, UpdateUserProfileImageMutationVariables>;