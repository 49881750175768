import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import Snackbar from '@material-ui/core/Snackbar/Snackbar'
import Alert from '@material-ui/lab/Alert'

export type ResultAlertProps = {
  alertText: string
  severity?: 'error' | 'warning' | 'success' | 'info'
  closeAlert?: (close: boolean) => void
}

export const ResultAlert: React.FC<ResultAlertProps> = ({ severity, alertText, closeAlert }) => {
  const [snackOpen, setSnackOpen] = React.useState<boolean>(true)
  const { t } = useTranslation('common')

  const modifyShowAlert = useCallback(() => {
    setSnackOpen(!snackOpen)
    closeAlert && closeAlert(!snackOpen)
  }, [closeAlert, snackOpen])

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={snackOpen}
      autoHideDuration={5000}
      onClose={modifyShowAlert}
    >
      <Alert onClose={modifyShowAlert} severity={severity ?? 'success'}>
        {t(alertText)}
      </Alert>
    </Snackbar>
  )
}
