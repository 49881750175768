import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

type ModalFormProps = {
  children: React.ReactNode
  headingText: string
  formClose: () => void
  submitForm: () => void
  formOpen: boolean
  disableSubmit?: boolean
  confirmButtonLabel?: string
  disableClose?: boolean
  cancelButtonLabel?: string
}

export const ModalForm: React.FC<ModalFormProps> = ({
  headingText,
  formClose,
  formOpen,
  children,
  submitForm,
  disableSubmit,
  confirmButtonLabel,
  disableClose,
  cancelButtonLabel,
}: ModalFormProps) => {
  const { t } = useTranslation('common')
  return (
    <>
      <Dialog
        open={formOpen}
        onClose={formClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle id="form-dialog-title" disableTypography>
          <Grid item>
            <Typography variant="h6" color="secondary" align="center" gutterBottom>
              {headingText}
            </Typography>
          </Grid>
        </DialogTitle>
        <DialogContent>{children}</DialogContent>
        <Divider variant="fullWidth" />
        <DialogActions>
          <Grid item container justifyContent="center" alignContent="stretch" spacing={2}>
            <Grid item>
              <Button fullWidth variant="contained" disabled={disableClose} onClick={formClose} color="primary">
                {cancelButtonLabel ? cancelButtonLabel : t('modals.cancel')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                disabled={disableSubmit}
                onClick={submitForm}
                color="primary"
                autoFocus={true}
              >
                {confirmButtonLabel ? confirmButtonLabel : t('modals.confirm')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}
