import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from './Inputs/Text'
import { ModalForm } from './ModalForm'

type Props = {
  submit: () => Promise<void>
  openPopUp: boolean
  closePopUp: (close: boolean) => void
  packetName: string
  updateName: (name: string) => void
}

const RenamePacket: React.FC<Props> = ({ submit, openPopUp, packetName, updateName, closePopUp }: Props) => {
  const [close, setClose] = useState<boolean>(false)
  const { t } = useTranslation('common')

  useEffect(() => {
    if (openPopUp) {
      setClose(openPopUp)
    }
  }, [openPopUp])

  return (
    <>
      <ModalForm
        formOpen={close}
        formClose={() => {
          setClose(false)
          closePopUp(false)
        }}
        headingText={t('labels.updatePacketName')}
        submitForm={submit}
      >
        <Text
          label={t('labels.packetName')}
          mdColumns={10}
          columns={10}
          value={packetName}
          changeHandler={(newName) => updateName(newName)}
          aloneInRow
        />
      </ModalForm>
    </>
  )
}

export default RenamePacket
